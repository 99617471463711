import React, { useState, useEffect } from "react";
import "../../mobile/address/EditAddressCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowCircleLeft,
  Phone,
  Apartment,
  Person,
  AddLocation,
  LocationCity,
  Business,
} from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EditAddress = () => {
  const { t } = useTranslation();
  const { authUser } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getRegion();
    getAddressList();
    setPhone(authUser.user.phone_number);
  }, []);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [addressList, setAddressList] = useState([]);

  const userLoginStatus = () => {
    let loginStatus = "";
    const customer = authUser ? (authUser.user ? authUser.user : null) : null;
    const status = localStorage.getItem("userLoginStatus");
    if (customer && status == "google") {
      loginStatus = "google";
    }
    if (customer && status == "facebook") {
      loginStatus = "facebook";
    }
    if (customer && status == "phone") {
      loginStatus = "phone";
    }
    return loginStatus;
  };

  const getAddressList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      setName(response.data.name);
      setPhone(response.data.phone_number);
      setAddress(response.data.address);
      setShowRegion(response.data.region_id);
      setShowTownship(response.data.township_id);
    }
  };

  const [region, setRegion] = useState("");
  const [showRegion, setShowRegion] = useState("");
  const handleChangeRegion = (event) => {
    setShowNoTownship(true);

    setShowRegion(event.target.value);
    getTownships(event.target.value);
    setShowTownship("");
  };
  const getRegion = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.regionsApi,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setRegion(response.data);
    }
  };

  // const [city,setCity] = useState("");
  // const [showCity,setShowCity] = useState("");
  // const handleChangeCity = (event) => {
  //     setShowCity(event.target.value);
  //     getTownships(event.target.value);
  // };
  // const getCity = async (regId) => {
  //     let obj = {
  //         method: 'get',
  //         url: ApiCollections.citiesApi,
  //         params: {
  //             region_id: regId,
  //         }
  //     };

  //     let response = await ApiRequest(obj);
  //     response =response.data;
  //     if (response.code === 200) {
  //         setCity(response.data)
  //     }
  // };

  const [township, setTownship] = useState("");
  const [showTownship, setShowTownship] = useState("");
  const [showNoTownship, setShowNoTownship] = useState(false);
  const handleChangeTownship = (event) => {
    setShowNoTownship(false);
    setShowTownship(event.target.value);
  };
  const getTownships = async (regId) => {
    let obj = {
      method: "get",
      url: ApiCollections.townshipsApi,
      params: {
        region_id: regId,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setTownship(response.deliFeeRegions);
    }
  };

  useEffect(() => {
    getTownships(showRegion);
  }, [showRegion]);

  const btnSave = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.editAddressApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
        name: name,
        ph_no: phone,
        address: address,
        region_id: showRegion,
        township_id: showTownship,
      },
    };

    if (name && phone && address && showRegion && showTownship) {
      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        navigate(-1);
      }
    } else {
      toast.error("Please Fill All Informations", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <ToastContainer draggable={false} autoClose={3000} />

      <form className="editaddress">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("editAddress")}</div>
        </div>
        <div className="nameBox">
          <div className="name">{t("userName")}</div>
          <div className="enterBox">
            <Person id="photo" />
            <input
              type="text"
              placeholder={t("pleaseName")}
              className="inputEnter"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="nameBox">
          <div className="name">{t("phoneNumber")}</div>
          <div className="enterBox">
            <Phone id="photo" />
            <input
              type="number"
              placeholder={t("pleasePhone")}
              className="inputEnter"
              value={phone}
              disabled={
                userLoginStatus() && userLoginStatus() == "phone"
                  ? "disabled"
                  : ""
              }
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="nameBox">
          <div className="name">{t("address")}</div>
          <div className="enterBox">
            <Apartment id="photo" />
            <input
              type="text"
              placeholder={t("pleaseadd")}
              className="inputEnter"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="nameBox">
          <div className="name">{t("region")}</div>
          <div className="enterBox">
            <AddLocation id="selectphoto" />
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={showRegion}
              onChange={handleChangeRegion}
              displayEmpty
            >
              {/* <MenuItem
                value=""
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "7vw !important",
                }}
              >
                {addressList.region_name}
              </MenuItem> */}
              {region.length > 0 &&
                region.map((reginItem, regionIndex) => (
                  <MenuItem
                    key={regionIndex}
                    value={reginItem.region_id}
                    sx={{
                      fontSize: "3.6vw !important",
                      lineHeight: "7vw !important",
                    }}
                  >
                    {reginItem.region_name_en}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        {/* <div className="nameBox">
                <div className="name">City</div>
                <div className="enterBox">
                    <LocationCity id="selectphoto"/>
                    <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={showCity}
                        onChange={handleChangeCity}
                        displayEmpty
                    >
                        <MenuItem value="" 
                        sx={{fontSize:'3.6vw !important',lineHeight:'7vw !important'}}>
                            {addressList.city_name}
                        </MenuItem>
                        {   city&&
                            city.map((cityItem,cityIndex) => (
                                <MenuItem 
                                    key={cityIndex}
                                    value={cityItem.city_id
                                    } 
                                    sx={{fontSize:'3.6vw !important',lineHeight:'7vw !important'}}
                                >
                                    {cityItem.city_name_en}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </div>
            </div> */}
        <div className="nameBox">
          <div className="name">{t("township")}</div>
          <div className="enterBox">
            <Business id="selectphoto" />
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={showTownship}
              onChange={handleChangeTownship}
              displayEmpty
            >
              {showNoTownship && (
                <MenuItem
                  value=""
                  sx={{
                    fontSize: "3.6vw !important",
                    lineHeight: "7vw !important",
                  }}
                >
                  {t("selectTownship")}
                </MenuItem>
              )}
              {township.length > 0 &&
                township.map((townshipItem, townshipIndex) => (
                  <MenuItem
                    key={townshipIndex}
                    value={townshipItem.township_id}
                    sx={{
                      fontSize: "3.6vw !important",
                      lineHeight: "7vw !important",
                    }}
                  >
                    {townshipItem.township_name_en}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        <button className="saveBtn" onClick={btnSave} type="button">
          {t("save")}
        </button>
      </form>
    </>
  );
};

export default EditAddress;
