import React, { useContext, useState, useEffect, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import { useParams } from "react-router-dom";
import { GlassMagnifier } from "react-image-magnifiers";
import { ChevronRight, ChevronLeft, Favorite, Star } from "@mui/icons-material";
import { Button, Box, Container, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./pDetail.scss";
import StarComponent from "../components/StarComponent";
import ProductTabs from "./ProductTabs";
import { useDispatch, useSelector } from "react-redux";
import { NavigateContext, useNavigateContext } from "../context";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import ReactImageMagnify from "react-image-magnify";
import Wrapper from "../Wrapper";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import ReactQuill from "react-quill";
import RelatedProduct from "./RelatedProduct";
import SameStore from "./SameStore";
import { buy } from "../../reducers/checkoutSlice";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import {
  addToCart,
  getTotalAmount,
  getCartCount,
} from "../../reducers/cartSlice";
import { addToCartProductAsync } from "../../reducers/cartActions";
import { useTranslation } from "react-i18next";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";
import { Navigation } from "swiper";
import YoutubeEmbed from "../../components/commons/YoutubeEmbed";
import { AiFillYoutube } from "react-icons/ai";
import { number } from "prop-types";

// import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  p: 3,
};

const ProductDetail = (props) => {
  const location = useLocation();
  let { productId, itemId } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { authUser } = useSelector((state) => state.user);
  const [my_swiper, set_my_swiper] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetail();
    setImg("");
    setImgIndex(null);
  }, [productId]);

  const [forThumbnail, setForThumbnail] = useState([]);
  const [showImg, setShowImg] = useState([]);
  const [img, setImg] = useState("");

  useEffect(() => {
    function imageZoom(imgID, resultID) {
      var img, lens, result, cx, cy;
      img = document.getElementById(imgID);
      result = document.getElementById(resultID);
      /*create lens:*/
      lens = document.createElement("DIV");
      lens.setAttribute("class", "img-zoom-lens");
      /*insert lens:*/
      img.parentElement.insertBefore(lens, img);
      /*calculate the ratio between result DIV and lens:*/
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      /*set background properties for the result DIV:*/
      result.style.backgroundImage = "url('" + img.src + "')";
      result.style.backgroundSize =
        img.width * cx + "px " + img.height * cy + "px";
      /*execute a function when someone moves the cursor over the image, or the lens:*/
      lens.addEventListener("mousemove", moveLens);
      img.addEventListener("mousemove", moveLens);
      lens.addEventListener("mouseout", moveOut);
      img.addEventListener("mouseout", moveOut);
      /*and also for touch screens:*/
      lens.addEventListener("touchmove", moveLens);
      img.addEventListener("touchmove", moveLens);
      function moveLens(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image:*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        /*calculate the position of the lens:*/
        x = pos.x - lens.offsetWidth / 2.5;
        y = pos.y - lens.offsetHeight / 2.5;
        /*prevent the lens from being positioned outside the image:*/
        if (x > img.width - lens.offsetWidth) {
          x = img.width - lens.offsetWidth;
        }
        if (x < 0) {
          x = 0;
        }
        if (y > img.height - lens.offsetHeight) {
          y = img.height - lens.offsetHeight;
        }
        if (y < 0) {
          y = 0;
        }
        /*set the position of the lens:*/
        lens.style.left = x + "px";
        lens.style.top = y + "px";
        lens.style.opacity = 1;
        /*display what the lens "sees":*/
        result.style.backgroundPosition = "-" + x * cx + "px -" + y * cy + "px";
        result.style.opacity = 1;
        result.style.zIndex = 100;
      }

      function moveOut() {
        result.style.zIndex = -1;
        result.style.opacity = 0;
        lens.style.opacity = 0;
      }
      function getCursorPos(e) {
        var a,
          x = 0,
          y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
    }
    imageZoom("myimage", "myresult");
  }, [forThumbnail, showImg, img]);

  const [loading, setLoading] = useState(false);

  const [detailproduct, setProductDetail] = useState([]);
  const [sameColor, setSameColor] = useState([]);
  const [CateID, setCateID] = useState(null);
  const [serlerID, setSellerID] = useState([]);

  const [shortdes, setShortdes] = useState("");
  const [longdes, setLongdes] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [show, setShow] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getProductDetail = async () => {
    setLoading(true);

    let obj = {
      method: "get",
      url: ApiCollections.productDetailApi,
      params: {
        id: productId,
        customer_id: localStorage.getItem("userTokenID"),
      },
    };
    if (itemId) {
      let itemIdObj = { item_id: itemId };
      Object.assign(obj.params, itemIdObj);
    }

    console.log(obj);
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setProductDetail(response.data);
      setSameColor([
        ...new Map(
          response.data.items.map((item) => [item["color"], item])
        ).values(),
      ]);
      setCateID(response.data.category_id);
      setSellerID(response.data.seller_id);
      setForThumbnail(response.data.product_file);
      setShowImg(response.data.product_file[0].photo);
      setFavProduct(response.data.favourite_status);
      setLongdes(response.data.product_longdescription);
      setShortdes(response.data.product_description);
      setYoutubeLink(response.data.product_link.substring(17, 28));
      if (response.data.product_link !== "null") {
        setImgIndex(100);
        setShow(1);
      } else {
        setImgIndex(0);
        setShow(0);
      }
    }
  };

  const [itemDetail, setItemDetail] = useState([]);
  const [showSize, setSize] = useState([]);
  const [isActive, setActive] = useState(null);
  const [activeColor, setActiveColor] = useState(null);

  const [sizeActive, setSizeActive] = useState(null);
  const [sizeAct, setSizeAct] = useState(null);
  const choseColor = async (selectID, selectColor) => {
    setActive(selectID);
    setActiveColor(selectColor);
    let obj = {
      method: "get",
      url: ApiCollections.sizeApi,
      params: {
        id: productId,
        color: selectColor,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSize(response.data);
      setSizeAct("");
      setSizeActive("");
    }
  };

  const [itemName, setItemName] = useState(null);
  const [itemPromotionPrice, setItemPromotionPrice] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const choseSize = async (sizeID, sizeName) => {
    setSizeActive(sizeID);
    setSizeAct(sizeName);
    let obj = {
      method: "get",
      url: ApiCollections.itemDetailApi,
      params: {
        id: sizeID,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setItemDetail(response.data);
      setItemName(response.data.product_name);
      setItemPrice(response.data.price);
      setItemPromotionPrice(response.data.product_promotion_price);
    }
  };

  const [favProduct, setFavProduct] = useState("0");
  const gofav = async (index) => {
    if (localStorage.getItem("userTokenID") != null) {
      setFavProduct(index);
      let requestObj = {
        method: "post",
        url: ApiCollections.favStatus,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          favourite_status: index,
          product_id: productId,
        },
      };
      let response = await ApiRequest(requestObj);

      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Something Wrong when try to follow!");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };
  const [quantity, setQuantity] = useState(1);

  const navigate = useContext(NavigateContext);

  const newArray = [];
  let newData = [...forThumbnail];
  while (newData.length > 0) newArray.push(newData.splice(0, 5));

  const changeQuantity = (value) => {
    value === "decrease" && quantity > 1 && setQuantity(quantity - 1);
    value === "increase" && setQuantity(quantity + 1);
  };

  const addToCartProduct = async () => {
    let customerId = authUser
      ? authUser.user
        ? authUser.user.id
        : null
      : null;
    if (customerId) {
      if (detailproduct.item_status == 0) {
        if (detailproduct.stock < quantity || detailproduct.stock == 0) {
          toast.error(
            "Your purchased stock is greater than the number of items in the store",
            {
              className: "toastColor",
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          let data = {
            customer_id: customerId,
            product_id: detailproduct.product_id,
            title: detailproduct.product_name,
            image: detailproduct.product_photo,
            price: detailproduct.product_price,
            promotion_price: detailproduct.product_promotion_price,
            size: sizeActive,
            color: activeColor,
            quantity,
            type: "product",
            seller_id: detailproduct.seller_id,
          };
          let obj = {
            method: "post",
            url: ApiCollections.addToCartProductServer,
            params: data,
          };
          setLoading(true);
          const response = await dispatch(addToCartProductAsync(obj)).unwrap();
          setLoading(false);
          if (response.flag === false) {
            toast.error(`Error on Add To Cart`);
          } else if (response.data.code === 200) {
            console.log(response.data.data);
            dispatch(addToCart(response.data.data));
            dispatch(getTotalAmount());
            dispatch(getCartCount());
            toast.success("Product Add to Cart!");
          } else if (response.data.code === 400) {
            toast.error(response.data.data.message);
          } else {
            toast.error("Something Wrong when try to add product to cart!");
          }
        }
      } else {
        if (!sizeActive && !sizeAct && detailproduct.item_status == 1) {
          toast.info("Select the color and size", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          if (itemDetail.stock < quantity || itemDetail.stock == 0) {
            toast.error(
              "Your purchased stock is greater than the number of items in the store",
              {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else {
            let data = {
              customer_id: customerId,
              product_id: itemDetail.item_id,
              title: itemDetail.product_name,
              image: itemDetail.product_photo,
              price: itemDetail.price,
              promotion_price: itemDetail.product_promotion_price,
              size: sizeActive,
              color: activeColor,
              quantity,
              type: "item",
              seller_id: itemDetail.seller_id,
            };
            let obj = {
              method: "post",
              url: ApiCollections.addToCartProductServer,
              params: data,
            };
            setLoading(true);
            const response = await dispatch(
              addToCartProductAsync(obj)
            ).unwrap();
            setLoading(false);
            if (response.flag === false) {
              toast.error(`Error on Add To Cart`);
            } else if (response.data.code === 200) {
              dispatch(addToCart(response.data.data));
              dispatch(getTotalAmount());
              dispatch(getCartCount());
              toast.success("Product Add to Cart!");
            } else if (response.data.code === 400) {
              toast.error(response.data.data.message);
            } else {
              toast.error("Something Wrong when try to add product to cart!");
            }
          }
        }
      }
    } else {
      toast.error(t("LOGIN_MAKE_ADD_TO_CAR"));
      setTimeout(() => {
        navigate("/login-with-phone");
      }, 5000);
    }
  };

  const buyNow = () => {
    if (!sizeActive && !sizeAct && detailproduct.item_status == 1) {
      toast.info("Select the color and size", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (
      detailproduct.stock < quantity ||
      detailproduct.stock == 0 ||
      itemDetail.stock < quantity ||
      itemDetail.stock == 0
    ) {
      toast.error(
        "Your purchased stock is greater than the number of items in the store",
        {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (sizeActive && sizeAct && detailproduct.item_status == 1) {
        navigate("/CheckOut");
        dispatch(buy({ product_id: null, item_id: sizeActive, qty: quantity }));
      }
      if (detailproduct.item_status == 0) {
        navigate("/CheckOut");
        dispatch(buy({ product_id: productId, item_id: null, qty: quantity }));
      }
    }
  };

  const handleChange = (e) => {
    setQuantity(e.target.value);
  };

  const [imgIndex, setImgIndex] = useState(null);
  const mouseHandler = (data, i) => {
    setImg(data);
    setImgIndex(i);
  };

  const refs = useRef([]);
  refs.current = [];
  const addRef = (ef) => {
    if (ef && !refs.current.includes(ef)) {
      refs.current.push(ef);
    }
  };

  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <ToastContainer autoClose={3000} />
        <div className="product-detail-wrapper overflow-hidden">
          <div className="swiper-wrapper pt-5">
            <div className="swiper-div">
              <div className="w-[80%] h-[450px] mx-auto showImg relative">
                <div className="w-full">
                  <div className="img-zoom-container">
                    <img
                      id="myimage"
                      src={img ? img : showImg && showImg}
                      className="small-img"
                      // onFocus={handleFocus}
                      tabIndex={0}
                      // onMouseLeave={handleMouseLeave}
                    />
                    <div id="myresult" className="img-zoom-result"></div>
                  </div>
                </div>

                {imgIndex === 100 && (
                  <div
                    className="w-full h-full absolute top-0 left-0 flex justify-center items-center  cursor-pointer"
                    onClick={handleOpen}
                  >
                    <AiFillYoutube className=" text-6xl text-[#FF0000]" />
                  </div>
                )}
              </div>
              <div className="w-[67%] mx-auto flex mt-2 relative pl-[40px] pr-[40px] thumbnail">
                <Swiper
                  slidesPerView={4.5}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Navigation]}
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                  className="mySwiper"
                >
                  {youtubeLink !== null && show !== 0 && (
                    <SwiperSlide style={{ height: "70px", width: "70px" }}>
                      <div
                        className={
                          imgIndex === 100
                            ? "w-[70px] h-[68px] border !border-priceColor  flex justify-center items-center cursor-pointer"
                            : "w-[70px] h-[68px] border border-gray-300  flex justify-center items-center cursor-pointer"
                        }
                        onMouseOver={() => mouseHandler(showImg, 100)}
                        onClick={handleOpen}
                      >
                        <AiFillYoutube className="text-4xl text-[#FF0000]" />
                      </div>
                    </SwiperSlide>
                  )}
                  {/* <div className="w-full h-[70px] mt-2 flex gap-x-2"> */}
                  {forThumbnail?.map((d, i) => (
                    <SwiperSlide
                      style={{ height: "70px", width: "70px" }}
                      key={i}
                    >
                      <div
                        className={
                          imgIndex === i
                            ? `w-[70px] !h-[68px] border !border-priceColor`
                            : `w-[70px] !h-[68px] border border-gray-300`
                        }
                        onMouseOver={() => mouseHandler(d.photo, i)}
                      >
                        <img
                          src={d.photo}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <button
                  onClick={() => my_swiper.slidePrev()}
                  className="absolute left-0 w-[38px] h-[70px] bg-priceColor flex justify-center items-center z-10"
                >
                  <FiArrowLeft className="text-2xl !text-white font-[600] " />
                </button>
                <button
                  onClick={() => my_swiper.slideNext()}
                  className="absolute right-0 w-[38px] h-[70px] bg-priceColor flex justify-center items-center z-10"
                >
                  <FiArrowRight className="text-2xl !text-white font-[600]" />
                </button>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={style}>
                  <div className="w-full h-full my-auto">
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${youtubeLink}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    {/* <iframe
                      width="956"
                      height="538"
                      src="https://www.youtube.com/embed/vadat46yfwg"
                      title="CAMO - Life is Wet (feat. JMIN) [Official Music Video]"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe> */}
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="w-[51.17%] h-auto">
              <div className="product-title !text-[1.675rem] !text-black pr-8">
                {itemName ? itemName : detailproduct.product_name}
              </div>
              <div className="price mt-[15px]">
                {itemName ? (
                  <>
                    {itemPromotionPrice !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {itemPromotionPrice}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">{itemPrice}ks</s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {itemPrice}ks
                        </span>
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {detailproduct.product_promotion_price !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {detailproduct.product_promotion_price}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">
                            {detailproduct.product_price}ks
                          </s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {detailproduct.product_price}ks
                        </span>
                      </span>
                    )}
                  </>
                )}
              </div>
              {detailproduct.brand_id != 1 ? (
                <div>
                  <p className="text-secondColor text-[14px] font-bold !mt-[15px]">
                    {t("Brand")}:{" "}
                    <span className="text-black font-normal  text-[12px]">
                      {detailproduct.brand_name}
                    </span>
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <div className="review-div justify-between items-center">
                {detailproduct.star_count > 0 && (
                  <div className="flex">
                    {[...Array(detailproduct.star_count)].map((i) => (
                      <span key={i}>
                        <Star
                          fontSize="small"
                          id="onestar"
                          className="text-starColor"
                        />
                      </span>
                    ))}
                    {[...Array(5 - detailproduct.star_count)].map((i) => (
                      <span key={i}>
                        <Star
                          fontSize="small"
                          id="notstar"
                          className="text-notstarColor"
                        />
                      </span>
                    ))}
                  </div>
                )}
                <p className="text-secondColor text-[14px] font-bold">
                  {t("ShopName")}:{" "}
                  <span
                    className="text-black font-normal   text-[12px] cursor-pointer hover:!underline"
                    onClick={() =>
                      navigate(`/StoreShop/${detailproduct.seller_id}`)
                    }
                  >
                    {detailproduct.shop_name}
                  </span>
                </p>
              </div>

              {sameColor.length > 0 && (
                <div className="product-spec-group">
                  <div className="text">
                    Colors : {activeColor && activeColor}
                  </div>
                  <div className="cursor-pointer mt-[10px] h-auto flex gap-x-2">
                    {sameColor.map((pItem, pIndex) => (
                      <div
                        className={
                          isActive == pItem.item_id
                            ? "flex justify-center items-center w-[55px] h-[55px] border-2 !border-red-600"
                            : "flex justify-center items-center w-[55px] h-[55px]"
                        }
                        key={pIndex}
                      >
                        <img
                          src={pItem.photo}
                          alt=""
                          key={pIndex}
                          onClick={() => choseColor(pItem.item_id, pItem.color)}
                          className="w-[50px] !h-[50px] object-cover"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showSize.length > 0 && (
                <div className="product-spec-group mt-[15px]">
                  <div className="text">Sizes : {sizeAct && sizeAct}</div>
                  <div className="w-full flex gap-x-2">
                    {showSize?.map((sizeItem, sizeIndex) => (
                      <div
                        className={
                          sizeActive == sizeItem.item_id
                            ? "spec-div border-2 !border-red-600"
                            : "spec-div"
                        }
                        onClick={() =>
                          choseSize(sizeItem.item_id, sizeItem.size)
                        }
                      >
                        <div className="flex justify-center items-center">
                          {sizeItem.size}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <div className="product-spec-group my-[10px]">
                  <div className="text">{t("Quantity")} : </div>
                  <div className="w-[90%] h-auto flex justify-between items-center">
                    <div className="w-[190px] h-auto flex !rounded-md overflow-hidden my-[10px]">
                      <div
                        onClick={() => changeQuantity("decrease")}
                        className="w-[45px] p-2 flex justify-center items-center !bg-[#efefef] text-white text-xl cursor-pointer rounded-sm"
                      >
                        <AiOutlineMinus className="text-black" />
                      </div>
                      <div className="w-[100px] flex justify-center items-center border !border-gray-300 mx-2 cursor-pointer hover:!border-priceColor borderHOver rounded-sm">
                        <input
                          type="text"
                          value={quantity}
                          className="w-full h-auto outline-none text-center"
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        onClick={() => changeQuantity("increase")}
                        className="w-[45px] p-2 flex justify-center items-center bg-[#efefef] text-white text-xl cursor-pointer rounded-sm"
                      >
                        <AiOutlinePlus className="text-black" />
                      </div>
                    </div>
                    {itemDetail.stock ? (
                      <span>
                        {itemDetail.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    ) : (
                      <span>
                        {detailproduct.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    )}
                    {favProduct == 1 ? (
                      <Favorite
                        onClick={() => gofav(0)}
                        className="!text-4xl !text-[#fe7021]"
                      />
                    ) : (
                      <Favorite
                        onClick={() => gofav(1)}
                        className="!text-4xl !text-[#555]"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="cart-btn-div gap-x-4">
                <div
                  className="buy-now-btn text-white !text-[16px] !font-bold"
                  onClick={buyNow}
                >
                  {t("BuyNow")}
                </div>
                <div
                  onClick={addToCartProduct}
                  className="cart-btn text-white !text-[16px] !font-bold"
                >
                  {t("AddToCart")}
                </div>
              </div>
              {/* /delivery */}
            </div>
          </div>
          <div className="my-5 px-10">
            {shortdes && (
              <div className="w-full h-auto">
                <p className="text-2xl font-bold">{t("Summary")}</p>
                <ReactQuill
                  theme="bubble"
                  value={shortdes}
                  readOnly="true"
                  className="text-[16px]"
                />
              </div>
            )}
            {longdes && (
              <div className="desDetail">
                <span className="text-2xl font-bold">{t("ProductDetail")}</span>
                <ReactQuill theme="bubble" value={longdes} readOnly="true" />
              </div>
            )}
          </div>

          <ProductTabs
            productId={productId}
            sellerId={detailproduct.seller_id}
          />
          <RelatedProduct relativeProducts={detailproduct.related_products} />
          {CateID != null && (
            <SameStore productId={productId} cateId={CateID} />
          )}
        </div>
        <ToastContainer draggable={false} autoClose={3000} />
      </Container>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  carouselDiv: {
    width: "100%",
  },
  navButtonWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  navButton: {
    backgroundColor: "transparent",
    color: "black",
  },
}));

export default ProductDetail;
