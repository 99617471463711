import React, { useState, useEffect } from "react";
import "./AddCartCss.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Wrapper from "../Wrapper";
import { Box, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";

import {
  decrementQuantity,
  removeItem,
  getTotalAmount,
  setQuantityOfItem,
  getCartCount,
  setCheckoutItems,
  updateCart,
  addToCart,
  updateTotalCheckoutAmountTemp,
} from "../../reducers/cartSlice";
import {
  setQuantity as setQuantityServer,
  incrementQty,
  decrementQty,
  removeCartItem,
} from "../../reducers/cartActions";

const AddCart = () => {
  const { t } = useTranslation();

  const { cart, checkoutItems, totalCheckoutAmountTemp } = useSelector(
    (state) => state.cart
  );
  const { authUser } = useSelector((state) => state.user);

  const [allChecked, setAllChecked] = useState(false);
  const [checked, setChecked] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (checkoutItems.length) {
      let items = [];
      checkoutItems.map((product) => {
        items.push(product.id);
      });
      setChecked(items);
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const gotoAddress = () => {
    // navigate("/AddAddress");
  };

  const goEditAddress = () => {
    // navigate("/EditAddress");
  };
  const goCheckOut = () => {
    if (checkoutItems.length) {
      let items = [];
      let updateCartProducts = cart;
      cart.map((product) => {
        if (checked.includes(product.id)) {
          updateCartProducts = updateCartProducts.filter(
            (item) => item.id !== product.id
          );
          items.push(product);
        }
      });

      dispatch(setCheckoutItems(items));
      dispatch(updateCart(updateCartProducts));
      dispatch(updateTotalCheckoutAmountTemp({ type: "REMOVE" }));
      dispatch(getTotalAmount());
      dispatch(getCartCount());
      items?.map(async (product) => {
        try {
          let data = {
            customer_id: product.customer_id,
            cart_id: product.id,
          };
          let obj = {
            method: "post",
            url: ApiCollections.removeProductCart,
            params: data,
          };
          const response = await dispatch(removeCartItem(obj)).unwrap();
          console.log(response);
        } catch (err) {
          console.log(err);
        }
      });
      setChecked([]);
      navigate("/multiple-product-checkout");
    } else {
      toast.error("Please Check Atleast one product to make checkout!");
      return false;
    }
  };
  const checkItemCheckoutChange = (id) => {
    const productId = checked.find((item) => item === id);

    if (productId) {
      const filterItems = checked.filter((item) => item !== id);

      let items = [];
      cart.map((product) => {
        if (filterItems.includes(product.id)) {
          items.push(product);
        }
      });
      dispatch(setCheckoutItems(items));
      dispatch(updateTotalCheckoutAmountTemp({ type: "ADD" }));

      setChecked(filterItems);
    } else {
      let updatedCheckIds = [...checked, id];
      let items = [];
      cart.map((product) => {
        if (updatedCheckIds.includes(product.id)) {
          items.push(product);
        }
      });
      dispatch(setCheckoutItems(items));
      dispatch(updateTotalCheckoutAmountTemp({ type: "ADD" }));
      setChecked(updatedCheckIds);
    }
  };
  console.log(checked);
  const checkAllHandle = (event) => {
    event.preventDefault();
    let checkAll = event.target.checked;
    let productIds = [];
    if (checkAll) {
      setAllChecked(true);
      cart.map((product) => {
        productIds.push(product.id);
      });

      let items = [];
      cart.map((product) => {
        if (productIds.includes(product.id)) {
          items.push(product);
        }
      });
      dispatch(setCheckoutItems(items));
      dispatch(updateTotalCheckoutAmountTemp({ type: "ADD" }));
    } else {
      setAllChecked(false);
      let items = [];
      dispatch(setCheckoutItems(items));
      dispatch(updateTotalCheckoutAmountTemp({ type: "REMOVE" }));
    }
    setChecked(productIds);
  };

  const addQty = React.useCallback(
    (id, allow) => async () => {
      if (!allow) {
        let customerId = authUser
          ? authUser.user
            ? authUser.user.id
            : null
          : null;
        let data = {
          customer_id: customerId,
          cart_id: id,
        };
        let obj = {
          method: "post",
          url: ApiCollections.increaseProductCartQyt,
          params: data,
        };
        setLoading(true);
        const response = await dispatch(incrementQty(obj)).unwrap();
        setLoading(false);
        if (response.flag === false) {
          toast.error(`Error on Quantity Increase`);
        } else if (response.data.code === 200) {
          dispatch(addToCart(response.data.data));
          dispatch(getTotalAmount());
          dispatch(getCartCount());

          // dispatch(incrementQuantity(id));
          // dispatch(getTotalAmount());
          // dispatch(getCartCount());
          toast.success("Increase Quantity Success");
        } else if (response.data.code === 422) {
          let message = response.data.message;
          toast.error(message, {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Something Wrong when try to Quantity Increase!");
        }
      } else {
        toast.error(
          "You cannot do this action when select product to checkout"
        );
      }
    },
    []
  );
  const reduceQty = React.useCallback(
    (id, qty, allowAction) => async () => {
      if (!allowAction) {
        if (qty <= 1) {
          toast.error("You have left only one ");
        } else {
          let customerId = authUser
            ? authUser.user
              ? authUser.user.id
              : null
            : null;
          let data = {
            customer_id: customerId,
            cart_id: id,
          };
          let obj = {
            method: "post",
            url: ApiCollections.decreaseProductCartQyt,
            params: data,
          };
          setLoading(true);
          const response = await dispatch(decrementQty(obj)).unwrap();
          setLoading(false);
          if (response.flag === false) {
            toast.error(`Error on Reduce Quantity`);
          } else if (response.data.code === 200) {
            dispatch(addToCart(response.data.data));
            // dispatch(decrementQuantity(id));
            dispatch(getTotalAmount());
            dispatch(getCartCount());
            toast.success("Reduce Quantity Success ");
          } else {
            toast.error("Something Wrong when try to Reduce Quantity!");
          }
        }
      } else {
        toast.error(
          "You cannot do this action when select product to checkout"
        );
      }
    },
    []
  );
  const removeProduct = React.useCallback(
    (id, allowAction) => async () => {
      if (!allowAction) {
        let customerId = authUser
          ? authUser.user
            ? authUser.user.id
            : null
          : null;
        let data = {
          customer_id: customerId,
          cart_id: id,
        };
        let obj = {
          method: "post",
          url: ApiCollections.removeProductCart,
          params: data,
        };
        setLoading(true);
        const response = await dispatch(removeCartItem(obj)).unwrap();
        console.log("success");
        setLoading(false);
        if (response.flag === false) {
          toast.error(`Error on Remove Product from Cart`);
        } else if (response.data.code === 200) {
          dispatch(addToCart(response.data.data));
          // dispatch(removeItem(id));
          dispatch(getTotalAmount());
          dispatch(getCartCount());

          const productId = checked.find((item) => item === id);
          if (productId) {
            const filterItems = checked.filter((item) => item !== id);
            setChecked(filterItems);
          }
          toast.success("Remove Product from Cart");
        } else {
          toast.error("Something Wrong when try to Quantity Increase!");
        }
      } else {
        toast.error(
          "You cannot do this action when select product to checkout"
        );
      }
    },
    []
  );
  const setQty = async (id, quantity, allowAction) => {
    if (!allowAction) {
      if (quantity < 1) {
        quantity = 1;
      }
      let customerId = authUser
        ? authUser.user
          ? authUser.user.id
          : null
        : null;
      let data = {
        customer_id: customerId,
        cart_id: id,
        quantity,
      };
      let obj = {
        method: "post",
        url: ApiCollections.setProductCartQyt,
        params: data,
      };
      setLoading(true);
      const response = await dispatch(setQuantityServer(obj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        toast.error(`Error on Quantity Set`);
      } else if (response.data.code === 200) {
        dispatch(addToCart(response.data.data));
        // dispatch(setQuantityOfItem({ id, quantity }));
        dispatch(getTotalAmount());
        dispatch(getCartCount());
        toast.success("Quantity Set Success");
      } else if (response.data.code === 422) {
        let message = response.data.message;
        toast.error(message, {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Something Wrong when try to Quantity Set!");
      }
    } else {
      toast.error("You cannot do this action when select product to checkout");
    }
  };

  return (
    <Wrapper>
      <Container>
        {loading && <Loading open={loading} />}
        <ToastContainer autoClose={3000} />
        <div className="cartAll">
          {cart.length > 0 ? (
            <div className="shoppingCart">
              <div className="leftDiv">
                <div className="showstore">
                  <div className="storeheader">
                    {cart && (
                      <FormControlLabel
                        id="lablename"
                        label={t("selectAll")}
                        control={
                          <Checkbox
                            checked={allChecked}
                            onChange={checkAllHandle}
                            id="checkicon"
                          />
                        }
                      />
                    )}
                  </div>
                  <Box>
                    {cart &&
                      cart.map((product) => (
                        <div key={product.id} className="showproduct">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  checked.find((item) => item === product.id)
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  checkItemCheckoutChange(product.id);
                                }}
                                id="checkicon"
                              />
                            }
                          />
                          <div className="procheck">
                            <div className="cardImage">
                              <img src={product.image} />
                            </div>
                            <div className="productRight">
                              <div className="title">{product.title}</div>
                              <div className="price">
                                {product.promotion_price !== 0 ? (
                                  <>
                                    {product.promotion_price} ks{" "}
                                    <span className="oriPrice">
                                      {product.price}ks{" "}
                                    </span>
                                  </>
                                ) : (
                                  <span>{product.price} ks</span>
                                )}
                              </div>
                              <div className="inputMain fl clearfix">
                                <button
                                  // disabled={
                                  //   checked.find((item) => item === product.id)
                                  //     ? true
                                  //     : false
                                  // }
                                  onClick={reduceQty(
                                    product.id,
                                    product.quantity,
                                    checked.includes(product.id)
                                  )}
                                >
                                  -
                                </button>
                                <input
                                  // disabled={
                                  //   checked.find((item) => item === product.id)
                                  //     ? true
                                  //     : false
                                  // }
                                  type="number"
                                  value={product.quantity}
                                  onChange={(event) => {
                                    event.preventDefault();
                                    setQty(
                                      product.id,
                                      event.target.value,
                                      checked.includes(product.id)
                                    );
                                  }}
                                />
                                <button
                                  className="rightB"
                                  onClick={addQty(
                                    product.id,
                                    checked.includes(product.id)
                                  )}
                                >
                                  +
                                </button>
                              </div>
                              <div
                                // style={{pointerEvents: checked.find((item) => item === product.id)
                                //   ? 'none'
                                //   : ''}}
                                className="showDelete"
                                onClick={removeProduct(
                                  product.id,
                                  checked.includes(product.id)
                                )}
                              >
                                <Delete id="iconDelete" />
                                <span className="textDelete">
                                  {t("delete")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Box>
                </div>
              </div>

              <div className="rightDiv">
                <div>{t("orderSummary")}</div>
                <Grid container spacing={2} my={2}>
                  <Grid item xs={8}>
                    {t("total")}
                  </Grid>
                  <Grid item xs={4}>
                    {totalCheckoutAmountTemp} Ks
                  </Grid>
                </Grid>
                <button className="confirmBtn" onClick={() => goCheckOut()}>
                  {t("checkOut")}
                </button>
              </div>
            </div>
          ) : (
            <div className="noDataform">
              <div className="text">{t("noCart")}</div>
              <div className="iconWrap">
                <Lottie animationData={nodata} loop={true} id="lot" />
              </div>
            </div>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default AddCart;
