import React, { useEffect, useState } from "react";
import "../../mobile/account/OrderDetailCss.scss";
import { ArrowCircleLeft, FmdGood, Person, Phone } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const NoCancelDetail = () => {
  const navigate = useNavigate();
  let { orderID } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderDetail();
  }, []);

  const [loading, setLoading] = useState(false);

  const goback = () => {
    navigate(-1);
  };

  const [orderDetail, setOrderDetail] = useState([]);
  const getOrderDetail = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.orderDetailApi,
      params: {
        order_id: orderID,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setOrderDetail(response.data);
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="orderdeail">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("OrderDetail")}</div>
        </div>
        {orderDetail.orderLists &&
          orderDetail.orderLists.map((detailItem, detailIndex) => (
            <div className="onepro" key={detailIndex}>
              <div className="orderProduct">
                <img src={detailItem.photo} />
                <div className="detail">
                  <div className="title">
                    {detailItem.item_name || detailItem.product_name}
                  </div>
                  <div className="price">
                    {detailItem.promotion_price !== 0 ? (
                      <span className="ori">
                        {detailItem.promotion_price} ks &nbsp;&nbsp;
                        <s>{detailItem.price}ks</s>
                      </span>
                    ) : (
                      <span className="ori">{detailItem.price} ks</span>
                    )}
                  </div>
                  {detailItem.color && (
                    <div className="itemBox">
                      <div className="colorBox">
                        {t("Color")}&nbsp;:&nbsp;
                        <span className="coll">{detailItem.color}</span>
                      </div>
                      <div className="sizeBox">
                        {t("Size")}&nbsp;:&nbsp;
                        <span className="siz">{detailItem.size}</span>
                      </div>
                    </div>
                  )}

                  <div className="qtyBox">
                    {t("Quantity")}&nbsp;:&nbsp;
                    <span className="qty">{detailItem.quantity}</span>
                  </div>
                </div>
              </div>
              {detailItem.cancel_status == 1 && (
                <div className="shopCancel">
                  We're sorry, this product has been cancelled by an
                  unforeseeable circumstances.
                </div>
              )}
            </div>
          ))}
        <div className="tax">
          <div className="total">
            <span className="name">{t("OrderNumber")}</span>
            <span className="number">{orderDetail.order_code}</span>
          </div>
          <div className="total">
            <span className="name">{t("Date")}</span>
            <span className="number">{orderDetail.date}</span>
          </div>
          <div className="total">
            <span className="name">{t("Payment")}</span>
            <span className="number">{orderDetail.payment_method}</span>
          </div>
          <div className="total">
            <span className="name">{t("priceTotal")}</span>
            <span className="number">{orderDetail.total_product_price}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("delivery")}</span>
            <span className="number">{orderDetail.delivery_fee}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("discount")}</span>
            <span className="number">{orderDetail.total_discount}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("CouponTitle")}</span>
            <span className="number">{orderDetail.coupon_discount}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("total")}</span>
            <span className="number">{orderDetail.total}ks</span>
          </div>
        </div>
        <div className="address">
          <div className="title">
            <span className="name">{t("address")}</span>
          </div>
          <div className="detail">
            <FmdGood id="img" />
            <div className="des">
              {orderDetail.address},{orderDetail.township_name},
              {orderDetail.region_name}
            </div>
          </div>
          <div className="detail">
            <Person id="img" />
            <div className="des">{orderDetail.name}</div>
          </div>
          <div className="detail">
            <Phone id="img" />
            <div className="des">{orderDetail.phone_number}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoCancelDetail;
