import React, { useState, useEffect } from "react";
import "../../mobile/product/CheckOutCss.scss";
import {
  ArrowCircleLeft,
  FmdGood,
  Person,
  Phone,
  KeyboardArrowRight,
  HighlightOff,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import selectaddress from "../../assets/selectaddress.json";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Loading from "../../components/commons/Loading";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Drawer, Box } from "@mui/material";
import { addID, addPrice, addStatus } from "../../reducers/couponSlice";
import nodata from "../../assets/coupon.json";

const CheckOutMultiple = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { checkoutItems, totalCheckoutAmount, totalCheckoutDiscountAmount } =
    useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAddressList();
    getCouponLists();
    dispatch(addID(null));
    dispatch(addPrice(null));
    dispatch(addStatus(0));
  }, []);

  const [CouponLists, setCouponLists] = useState([]);
  const getCouponLists = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.couponLists,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setCouponLists(response.data.Coupon);
    }
  };

  const gotoOrderList = async () => {
    if (addressList == null) {
      toast.error("Please Address", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (largeItem == 1 && Cod == 0) {
      toast.error(
        "Sorry, this item can't be shipped to your selected address",
        {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      navigate("/PaymentMultiple");
    }

    /*** Order Product Start**/
    // setLoading(true);
    // let orderProducts = [];
    // let orderTotal = totalCheckoutAmount; // get total amount of checkut items
    // checkoutItems.map((product) => {
    //   orderProducts.push({
    //     product_id: product.type == "product" ? product.id : null,
    //     item_id: product.type == "item" ? product.id : null,
    //     quantity: product.quantity,
    //     price: product.price,
    //     seller_id: product.seller_id,
    //     product_type: product.type,
    //   });
    // });

    // let requestObj = {
    //   method: "post",
    //   url: ApiCollections.placeOrder,
    //   params: {
    //     user_id: localStorage.getItem("userTokenID"),
    //     region_id: addressList.region_id,
    //     township_id: addressList.township_id,
    //     address: addressList.address,
    //     total: Number.parseFloat(orderTotal + deliveryFee).toFixed(2),
    //     totalDiscount: 0,
    //     order_products: orderProducts,
    //   },
    // };

    // let response = await ApiRequest(requestObj);
    // if (response.data.code === 200) {
    //   dispatch(setCheckoutItems([]));
    //   setLoading(false);
    //   toast.success("Order Success", {
    //     className: "toastColor",
    //     draggable: true,
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   navigate("/OrderList");
    // } else {
    //   setLoading(false);
    //   toast.error("Order Fail", {
    //     className: "toastColor",
    //     draggable: true,
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
    /*** Order Product End**/
  };

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  const [addressList, setAddressList] = useState([]);
  const getAddressList = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      getDeliveryFee(response.data.township_id, response.data.region_id);
    } else {
      setAddressList(response.data);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState([]);
  const [Cod, setCod] = useState([]);
  const [largeItem, setLargeItem] = useState([]);
  const [deliveryExtra, setDeliveryExtra] = useState([]);
  const getDeliveryFee = async (townID, regionID) => {
    let productIdsArr = [];
    checkoutItems.map((product) => {
      productIdsArr.push({
        id: product.product_id,
        quantity: product.quantity,
        type: product.type,
      });
    });
    let requestObj = {
      method: "post",
      url: ApiCollections.deliveryFeeApi,
      params: {
        township_id: townID,
        region_id: regionID,
        productIds_arr: productIdsArr,
      },
    };

    let response = await ApiRequest(requestObj);

    if (response.data.code === 200) {
      setDeliveryFee(response.data.data.delivery_fee);
      setCod(response.data.data.cod_status);
      setLargeItem(response.data.data.large_item_status);
      const { finalTotal } = response.data.data.extra_charge.reduce(
        (total, item) => {
          const { shipping_type_price } = item;
          const data = shipping_type_price;
          total.finalTotal += data;
          return total;
        },
        { finalTotal: 0 }
      );
      setDeliveryExtra(finalTotal);
    }
  };

  const gotoAddress = () => {
    navigate("/AddAddress");
  };

  const goEditAddress = () => {
    navigate("/EditAddress");
  };

  const [couponCal, setCouponCal] = useState("0");
  const couponget = (couponPrice, levelAmount, id) => {
    if (levelAmount == "Level 1") {
      if (totalCheckoutAmount >= 10000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး တစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 2") {
      if (totalCheckoutAmount >= 20000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး နှစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 3") {
      if (totalCheckoutAmount >= 40000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး လေးသောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, bottom: open });
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      {!loading && (
        <div className="seeOrder">
          <div className="header">
            <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
            <div className="title">{t("checkOut")}</div>
          </div>
          {addressList !== null ? (
            <div className="address">
              <div className="title">
                <span className="name">{t("address")}</span>
                <span className="edit" onClick={() => goEditAddress()}>
                  {t("edit")}
                </span>
              </div>
              <div className="detail">
                <FmdGood id="img" />
                <div className="des">
                  {addressList.address},{addressList.township_name},
                  {addressList.region_name}
                </div>
              </div>
              <div className="detail">
                <Person id="img" />
                <div className="des">{addressList.name}</div>
              </div>
              <div className="detail">
                <Phone id="img" />
                <div className="des">{addressList.phone_number}</div>
              </div>
            </div>
          ) : (
            <div className="NoAddress" onClick={() => gotoAddress()}>
              <div className="iconlot">
                <Lottie animationData={selectaddress} loop={true} id="lotsec" />
              </div>
              <div className="text">{t("addAddress")}</div>
            </div>
          )}

          {checkoutItems.map((checkShow, checkIndex) => (
            <div className="orderProduct" key={checkIndex}>
              <img src={checkShow.image} />
              <div className="detail">
                <div className="title">{checkShow.title}</div>
                <div className="priceBox">
                  {checkShow.promotion_price !== 0 ? (
                    <div>
                      <span className="price">
                        {checkShow.promotion_price} ks
                      </span>
                      <span className="disPrice">{checkShow.price}ks</span>
                    </div>
                  ) : (
                    <div>
                      <span className="price">{checkShow.price} ks</span>
                    </div>
                  )}
                </div>
                <div className="qtyBox">
                  {t("Quantity")}&nbsp;:&nbsp;
                  <span className="qty">{checkShow.quantity}</span>
                </div>
              </div>
            </div>
          ))}
          <div className="tax">
            <div className="total">
              <span className="name">{t("priceTotal")}</span>
              {totalCheckoutAmount ? (
                <span className="number">
                  {Number.parseFloat(totalCheckoutAmount).toFixed(2)}ks
                </span>
              ) : (
                <span className="number">0ks</span>
              )}
            </div>

            <div className="total">
              <span className="name">{t("delivery")}</span>
              <span className="number">{deliveryFee + deliveryExtra}ks</span>
            </div>

            <div className="total">
              <span className="name">{t("cashOnDelivery")}</span>
              {Cod == 1 ? (
                <span className="number !text-[green]">{t("available")}</span>
              ) : (
                <span className="number !text-[red]">{t("unAvailable")}</span>
              )}
            </div>

            <div className="total">
              <span className="name">{t("discount")}</span>
              <span className="number">
                -{Number.parseFloat(totalCheckoutDiscountAmount).toFixed(2)}ks
              </span>
            </div>
            <div className="total">
              <span className="name">{t("CouponTitle")}</span>
              <span className="number">
                -{Number.parseFloat(couponCal).toFixed(2)}ks
              </span>
            </div>
          </div>
          <div className="couponAll">
            <React.Fragment key={"bottom"}>
              <div className="couponChose" onClick={toggleDrawer(true)}>
                {t("CouponUse")}
                <KeyboardArrowRight id="btnRight" />
              </div>

              <Drawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer(false)}
              >
                <Box sx={{ width: "auto" }} role="presentation">
                  <div className="couponCart">
                    <div className="crossDiv">
                      <HighlightOff
                        id="couponcross"
                        onClick={toggleDrawer(false)}
                      />
                    </div>

                    {CouponLists.length > 0 ? (
                      <div>
                        {CouponLists.map((item, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              couponget(
                                item.discount_price,
                                item.coupon_level,
                                item.id
                              )
                            }
                          >
                            <img src={item.coupon_img} className="couponImg" />
                            <div className="date">
                              &nbsp;&nbsp;{item.time_left}
                              <div className="name">Day Left</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="noCou">
                        <div className="text">{t("CouponNo")}</div>
                        <div className="iconWrap">
                          <Lottie animationData={nodata} loop={true} id="lot" />
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </Drawer>
            </React.Fragment>
          </div>
          <div className="orderFooter">
            <div className="buyNow">
              {t("total")} :
              <span>
                {Number.parseFloat(
                  totalCheckoutAmount -
                    totalCheckoutDiscountAmount +
                    deliveryFee +
                    deliveryExtra -
                    couponCal
                ).toFixed(2)}
                ks
              </span>
            </div>
            <div className="addCart" onClick={gotoOrderList}>
              {t("placeOrder")}
            </div>
          </div>
          <ToastContainer draggable={false} autoClose={3000} />
        </div>
      )}
    </>
  );
};

export default CheckOutMultiple;
