import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Star } from "@mui/icons-material";
import {
  FavoriteBorder,
  Visibility,
  StarOutline,
  StarRate,
  StarHalf,
} from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "../home/product.scss";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";

const RPComponent = ({ relatedProducts }) => {
  console.log(relatedProducts, "rela");
  const [newarrival, setNewarrival] = useState([]);
  const navigate = useNavigate();

  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  return (
    <div className=" grid grid-cols-5 gap-x-6">
      {relatedProducts?.slice(0, 5).map((naItem, naIndex) => (
        <motion.div
          className="single-product"
          key={naIndex}
          onClick={() =>
            linkProductPage(naItem.id, naItem.item_status, naItem.item_status)
          }
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="single-product-img">
            <div className="w-full h-[211px]">
              <img
                src={naItem.photo}
                className="w-full !h-full !object-fill image"
              />
            </div>
            {naItem.product_promotion_percentage !== null && (
              <div className="sg-floating-badges">
                <span className="onsale percent-count">
                  -
                  {Number.parseFloat(
                    naItem.product_promotion_percentage
                  ).toFixed(0)}
                  %
                </span>
              </div>
            )}
            {/* overlay while hovering image */}
            {/* <div className="overlay"> */}
            {/* </div> */}
            {/* left side */}
            {/* {naItem.product_promotion_percentage && (
              <div className="sg-floating-badges">
                <span className="onsale percent-count">
                  {naItem.product_promotion_percentage}%
                </span>
              </div>
            )}

            {/* right side */}
            {/* <div className="sg-floating-icons">
              <span className="!flex justify-center items-center">
                <a
                  href=""
                  button-wishlist=""
                  data-product-handle="3-variable-product"
                >
                  <Tooltip
                    title="Add to Wishlist"
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    placement="left"
                  >
                    <FavoriteBorder fontSize="small" />
                  </Tooltip>
                </a>
              </span>
            </div> */}
            {/* bottom side */}
            {naItem.star_count != null && (
              <div className="sg-floating-stars !flex justify-center items-center">
                <div className="starsdiv">
                  {[...Array(naItem.star_count)].map((i) => (
                    <span key={i}>
                      <Star
                        fontSize="small"
                        id="onestar"
                        className="text-starColor"
                      />
                    </span>
                  ))}
                  {[...Array(5 - naItem.star_count)].map((i) => (
                    <span key={i}>
                      <Star
                        fontSize="small"
                        id="notstar"
                        className="text-notstarColor"
                      />
                    </span>
                  ))}
                  ({naItem.person_count})
                </div>
              </div>
            )}
          </div>

          {/* product content */}
          <div className="product-content ">
            <div className="title">
              <h3 className="popup_cart_title">
                <div>{naItem.product_name}</div>
              </h3>
            </div>

            <div className="price">
              {naItem.product_promotion_price !== 0 ? (
                <>
                  <span className="current-price">
                    <span className="money">
                      {naItem.product_promotion_price}ks
                    </span>
                  </span>
                  <span className="discounted-price ">
                    <s className="money">{naItem.simple_price}ks</s>
                  </span>
                </>
              ) : (
                <span className="current-price">
                  <span className="money">{naItem.simple_price}ks</span>
                </span>
              )}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default RPComponent;
