import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// const ShopContext = createContext({});

// const ShopProvider = ({ children }) => {
//   const [infos, setInfos] = useState<Shop>(Data);
//   return (
//     <ShopContext.Provider value={infos}>
//       {children}
//     </ShopContext.Provider>
//   );
// };

// export useShopContext = () => useContext(ShopContext);

// export default ShopProvider;

const NavigateContext = createContext(null);

const NavigateProvider = ({ children }) => {
  const navigate = useNavigate();
  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  )
}

const useNavigateContext = () => useContext(NavigateContext);

export { NavigateContext, useNavigateContext, NavigateProvider };