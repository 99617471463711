import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Modal,
  Rating,
  Tab,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import "./Receive.scss";
import StarIcon from "@mui/icons-material/Star";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import noorder from "../../assets/noorder.json";
import Lottie from "lottie-react";
import { addReview } from "../../reducers/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const labels = {
  1: "Useless",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const Receive = () => {
  const navigate = useNavigate();
  // const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(4);
  const [hover, setHover] = React.useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getReceive();
  }, []);

  const [loading, setLoading] = useState(false);

  const [showReceive, setReceive] = useState([]);
  const getReceive = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.receiveListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setReceive(response.data);
    }
  };

  const godeatil = (orderID) => {
    navigate(`/NoCancelDetail/${orderID}`);
  };

  const goReview = (orderid, productid) => {
    dispatch(addReview({ order_id: orderid, product_id: productid }));

    handleOpen();
    getreviewProduct(orderid, productid);
    setValue(4);
    setImg([]);
    setreviewProduct({});
  };

  const state = useSelector((state) => state.review);
  const [reviewProduct, setreviewProduct] = useState({});
  const getreviewProduct = async (orderid, productid) => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.reviewShowProduct,
      params: {
        order_id: orderid,
        product_id: productid,
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);

    response = response.data;
    if (response.code === 200) {
      setreviewProduct(response.data);
    }
  };

  const selectStar = (event, newValue) => {
    event.preventDefault();
    setValue((prevValue) => {
      if (newValue == null && value == prevValue) {
        return value;
      } else {
        return newValue;
      }
    });
  };

  const [img, setImg] = useState([]);
  const handleImage = (e) => {
    if (e.target.files.length > 3) {
      setImg([]);
      toast.error(t("REVIEW_FILE_LIMIT"));
      return false;
    } else {
      let validFile = true;
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size / 1024 / 1024 > 10) {
          toast.error(t(t("REVIEW_FILE_SIZE")));
          setImg([]);
          validFile = false;
          break;
        }
      }
      if (validFile) {
        setImg(e.target.files);
        // return true
      } else {
        setImg([]);
        return false;
      }
    }
  };

  const [comment, setComment] = useState(null);

  const goHistory = async () => {
    if (comment === null) {
      toast.error("Please fill the comment", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const data = {
        customer_id: localStorage.getItem("userTokenID"),
        order_id: state.order_id,
        product_id: state.product_id,
        seller_id: reviewProduct.seller_id,
        star_count: value,
        comment: comment,
      };

      const formData = new FormData();
      for (const property in data) {
        formData.append(property, data[property]);
      }

      for (let i = 0; i < img.length; i++) {
        formData.append("photo_array[]", img[i]);
      }

      const requestObj = {
        method: "post",
        url: ApiCollections.giveReview,
        params: formData,
      };

      let response = await ApiRequest(requestObj);
      if ((response.data.code = 200)) {
        navigate("/ReviewHistory");
      }
    }
  };

  return (
    <Wrapper>
      <ToastContainer autoClose={3000} />
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("Receive")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {showReceive.length > 0 ? (
                <Box value="1" sx={{ padding: "0 !important" }}>
                  {showReceive?.map((oItem, mIndex) => (
                    <div className="orderProduct" key={mIndex}>
                      <div className="orderHeader">
                        <div className="number flex justify-between items-center">
                          {t("OrderNumber")}&nbsp;:&nbsp;{oItem.order_code}
                        </div>
                        <div className="date !flex !gap-x-2">
                          {t("Date")}: <div>{oItem.date}</div>
                        </div>
                      </div>
                      {oItem?.receiveLists?.map((oVariant, oIndex) => (
                        <div className="w-full h-auto">
                          <div className="onePcProduct" key={oIndex}>
                            <div className="bodyProduct">
                              <div className="orderImage">
                                <img src={oVariant.photo} />
                              </div>
                              <div>
                                <div className="title">
                                  {oVariant.product_name || oVariant.item_name}
                                </div>
                                <div className="price">
                                  {oVariant.promotion_price !== 0 ? (
                                    <>
                                      <span className="current-price">
                                        <span className="money">
                                          {oVariant.promotion_price}ks
                                        </span>
                                      </span>
                                      <span className="detailprice ">
                                        <s className="money">
                                          {oVariant.price}ks
                                        </s>
                                      </span>
                                    </>
                                  ) : (
                                    <span className="current-price">
                                      <span className="money">
                                        {oVariant.price}ks
                                      </span>
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <div className="pt-[20px] pl-[15px] flex gap-x-4">
                                    <button
                                      className="px-3 py-2 bg-[#11917a] text-white"
                                      onClick={() => {
                                        if (oVariant.item_status != 0) {
                                          navigate(
                                            `/products/product-detail/${oVariant.product_id}/${oVariant.item_id}`
                                          );
                                        } else {
                                          navigate(
                                            `/products/product-detail/${oVariant.product_id}`
                                          );
                                        }
                                      }}
                                    >
                                      {t("ProductDetail")}
                                    </button>
                                    {oVariant.cancel_status == 0 && (
                                      <button
                                        className="px-11 py-2 bg-[#ff4d4f] text-white"
                                        onClick={() =>
                                          goReview(
                                            oVariant.order_id,
                                            oVariant.product_id
                                          )
                                        }
                                      >
                                        {t("Review")}
                                      </button>
                                    )}

                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style}>
                                        <>
                                          <div className="w-full pb-2 border-b border-gray-300">
                                            {reviewProduct.seller_name}
                                          </div>

                                          <div className="flex pt-3">
                                            <div className="w-[100px] h-[100px]">
                                              <img
                                                src={reviewProduct?.photo}
                                                className="w-full h-full object-cover"
                                              />
                                            </div>
                                            <div className="!flex flex-col justify-between pl-4">
                                              <p className="text-black">
                                                {reviewProduct?.product_name}
                                              </p>
                                              <div className="!w-auto !text-start text-black">
                                                {reviewProduct?.simple_price}Ks
                                              </div>
                                            </div>
                                            <div className="!ml-auto">
                                              <span className="text-black">
                                                {t("Quantity")}:{" "}
                                              </span>
                                              <span className="qtyNumber text-black">
                                                {reviewProduct?.quantity}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex gap-x-4 pl-56 items-center">
                                            <Rating
                                              name="hover-feedback"
                                              value={value}
                                              precision={1}
                                              getLabelText={getLabelText}
                                              onChange={selectStar}
                                              className="py-2 iconActive"
                                              onChangeActive={(
                                                event,
                                                newHover
                                              ) => {
                                                setHover(newHover);
                                              }}
                                              emptyIcon={
                                                <StarIcon
                                                  style={{
                                                    opacity: 0.55,
                                                  }}
                                                  fontSize="inherit"
                                                  className="!text-5xl"
                                                />
                                              }
                                            />
                                            {value !== null && (
                                              <Box
                                                sx={{ ml: 2 }}
                                                className="text-priceColor py-2"
                                              >
                                                {
                                                  labels[
                                                    hover !== -1 ? hover : value
                                                  ]
                                                }
                                              </Box>
                                            )}
                                          </div>
                                          <div className="w-full flex justify-center">
                                            <label
                                              for="img-selection"
                                              className="px-3 py-2 border !border-priceColor !text-priceColor cursor-pointer m-0"
                                            >
                                              {t("SelectImages")}
                                            </label>
                                          </div>
                                          <input
                                            onChange={handleImage}
                                            multiple
                                            type="file"
                                            id="img-selection"
                                            style={{ display: "none" }}
                                          />
                                          <div className="w-full flex justify-center py-3">
                                            <div className="flex space-x-6">
                                              {Array.from(img).map(
                                                (item, index) => (
                                                  <div className="w-[80px] h-[80px]">
                                                    <img
                                                      src={
                                                        item
                                                          ? URL.createObjectURL(
                                                              item
                                                            )
                                                          : null
                                                      }
                                                      className="w-full h-full object-cover"
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                          <div className="w-full flex justify-center">
                                            <TextareaAutosize
                                              maxRows={4}
                                              aria-label="maximum height"
                                              placeholder={t("EnterComment")}
                                              className="w-[70%] mx-auto !h-[150px] rounded-md border border-gray-300 p-2 !outline-priceColor"
                                              value={comment}
                                              onChange={(e) =>
                                                setComment(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="w-full flex justify-center pt-3">
                                            <Button
                                              variant="contained"
                                              color="warning"
                                              sx={{ px: 4, py: 1 }}
                                              onClick={goHistory}
                                            >
                                              {t("Submit")}
                                            </Button>
                                          </div>
                                        </>
                                      </Box>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                              <div className="qty !ml-auto">
                                <span className="">{t("Quantity")}: </span>
                                <span className="qtyNumber">
                                  {oVariant.quantity}
                                </span>
                              </div>
                            </div>
                            {oVariant.cancel_status == 1 && (
                              <div className="shopCancel">
                                We're sorry, this product has been cancelled by
                                an unforeseeable circumstances.
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="bottom">
                        <div className="paid">
                          {t("Payment")}&nbsp;:&nbsp;{oItem.payment_method}
                        </div>
                        <div
                          className="detail cursor-pointer"
                          onClick={() => godeatil(oItem.order_id)}
                        >
                          {t("OrderDetail")}
                        </div>
                      </div>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("NoProduct")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={noorder}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Receive;
