import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./OrderListNav.scss";
import { Badge } from "@mui/material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addRTS, addS, addCQA } from "../../reducers/countRTSSSlice";
import { useTranslation } from "react-i18next";

const OrderListNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authUser, token } = useSelector((state) => state.user);
  const state = useSelector((state) => state.RTSS);
  const dispatch = useDispatch();
  useEffect(() => {
    getReadyShip();
    getShipping();
    getShowCommentProduct();
  }, []);

  const getShowCommentProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.showCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      dispatch(addCQA(response.data.unseen_question_count));
    }
  };

  const [countReadyShip, setReadyShipCount] = useState([]);
  const getReadyShip = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.toShipListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setReadyShipCount(response.count);
      dispatch(addRTS(response.count));
    }
  };

  const [countShipping, setShipping] = useState([]);
  const getShipping = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.shipedListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setShipping(response.count);
      dispatch(addS(response.count));
    }
  };

  const getUserNameStatus = () => {
    let name = "";
    let customer = authUser ? authUser.user : null;
    let status = localStorage.getItem("userLoginStatus");
    if (status == "phone" && customer) {
      name = authUser.user.phone_number;
    }
    if (status == "google" && customer) {
      name = authUser.user.email;
    }
    if (status == "facebook") {
      let customerFacebook = authUser
        ? authUser.facebook_user
          ? authUser.facebook_user
          : null
        : null;
      if (customerFacebook && customerFacebook.name) {
        name = customerFacebook.name;
      }
    }
    if (customer && authUser.user.region_id && authUser.user.township_id) {
      name = authUser.user.name;
    }
    return name;
  };
  let getCustomerImgUrl = () => {
    let url = null;
    let customer = authUser ? authUser.user : null;
    if (customer && authUser.user) {
      url = authUser.user.image_url;
    }
    return url;
  };
  const goToProfile = () => {
    let customerImg = authUser
      ? authUser.user
        ? authUser.user.image_url
        : null
      : null;
    if (customerImg) {
      navigate("/UserInformation");
    } else {
      navigate("/UserProfile");
    }
  };

  return (
    <div className="acco !border-r-4 !border-[rgb(245, 245, 245)]">
      <div className="hello">{t("Greeting")}</div>
      <div className="w-full h-auto flex justify-center py-2">
        <div className="w-[80px] h-[80px] rounded-full overflow-hidden">
          {getCustomerImgUrl() ? (
            <img
              src={getCustomerImgUrl()}
              onClick={() => goToProfile()}
              className="w-full h-full object-cover cursor-pointer"
            />
          ) : (
            <img
              className="w-full h-full object-cover cursor-pointer"
              src={require("../../assets/profile.png")}
              onClick={() => goToProfile()}
            />
          )}
        </div>
      </div>
      <div
        className="user border-b-4 pb-3 cursor-pointer hover:!text-[#0876a1]"
        onClick={() => goToProfile()}
      >
        {getUserNameStatus()}
      </div>
      <div className="first">
        <p className="firstTitle">{t("MyOrders")}</p>
        <NavLink className="destion" to="/OrderList">
          {t("OrderLists")}
        </NavLink>
        <NavLink className="destion" to="/ReadyToShip">
          {t("ReadyToShip")}
          {state.countRTS !== 0 && (
            <Badge
              badgeContent={state.countRTS}
              color="error"
              sx={{ marginTop: "-20px", marginLeft: "7px" }}
            ></Badge>
          )}
        </NavLink>
        <NavLink className="destion" to="/ToShip">
          {t("Shipping")}
          {state.countS !== 0 && (
            <Badge
              badgeContent={state.countS}
              color="error"
              sx={{ marginTop: "-20px", marginLeft: "7px" }}
            ></Badge>
          )}
        </NavLink>
        <NavLink className="destion" to="/Receive">
          {t("Receive")}
        </NavLink>
        <NavLink className="destion" to="/Return">
          {t("Return")}
        </NavLink>
        <NavLink className="destion" to="/OrderCancelList">
          {t("OrderCancel")}
        </NavLink>

        <NavLink className="destion" to="/ReviewHistory">
          {t("Review")}
        </NavLink>
      </div>
      <div className="second">
        <p className="secondTitle">{t("Favorite")}</p>
        <NavLink className="destion" to="/Wishlist">
          {t("MyWishlist")}
        </NavLink>
        <NavLink className="destion" to="/FllowStore">
          {t("FollowedStores")}
        </NavLink>
        <NavLink className="destion" to="/SeeQA">
          {t("qna")}
          {state.countCQA !== 0 && (
            <Badge
              badgeContent={state.countCQA}
              color="error"
              sx={{ marginTop: "-20px", marginLeft: "7px" }}
            ></Badge>
          )}
        </NavLink>
        <NavLink className="destion" to="/Coupon">
          {t("CouponTitle")}
        </NavLink>
      </div>
      <div className="third">
        <p className="thirdTitle">{t("Content")}</p>
        <NavLink className="destion" to="/Message">
          {t("Message")}
        </NavLink>
      </div>
    </div>
  );
};

export default OrderListNav;
