import React from "react";
import { Box, Container, Divider, Grid } from "@mui/material";

import "./pDetail.scss";
import "./RatingScroll.scss";
import { Star } from "@mui/icons-material";
import rating from "../../assets/rating.json";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

const RatingPage = ({ reviewProduct }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Box className="product-rating-group">
          <Box className="">
            <div className="w-[90%] mx-auto h-[400px] overflow-y-scroll border border-black mt-8 mb-6">
              {reviewProduct.length > 0 ? (
                <div className="w-full h-full">
                  {reviewProduct?.map((d) => (
                    <div className="pl-8">
                      <Grid container spacing={2} my={1.5}>
                        <Grid item xs={1}>
                          {/* <Avatar>{d.user_avator}</Avatar> */}
                          <div className="w-[40px] h-[40px] rounded-circle overflow-hidden">
                            <img
                              src={d.customer_photo}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div>
                            <div>{d.customer_name}</div>
                            <div>{d.comment_date}</div>
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div className="flex justify-start items-start gap-x-">
                            <div className="w-[70%]">
                              <p style={{ marginTop: "5px" }}>{d.comment}</p>
                              <div className="w-full h-auto flex gap-x-4 py-4">
                                {d.photo_one && (
                                  <div className="w-[60px] h-[60px]">
                                    <img
                                      src={d.photo_one}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                )}
                                {d.photo_two && (
                                  <div className="w-[60px] h-[60px]">
                                    <img
                                      src={d.photo_two}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                )}
                                {d.photo_three && (
                                  <div className="w-[60px] h-[60px]">
                                    <img
                                      src={d.photo_three}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            {d.star_count != null && (
                              <div className="w-[20%] !flex justify-center items-center">
                                <div className="starsdiv">
                                  {[...Array(d.star_count)].map((i) => (
                                    <span key={i}>
                                      <Star
                                        fontSize="small"
                                        id="onestar"
                                        className="text-starColor"
                                      />
                                    </span>
                                  ))}
                                  {[...Array(5 - d.star_count)].map((i) => (
                                    <span key={i}>
                                      <Star
                                        fontSize="small"
                                        id="notstar"
                                        className="text-notstarColor"
                                      />
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Divider />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center relative">
                  <div className="absolute top-[100px]">{t("NoRatting")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={rating}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Container>
      <div className="border-b-[15px] border-[rgb(245, 245, 245)]"></div>
    </>
  );
};

export default RatingPage;
