import React, { useState, useEffect } from "react";
import "../../mobile/product/QuestionAnswerCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowCircleLeft,
  ContactSupport,
  QuestionAnswer,
  Send,
} from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const QuestionAndAnswer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    getAnswer();
  }, []);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const [cusQus, setCusQus] = useState("");
  let { pid, sid } = useParams();
  const [loading, setLoading] = useState(false);

  const [showAns, setAns] = useState([]);
  const getAnswer = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.giveAnswer,
      params: {
        product_id: pid,
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setAns(response.data.data);
    }
  };

  const btnQues = async () => {
    if (localStorage.getItem("userTokenID") != null) {
      let requestObj = {
        method: "post",
        url: ApiCollections.customerQuestion,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          seller_id: sid,
          product_id: pid,
          question: cusQus,
        },
      };

      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        getAnswer();
        setCusQus("");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="quesans">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("qna")}</div>
        </div>
        {showAns.length > 0 &&
          showAns.map((all, index) => (
            <div className="allQa" key={index}>
              <div className="writeQ">
                <ContactSupport id="qicon" />
                <div className="ques">
                  <div className="showDetail">
                    {all.customer_name != null ? (
                      <span className="name">{all.customer_name}</span>
                    ) : (
                      <span className="name">User : {all.customer_id}</span>
                    )}
                    <span className="date">{all.question_time}</span>
                  </div>
                  <div className="wq">{all.question}</div>
                </div>
              </div>
              {all.answer != null && (
                <div className="writeA">
                  <QuestionAnswer id="qicon" />
                  <div className="ques">
                    <div className="showDetail">
                      <span className="name">{all.answer_by}</span>
                      <span className="date">{all.answer_time}</span>
                    </div>
                    <div className="wq">{all.answer}</div>
                  </div>
                </div>
              )}
            </div>
          ))}

        <div className="footer">
          <input
            type="text"
            placeholder="Please enter the question"
            className="input-text"
            value={cusQus}
            onChange={(e) => setCusQus(e.target.value)}
          />
          <div className="send-btn" onClick={btnQues}>
            <Send id="send-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionAndAnswer;
