import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";
import { useLastLocation } from "react-router-dom-last-location";
import Wrapper from "../Wrapper";
import { Container } from "@mui/material";

const primary = purple[500]; // #f44336

export default function AuthProtectedRoute() {
  const { token } = useSelector((state) => state.user);
  const { lastLocation } = useLastLocation();
  if (lastLocation && lastLocation.pathname) {
    if (!token) {
      sessionStorage.setItem("redirectUrl", lastLocation.pathname);
    }
  }

  // show unauthorized screen if no user is found in redux store
  if (!token) {
    return (
      <Wrapper>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "70px",
              padding: "50px",
              backgroundColor: "#5582b3",
            }}
          >
            {/* <Typography variant="h1" style={{ color: 'white' }}>
              404
            </Typography> */}
            <img
              src={require("../../assets/images/Logopng.png")}
              style={{ width: "130px", height: "130px" }}
            />
            <Typography
              variant="h6"
              style={{ color: "white", padding: "30px 0", fontSize: "18px" }}
            >
              Please, Login First !
            </Typography>
            <NavLink to="/login-with-phone">
              <Button
                variant="contained"
                style={{ color: "white", fontSize: "18px" }}
              >
                Go To Login
              </Button>
            </NavLink>
          </Box>
        </Container>
      </Wrapper>
    );
  }
  // returns child route elements
  return <Outlet />;
}
