import * as React from "react";
import PropTypes from "prop-types";
import { IconButton, Container, Grid, InputBase, Badge } from "@mui/material";
import { Search, ShoppingCart, AccountCircle } from "@mui/icons-material";
import $ from "jquery";
import "./header.scss";
import LanguageMenu from "../../mobile/home/LanguageMenu";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, Space } from "antd";
import { useTranslation } from "react-i18next";

const navItems = ["Home", "About", "Contact"];

const HeaderComponent = (props) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = React.useState();
  const { totalCount } = useSelector((state) => state.cart);

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const _top = window.scrollY < 200;
    if (_top) {
      $("#main-nav").css({ top: "50px" });
    } else {
      $("#main-nav").css({
        top: 0,
        "box-shadow": "0 2px 4px 0 rgba(50, 50, 50, .25)",
      });
    }
  };

  const { Search } = Input;
  const onSearch = (value) => {
    // localStorage.setItem("search-query", value);
    // navigate("/search-product");
    const params = { search: value };
    navigate({
      pathname: "/search-product",
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleChange = (prop) => (event) => {
    setSearchValue(event.target.value);
  };

  const navigate = useNavigate();

  const addToCart = () => {
    navigate("/ShoppingCart");
  };

  const gotoOrderList = () => {
    navigate("/OrderList");
  };

  const gotoHome = () => {
    navigate("/");
  };

  return (
    <div id="main-nav">
      <Container>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ alignItems: "end" }}
        >
          <Grid item xs={2}>
            <img
              src={require("../../assets/images/MGD_Logo_Text.png")}
              onClick={gotoHome}
              className="header_logo"
              style={{ width: "160px", height: "47px", cursor: "pointer" }}
            />
          </Grid>
          <Grid item xs={7}>
            <div
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
              className="search-div"
            >
              <Space direction="vertical">
                <Search
                  placeholder={t("searchStore")}
                  onSearch={onSearch}
                  enterButton
                  id="pcsearchDiv"
                />
              </Space>
              {/* <InputBase
                sx={{ ml: 1, flex: 1, color: "#fff" }}
                placeholder="Search Product ..."
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px", color: "#fff" }}
                aria-label="search"
              >
                <Search />
              </IconButton> */}
            </div>
          </Grid>
          <Grid item xs={3} className="cart-acc-div">
            <div onClick={addToCart}>
              <Badge badgeContent={totalCount} color="error">
                <ShoppingCart
                  className="duration-300 hover:!text-priceColor cursor-pointer"
                  style={{ fontSize: "35px", color: "#fff" }}
                />
              </Badge>
            </div>
            <div onClick={gotoOrderList}>
              <AccountCircle
                className="duration-300 hover:!text-priceColor cursor-pointer"
                style={{ fontSize: "35px", color: "#fff" }}
              />
            </div>
            <LanguageMenu />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

HeaderComponent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default HeaderComponent;
