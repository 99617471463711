import React, { useEffect, useState, Component, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import "../../mobile/home/HomeCss.scss";
import Carousel from "react-material-ui-carousel";
import { StarOutline, Star, TerrainRounded } from "@mui/icons-material";
import { Input, Space } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import LanguageMenu from "./LanguageMenu";
import { useTranslation } from "react-i18next";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import appbutton from "../../assets/appbutton.json";
import ReactGA from "react-ga";
import { Box, Container, Modal } from "@mui/material";
ReactGA.initialize("UA-260952847-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    countDownTimer();
    getBanner();
    getCategoriesData();
    getPromotionsData();
    getNewarrival();
    // getFlash();
    getOfficialStore();
    getUserInfo();
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);

  const { Search } = Input;
  const onSearch = (value) => {
    localStorage.setItem("search-query", value);
    navigate("/search-product");
  };

  const navigate = useNavigate();
  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };
  const goAllProduct = (id) => {
    navigate(`/CategoryAllProduct/${id}`);
  };
  const goToPopularAll = () => {
    navigate("/PopularMore");
  };
  const goToArrAll = () => {
    navigate("/NewArivial");
  };
  const goToPromotionMore = () => {
    navigate("/PromotionMore");
  };
  const goToStoreAll = () => {
    navigate("/StoreAll");
  };
  const goStoreShop = (id) => {
    navigate(`/StoreShop/${id}`);
  };
  // const goFlashMore = () => {
  //   navigate("/FlashMore");
  // };

  const getUserInfo = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.userInfo,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      localStorage.setItem("userTokenPhone", response.data.phone_number);
      localStorage.setItem("userTokenEmail", response.data.email);
    }
  };

  const popularr = [1, 2];
  const ref = useRef(null);
  const wrapRef = useRef(null);

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  window.addEventListener("scroll", reveal);

  const [showTimeH, setShowTimeH] = useState("00");
  const [showTimeM, setShowTimeM] = useState("00");
  const [showTimes, setShowTimes] = useState("00");
  const [countDownTime, setCountDownTime] = useState("23451");

  useEffect(() => {
    setTimeout(() => {
      countDownTimer();
    }, 1000);
  }, [countDownTime]);
  useEffect(() => {
    let redirect = sessionStorage.getItem("redirectUrl");
    if (redirect) {
      sessionStorage.removeItem("redirectUrl");
      window.location.href = redirect;
    }
  }, []);

  const countDownTimer = () => {
    if (countDownTime > 0) {
      // setTimeout(() => {
      setCountDownTime(countDownTime - 1);
      var h = Math.floor(countDownTime / 3600);
      var m = Math.floor((countDownTime % 3600) / 60);
      var s = Math.floor((countDownTime % 3600) % 60);
      var hDisplay = h < 10 ? "0" + h : h;
      var mDisplay = m < 10 ? "0" + m : m;
      var sDisplay = s < 10 ? "0" + s : s;
      setShowTimeH(hDisplay);
      setShowTimeM(mDisplay);
      setShowTimes(sDisplay);
      // }, 1000)
    }
  };

  // const [flash, setFlash] = useState([]);
  // const getFlash = async () => {
  //   let obj = {
  //     method: "get",
  //     url: ApiCollections.getAllFlashSales,
  //   };
  //   let response = await ApiRequest(obj);
  //   response = response.data;
  //   if (response.code === 200) {
  //     setFlash(response.data);
  //   }
  // };

  const fetchData = () => {
    setPage(page + 1);
    getProduct();
  };

  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const getProduct = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.justForYouApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setProduct([...product, ...response.data]);
    }
  };

  const [showBanner, setBanner] = useState([]);
  const getBanner = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.banner,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setBanner(response.data);
    }
  };

  const [category, setCategories] = useState([]);
  const getCategoriesData = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.categories,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setCategories(response.data);
    }
  };

  const [promotion, setPromotion] = useState("");
  const getPromotionsData = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.promotionCampange,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setPromotion(response.data);
    }
  };

  const [newarrival, setNewarrival] = useState([]);
  const getNewarrival = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.homeNewArrivalsApi,
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setNewarrival(response.data);
    }
  };

  const [officialStore, setOfficialStore] = useState([]);
  const getOfficialStore = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.officialStores,
      params: {
        page: "1",
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setOfficialStore(response.data);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const goPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.mgdmall";
  };

  const goIos = () => {
    window.location.href = "https://apple.co/45CYpWi";
  };

  const mobstyle = {
    position: "absolute",
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Wrapper>
      {loading && <Loading open={loading} />}
      <div ref={wrapRef} className="mainHome">
        <div className="download">
          <div className="downloadText">Application Download</div>

          <div className="downloadImg" onClick={handleOpen}>
            <Lottie animationData={appbutton} loop={true} id="lot" />
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={mobstyle}>
              <div onClick={goPlayStore}>
                <img
                  src={require("../../assets/images/playphoto.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div onClick={goIos} style={{ marginTop: "5vw" }}>
                <img
                  src={require("../../assets/images/iosphoto.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Box>
          </Modal>
        </div>
        <div className="homeHeader" id="topHeader">
          <img
            src={require("../../assets/images/MGDLogo.png")}
            className="headerLogo"
          />
        </div>
        <div className="inputDiv">
          <Space direction="vertical">
            <Search
              placeholder={t("searchStore")}
              onSearch={onSearch}
              enterButton
              id="searchDiv"
            />
          </Space>
          <LanguageMenu />
        </div>
        <div className="mainslider">
          <Carousel>
            {showBanner.length > 0 &&
              showBanner.map((bannerItem, bannerIndex) => (
                <div className="sliderDiv" key={bannerIndex}>
                  <img src={bannerItem.photo} className="headerSlider" />
                </div>
              ))}
          </Carousel>
        </div>
        {category.length > 0 && (
          <div className="CategoryDiv">
            <div className="title">
              <div className="name">{t("Category")}</div>
            </div>
            <div className="showCategory">
              {category.map((item) => (
                <div
                  className="showDes"
                  key={item.id}
                  onClick={() => goAllProduct(item.id)}
                >
                  <div className="photo">
                    <img src={item.image_url} className="image" />
                  </div>
                  <div className="text">{item.category_name}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {category.length > 0 && <div className="blackSpace"></div>}
        {/* {flash.length > 0 && (
          <div className="flashSale reveal">
            <div className="title">
              <div className="name">{t("Flash_Sale")}</div>
              <div className="endTime">
                <div className="block hour">{showTimeH}</div>
                <div className="aa">:</div>
                <div className="block minute">{showTimeM}</div>
                <div className="aa">:</div>
                <div className="block second">{showTimes}</div>
              </div>
              <div className="more" onClick={() => goFlashMore()}>
                {t("seeMore")}
              </div>
            </div>
            <div className="cards">
              {flash.map((flashItem, flashIndex) => (
                <div
                  className="card"
                  key={flashIndex}
                  onClick={() => gotoproduct(flashItem.product.id)}
                >
                  <div className="cardImage">
                    <img
                      src={require("../../assets/images/c2.png")}
                      className="image"
                    />
                  </div>
                  <div className="priceBox">
                    <span className="price">
                      {flashItem.product.simple_price}ks
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
        {promotion && (
          <div className="PromotionDiv reveal">
            <div className="title">
              <div className="name">
                {t("promotion_title")}
                <span className="viewAll" onClick={() => goToPromotionMore()}>
                  {t("view_all")}
                </span>
              </div>
            </div>
            <div className="imageDiv">
              {promotion.campange && (
                <div className="firstLogo">
                  <img
                    src={promotion.campange.campange_photo}
                    className="image"
                  />
                </div>
              )}
              {promotion.products.length > 0 && (
                <div className="secondDiv">
                  {promotion.products.slice(0, 3).map((camItem, camIndex) => (
                    <div
                      className="secondLogo"
                      onClick={() =>
                        gotoproduct(
                          camItem.product_id,
                          camItem.item_status,
                          camItem.item_id
                        )
                      }
                      key={camIndex}
                    >
                      <img src={camItem.photo} className="image" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {officialStore.length > 0 && (
          <div className="storeShops reveal">
            <div className="title">
              <div className="name">{t("store_title")}</div>
            </div>
            <div className="storeCart">
              {officialStore.map((osItem, osIndex) => (
                <div
                  className="cart"
                  key={osIndex}
                  onClick={() => goStoreShop(osItem.id)}
                >
                  <img src={osItem.photo} className="image" />
                </div>
              ))}
              <div className="cart" onClick={() => goToStoreAll()}>
                <div className="seeMore">{t("seeMore")}</div>
              </div>
            </div>
          </div>
        )}
        {newarrival.length > 0 && (
          <div>
            <div className="goodsNew reveal">
              <div className="title">
                <div className="name">{t("arrivals_title")}</div>
              </div>
              <div className="cards">
                {newarrival.slice(0, 10).map((newItem, newIndex) => (
                  <div
                    className="card"
                    onClick={() =>
                      gotoproduct(
                        newItem.id,
                        newItem.item_status,
                        newItem.item_id
                      )
                    }
                    key={newIndex}
                  >
                    <div className="cardImage">
                      <img src={newItem.photo} />
                    </div>
                    <div className="title">{newItem.product_name}</div>
                    <div className="priceBox">
                      <div className="price">{newItem.simple_price}ks</div>
                    </div>
                  </div>
                ))}
                <div className="card">
                  <div className="seeMore" onClick={() => goToArrAll()}>
                    {t("seeMore")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="popularDiv reveal">
          <div className="title">
            <div className="name">
              Popular Products
              <span className="viewAll" onClick={() => goToPopularAll()}>
                View All
              </span>
            </div>
          </div>
          <div className="allCards">
            <div className="verImage">
              <img src={require("../../assets/images/c2.png")} />
            </div>
            <div className="oneRightCard">
              {popularr.map(() => (
                <div className="cardChange">
                  <div className="cardImage">
                    <img src={require("../../assets/images/c2.png")} />
                  </div>
                  <div>
                    <div className="title">
                      K-Lite Rechargeable 5W Led Torch Light Kl-7131
                    </div>
                    <div className="price">1500 ks</div>
                    <div className="starspop">
                      {[...Array(5)].map((e, i) => (
                        <span>
                          <StarOutline fontSize="small" id="onestar" />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div ref={ref}>
          <ul className="floor reveal">
            <li className="floorItem">
              <div className="top">
                <div className="title">{t("just_title")}</div>
              </div>
              <div className="goodsBox">
                {product.length > 0 &&
                  product.map((proitem, proindex) => (
                    <div
                      className="floorGoods"
                      onClick={() =>
                        gotoproduct(
                          proitem.id,
                          proitem.item_status,
                          proitem.item_id
                        )
                      }
                      key={proindex}
                    >
                      <img src={proitem.thumbnail_photo} />
                      {proitem.product_promotion_percentage !== null && (
                        <div className="promotionDiscountt">
                          <div className="percent">
                            -
                            {Number.parseFloat(
                              proitem.product_promotion_percentage
                            ).toFixed(0)}
                            %{/* {proitem.product_promotion_percentage}% */}
                          </div>
                        </div>
                      )}
                      <p className="title">{proitem.product_name}</p>
                      {proitem.product_promotion_price !== 0 ? (
                        <p className="price">
                          {proitem.product_promotion_price}
                          ks
                          <span className="disPrice">
                            {proitem.simple_price}ks
                          </span>
                        </p>
                      ) : (
                        <p className="price">{proitem.simple_price}ks</p>
                      )}
                      {proitem.star_count != null && (
                        <div className="starsdiv">
                          {[...Array(proitem.star_count)].map((i) => (
                            <span key={i}>
                              <Star fontSize="small" id="onestar" />
                            </span>
                          ))}
                          {[...Array(5 - proitem.star_count)].map((i) => (
                            <span key={i}>
                              <Star fontSize="small" id="notstar" />
                            </span>
                          ))}
                          ({proitem.person_count})
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </li>
          </ul>
        </div>
        {product.length > 0 && (
          <InfiniteScroll
            dataLength={product.length}
            next={fetchData}
            hasMore={true}
            loader={
              pageCount >= page ? (
                <div className="loading">{t("loading")}</div>
              ) : (
                <div className="loading">{t("end")}</div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </Wrapper>
  );
};

export default Home;

// https://www.youtube.com/watch?v=gJ5MnBVl1ZM
// scroll call api note
