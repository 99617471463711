import React, { useState, useEffect } from "react";
import "../../mobile/account/OrderCancelListCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import Lottie from "lottie-react";
import noorder from "../../assets/noorder.json";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const OrderCancelList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCancelList();
  }, []);

  const [loading, setLoading] = useState(false);

  const [showCancelList, setCancelList] = useState([]);
  const getCancelList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.cancelListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setCancelList(response.data);
    }
  };

  const godeatil = (orderID) => {
    navigate(`/CancelDetail/${orderID}`);
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="cancelListDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("OrderCancelLists")}</div>
        </div>
        {showCancelList.length > 0 ? (
          <div>
            {showCancelList.map((orderItem, orderIndex) => (
              <div className="listAll" key={orderIndex}>
                <div className="top">
                  <div className="orderNo">
                    {t("OrderNumber")} : {orderItem.order_code}
                  </div>
                  <div className="date">{orderItem.date}</div>
                </div>
                {orderItem.orderLists.map((listItem, listIndex) => (
                  <div key={listIndex}>
                    <div className="orderlistDiv">
                      <div className="cardImage">
                        <img src={listItem.photo} />
                      </div>
                      <div>
                        <div className="title">
                          {listItem.product_name || listItem.item_name}
                        </div>
                        <div className="price">
                          {listItem.promotion_price !== 0 ? (
                            <span className="ori">
                              {listItem.promotion_price} ks &nbsp;&nbsp;
                              <s>{listItem.price}ks</s>
                            </span>
                          ) : (
                            <span className="ori">{listItem.price} ks</span>
                          )}
                        </div>
                        <div className="qty">
                          {t("Quantity")}:{" "}
                          <span className="count">{listItem.quantity}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="bottom">
                  <div className="paid">
                    {t("Payment")}&nbsp;:&nbsp;{orderItem.payment_method}
                  </div>
                  <div
                    className="detail"
                    onClick={() => godeatil(orderItem.order_id)}
                  >
                    {t("OrderDetail")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("NoProduct")}</div>
            <div className="iconWrap">
              <Lottie animationData={noorder} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderCancelList;
