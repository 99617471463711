import React, { useEffect } from "react";
import "../../mobile/account/MgdAboutCss.scss";
import { ArrowCircleLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TermPolicy = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="mgdAbout">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("tap")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("termsPolicy")}</div>
          <div className="des">{t("policyDes")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("conditions")}</div>
          <div className="des">{t("conditionDes")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("accountMain")}</div>
          <div className="des">{t("accountFir")}</div>
          <div className="des">{t("accountSec")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("intellectual")}</div>
          <div className="des">{t("intellectualDes")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("pricing")}</div>
          <div className="des">{t("pricingDes")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("suspend")}</div>
          <div className="des">{t("suspendFir")}</div>
          <div className="des">{t("suspendSec")}</div>
          <div className="des">{t("suspendThd")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("shippingMain")}</div>
          <div className="des">{t("shippingDes")}</div>
        </div>
        <div className="termDiv">
          <div className="title">{t("refunds")}</div>
          <div className="des">{t("refundsDes")}</div>
        </div>
        <div className="termEnter">
          <div className="title">{t("reserve")}</div>
          <div className="des">{t("reserveDes")}</div>
        </div>
      </div>
    </>
  );
};

export default TermPolicy;
