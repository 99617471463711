import React, { useState, useEffect } from "react";
import "../../mobile/seeMore/NewArivialCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, Star } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const NewArivial = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData();
  }, []);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const getMore = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.newArrivalsApi,
      params: {
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setProduct([...product, ...response.data]);
    }
  };

  const fetchData = () => {
    setPage(page + 1);
    getMore();
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="NewArivial">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("arrivals_title")}</div>
        </div>
        <ul className="morefloor">
          <li className="moreFloorItem">
            <div className="goodsBox">
              {product.length > 0 &&
                product.map((proitem, proindex) => (
                  <div
                    className="floorGoods"
                    onClick={() =>
                      gotoproduct(
                        proitem.id,
                        proitem.item_status,
                        proitem.item_id
                      )
                    }
                    key={proindex}
                  >
                    <img src={proitem.thumbnail_photo} />
                    {proitem.product_promotion_percentage !== null && (
                      <div className="promotionDiscountt">
                        <div className="percent">
                          -
                          {Number.parseFloat(
                            proitem.product_promotion_percentage
                          ).toFixed(0)}
                          %{/* {proitem.product_promotion_percentage}% */}
                        </div>
                      </div>
                    )}
                    <p className="title">{proitem.product_name}</p>
                    {proitem.product_promotion_price !== 0 ? (
                      <p className="price">
                        {proitem.product_promotion_price}
                        ks
                        <span className="disPrice">
                          {proitem.simple_price}ks
                        </span>
                      </p>
                    ) : (
                      <p className="price">{proitem.simple_price}ks</p>
                    )}

                    {proitem.star_count != null && (
                      <div className="starsdiv">
                        {[...Array(proitem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="onestar" />
                          </span>
                        ))}
                        {[...Array(5 - proitem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="notstar" />
                          </span>
                        ))}
                        ({proitem.person_count})
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </li>
        </ul>
        {product.length > 0 && (
          <InfiniteScroll
            dataLength={product.length}
            next={fetchData}
            hasMore={true}
            loader={
              pageCount >= page ? (
                <div className="loading">{t("loading")}</div>
              ) : (
                <div className="loading">{t("end")}</div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default NewArivial;
