import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { AiOutlineSearch } from "react-icons/ai";
import { Button, Container } from "@mui/material";
import { StarOutline } from "@mui/icons-material";
import StoreProductComponent from "./StoreProductComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";
import "./StorePage.scss";

const StorePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
    getStoreShop();
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const { sellerId } = useParams();

  const [loading, setLoading] = useState(false);

  const [followStore, setFollowStore] = useState(0);

  const [allAboutStore, setAllAboutStore] = useState();
  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.storeShop,
      params: {
        seller_id: sellerId,
        customer_id: localStorage.getItem("userTokenID"),
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);

    response = response.data;
    if (response.code === 200) {
      setAllAboutStore(response.data);
      setFollowStore(response?.data?.Header?.follow_status);
    }
  };

  const goToFollow = async (index) => {
    if (localStorage.getItem("userTokenID")) {
      setFollowStore(index);
      let requestObj = {
        method: "post",
        url: ApiCollections.followStatus,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          seller_id: sellerId,
          follow_status: index,
        },
      };

      let response = await ApiRequest(requestObj);

      response = response.data;
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error("something wrong when try to follow");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  const header = allAboutStore?.Header;
  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  return (
    <Wrapper>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}

      <Container>
        <div className="w-full h-auto ">
          {/* <div className="w-full h-auto mt-[70px] flex justify-end">
            <form className="w-[400px] h-[40px] flex">
              <input
                placeholder="Search Store"
                className="outline-none w-[80%] h-full flex justify-start items-center pl-2 border border-gray-500"
              />
              <button className="w-[20%] h-full flex justify-center items-center bg-starColor">
                <AiOutlineSearch className="text-xl text-white" />
              </button>
            </form>
          </div> */}
          <div className="w-full h-[200px] mt-[70px] mb-[20px]">
            <img src={header?.background_photo} className="w-full h-full" />
          </div>
          <div className="w-full h-auto flex justify-between">
            <div className="w-[80%] flex">
              <div className="w-[70px] h-[70px]">
                <img
                  src={header?.logo}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col justify-center pl-2">
                <p className="text-xl">{header?.shop_name}</p>
              </div>
            </div>
            <div className="flex justify-end items-end w-[20%]">
              {followStore === 1 ? (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => goToFollow(0)}
                >
                  {t("followed")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => goToFollow(1)}
                >
                  {t("follow")}
                </Button>
              )}
            </div>
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t("bestSeller")}
                </p>
              </div>
            </div>
            {allAboutStore?.best_seller?.length > 0 && (
              <>
                <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
                  {allAboutStore?.best_seller
                    ?.slice(0, 3)
                    .map((ASItem, ASIndex) => (
                      <div
                        className="pHC relative flex w-full h-auto justify-between py-4 px-4 border-b border-gray-400 cursor-pointer z-10"
                        onClick={() =>
                          linkProductPage(
                            ASItem.id,
                            ASItem.item_status,
                            ASItem.item_id
                          )
                        }
                        key={ASIndex}
                      >
                        {ASItem.product_promotion_percentage != null && (
                          <div className="absolute top-0 -left-5 text-[12px] text-white rounded-full w-[40px] h-[40px] bg-[#408d7f] flex justify-center items-center">
                            -
                            {Number.parseFloat(
                              ASItem.product_promotion_percentage
                            ).toFixed(0)}
                            %
                          </div>
                        )}
                        <div className="w-[40%] h-[130.13px] -z-10 overflow-hidden">
                          <img
                            className="w-full h-full object-cover"
                            src={ASItem.photo}
                          />
                        </div>
                        <div className="w-[50%] flex flex-col justify-center gap-y-2">
                          <div>{ASItem.product_name}</div>
                          <div className="price">
                            {ASItem.product_promotion_price !== 0 ? (
                              <>
                                <span className="current-price">
                                  <span className="money">
                                    {ASItem.product_promotion_price}ks
                                  </span>
                                </span>
                                <span className="discounted-price ">
                                  <s className="money">
                                    {ASItem.simple_price}ks
                                  </s>
                                </span>
                              </>
                            ) : (
                              <span className="current-price">
                                <span className="money">
                                  {ASItem.simple_price}ks
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p className="text-[22px] text-mainColor font-bold">
                  {t("allProduct")}
                </p>
              </div>
            </div>
            <StoreProductComponent sellerId={sellerId} />
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default StorePage;
