import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countRTS: 0,
  countS: 0,
  countCQA: 0,
};

export const countRTSSSlice = createSlice({
  name: "countShipping",
  initialState,
  reducers: {
    addRTS: (state, action) => {
      state.countRTS = action.payload;
    },
    addS: (state, action) => {
      state.countS = action.payload;
    },
    addCQA: (state, action) => {
      state.countCQA = action.payload;
    },
  },
});
export const { addRTS, addS, addCQA } = countRTSSSlice.actions;

export default countRTSSSlice.reducer;
