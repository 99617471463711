import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useSelector, useDispatch } from "react-redux";

import { setLanguge, setLangugeName } from "../../reducers/user";

import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "English" },
  cn: { nativeName: "中国" },
  mm: { nativeName: "မြန်မာ" },
};

export default function LanguageMenu() {
  const { languageNative } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [lang, setLang] = React.useState('English');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeLocalLang = React.useCallback(
    (lang) => () => {
      dispatch(setLanguge(lang));
      dispatch(setLangugeName(lngs[lang].nativeName));
      i18n.changeLanguage(lang);
      // setLang(lngs[lang].nativeName);
      setAnchorEl(null);
      window.location.reload();
    },
    []
  );
  return (
    <div>
      <Button
        id="lang-positioned-button"
        variant="contained"
        color="secondary"
        aria-controls={open ? "lang-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {languageNative}
      </Button>
      <Menu
        id="lang-positioned-menu"
        aria-labelledby="lang-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} onClick={changeLocalLang(lng)}>
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
