import React, { useEffect, useState } from "react";
import "../../mobile/account/CancelDetailCss.scss";
import { ArrowCircleLeft, FmdGood, Person, Phone } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const CancelDetail = () => {
  const navigate = useNavigate();
  let { orderID } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getCancelDetail();
  }, []);

  const [loading, setLoading] = useState(false);

  const goback = () => {
    navigate(-1);
  };

  const gotoproduct = (product_id, item_status, item_id) => {
    console.log(item_status);
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  //   const goCancel = () => {
  //     navigate(`/OrderCancel/${orderID}`);
  //   };

  const [cancelDetail, setCancelDetail] = useState([]);
  const getCancelDetail = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.orderDetailApi,
      params: {
        order_id: orderID,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setCancelDetail(response.data);
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="cancelDetailDiv">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("OrderDetail")}</div>
        </div>
        {cancelDetail.orderLists &&
          cancelDetail.orderLists.map((detailItem, detailIndex) => (
            <div className="orderProduct" key={detailIndex}>
              <img src={detailItem.photo} />
              <div className="detail">
                <div className="title">
                  {detailItem.item_name || detailItem.product_name}
                </div>
                <div className="price">
                  {detailItem.promotion_price !== 0 ? (
                    <span className="ori">
                      {detailItem.promotion_price} ks &nbsp;&nbsp;
                      <s>{detailItem.price}ks</s>
                    </span>
                  ) : (
                    <span className="ori">{detailItem.price} ks</span>
                  )}
                </div>
                {detailItem.color && (
                  <div className="itemBox">
                    <div className="colorBox">
                      {t("Color")}&nbsp;:&nbsp;
                      <span className="coll">{detailItem.color}</span>
                    </div>
                    <div className="sizeBox">
                      {t("Size")}&nbsp;:&nbsp;
                      <span className="siz">{detailItem.size}</span>
                    </div>
                  </div>
                )}
                <div className="buyNew">
                  <div className="qtyBox">
                    {t("Quantity")}&nbsp;:&nbsp;
                    <span className="qty">{detailItem.quantity}</span>
                  </div>
                  <div
                    className="detail"
                    onClick={() =>
                      gotoproduct(
                        detailItem.product_id,
                        detailItem.item_status,
                        detailItem.item_id
                      )
                    }
                  >
                    {t("buyAgain")}
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="tax">
          <div className="total">
            <span className="name">{t("OrderNumber")}</span>
            <span className="number">{cancelDetail.order_code}</span>
          </div>
          <div className="total">
            <span className="name">{t("Date")}</span>
            <span className="number">{cancelDetail.date}</span>
          </div>
          <div className="total">
            <span className="name">{t("Payment")}</span>
            <span className="number">{cancelDetail.payment_method}</span>
          </div>
          <div className="total">
            <span className="name">{t("priceTotal")}</span>
            <span className="number">{cancelDetail.total_product_price}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("delivery")}</span>
            <span className="number">{cancelDetail.delivery_fee}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("discount")}</span>
            <span className="number">{cancelDetail.total_discount}ks</span>
          </div>
          <div className="total">
            <span className="name">{t("total")}</span>
            <span className="number">{cancelDetail.total}ks</span>
          </div>
        </div>
        <div className="address">
          <div className="title">
            <span className="name">{t("address")}</span>
          </div>
          <div className="detail">
            <FmdGood id="img" />
            <div className="des">
              {cancelDetail.address},{cancelDetail.township_name},
              {cancelDetail.region_name}
            </div>
          </div>
          <div className="detail">
            <Person id="img" />
            <div className="des">{cancelDetail.name}</div>
          </div>
          <div className="detail">
            <Phone id="img" />
            <div className="des">{cancelDetail.phone_number}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelDetail;
