import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Box, Fab } from "@mui/material";

import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { setAuthUser } from "../../reducers/user";
import getCustomerCartItems from "../helper/CustomerCart";

function SocialLoginCallback() {
  const location = useLocation();
  let { provider } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const handleSocialLogin = async () => {
    let requestObj = {
      method: "get",
      url:
        ApiCollections.loginCallBackSocial + `/${provider}${location.search}`,
    };
    const response = await ApiRequest(requestObj);
    if (response.flag === false) {
    } else if (response.data.code === 200) {
      setOpen(false);
      //set aut user data to state
      dispatch(setAuthUser(response.data.data));
      getCustomerCartItems(response.data.data.user.id);
      localStorage.setItem("userTokenID", response.data.data.user.id);
      localStorage.setItem("userLoginStatus", provider);
      navigate("/"); // step two for login (that must enter sms code)
    } else {
      console.log("Something Wrong Login with Social Account!");
    }
  };
  useEffect(() => {
    handleSocialLogin();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab aria-label="save" sx={{ backgroundColor: "#777" }}>
            <img
              src={require("../../assets/images/Logopng.png")}
              style={{ width: 90, height: 90, position: "relative" }}
            />
          </Fab>
          <CircularProgress
            size={130}
            sx={{
              color: "gold",
              position: "absolute",
              top: -41,
              left: -41,
              zIndex: 1,
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}

export default SocialLoginCallback;
