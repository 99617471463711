import React from "react";
import { Backdrop, CircularProgress, Box, Fab } from "@mui/material";
function Loading({ open }) {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "transparent",
        }}
        open={open}
      >
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab
            aria-label="save"
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <img
              src={require("../../assets/images/Logopng.png")}
              style={{ width: 50, height: 50, position: "relative" }}
            />
          </Fab>
          <CircularProgress
            size={80}
            sx={{
              color: "#f5c565",
              position: "absolute",
              top: -14,
              left: -14,
              zIndex: 1,
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}
export default Loading;
