import React, { useState, useEffect } from "react";
import "../../mobile/product/CommentCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft, Star } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";

const Comment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let { productId } = useParams();

  const [loading, setLoading] = useState(false);

  const goback = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getreviewProduct();
  }, []);

  const [reviewProduct, setreviewProduct] = useState([]);
  const getreviewProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.productReviewApi,
      params: {
        product_id: productId,
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="comment">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("CustomerReview")}</div>
        </div>
        {reviewProduct.length > 0 ? (
          <div className="displayComment">
            {reviewProduct.map((reItem, reIndex) => (
              <div className="listwrap" key={reIndex}>
                <div className="listHd">
                  <div className="pullLeft">
                    <img src={reItem.customer_photo} />
                    &nbsp;&nbsp;
                    <span>{reItem.customer_name}</span>
                  </div>
                  <div className="listPurTimer">{reItem.comment_date}</div>
                  <div className="pullRight">
                    {[...Array(reItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="onestar" />
                      </span>
                    ))}
                    {[...Array(5 - reItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="notstar" />
                      </span>
                    ))}
                  </div>
                </div>
                <div className="listCom">{reItem.comment}</div>
                <div className="listImgMain clearfix">
                  {reItem.photo_one && <img src={reItem.photo_one} />}
                  {reItem.photo_two && <img src={reItem.photo_two} />}
                  {reItem.photo_three && <img src={reItem.photo_three} />}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("NoComment")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Comment;
