import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import { Star } from "@mui/icons-material";
import { FavoriteBorder } from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "../home/product.scss";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";

const GrandAllProduct = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  let { grandId } = useParams();

  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const navigate = useNavigate();

  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    setPage(page + 1);
    getMore();
  };

  const getMore = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.grandProducts,
      params: {
        grand_id: grandId,
        page: page,
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setPageCount(response.data.last_page);
      setProduct([...product, ...response.data.data]);
    }
  };

  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        {loading && <Loading open={loading} />}
        <div>
          {product.length > 0 ? (
            <div className="w-full h-auto py-[20px]">
              <div className="product-wrapper grid grid-cols-5 gap-x-6">
                {product?.map((naItem, naIndex) => (
                  <motion.div
                    className="single-product"
                    key={naIndex}
                    onClick={() =>
                      linkProductPage(
                        naItem.id,
                        naItem.item_status,
                        naItem.item_id
                      )
                    }
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="single-product-img">
                      <div className="w-full h-[211px]">
                        <img
                          src={naItem.thumbnail_photo}
                          className="w-full !h-full !object-fill image"
                        />
                      </div>

                      {naItem.product_promotion_percentage !== null && (
                        <div className="sg-floating-badges">
                          <span className="onsale percent-count">
                            -
                            {Number.parseFloat(
                              naItem.product_promotion_percentage
                            ).toFixed(0)}
                            %
                          </span>
                        </div>
                      )}

                      <div className="sg-floating-icons">
                        <span className="!flex justify-center items-center">
                          <a
                            href=""
                            button-wishlist=""
                            data-product-handle="3-variable-product"
                          >
                            <Tooltip
                              title="Add to Wishlist"
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              placement="left"
                            >
                              <FavoriteBorder fontSize="small" />
                            </Tooltip>
                          </a>
                        </span>
                      </div>

                      {/* bottom side */}
                      {naItem.star_count != null && (
                        <div className="sg-floating-stars !flex justify-center items-center">
                          <div className="starsdiv">
                            {[...Array(naItem.star_count)].map((i) => (
                              <span key={i}>
                                <Star
                                  fontSize="small"
                                  id="onestar"
                                  className="text-starColor"
                                />
                              </span>
                            ))}
                            {[...Array(5 - naItem.star_count)].map((i) => (
                              <span key={i}>
                                <Star
                                  fontSize="small"
                                  id="notstar"
                                  className="text-notstarColor"
                                />
                              </span>
                            ))}
                            ({naItem.person_count})
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="product-content ">
                      <div className="title">
                        <h3 className="popup_cart_title">
                          <div>{naItem.product_name}</div>
                        </h3>
                      </div>

                      <div className="price">
                        {naItem.product_promotion_price !== 0 ? (
                          <>
                            <span className="current-price">
                              <span className="money">
                                {naItem.product_promotion_price}ks
                              </span>
                            </span>
                            <span className="discounted-price ">
                              <s className="money">{naItem.simple_price}ks</s>
                            </span>
                          </>
                        ) : (
                          <span className="current-price">
                            <span className="money">
                              {naItem.simple_price}ks
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
              {product.length > 0 && (
                <InfiniteScroll
                  dataLength={product.length}
                  next={fetchData}
                  hasMore={true}
                  loader={
                    pageCount >= page ? (
                      <div className="text-xl w-full py-4 text-center">
                        {t("loading")}
                      </div>
                    ) : (
                      <div className="text-xl w-full py-4 text-center">
                        {t("end")}
                      </div>
                    )
                  }
                ></InfiniteScroll>
              )}
            </div>
          ) : (
            <div className="w-full h-auto flex flex-col items-center mt-[100px]">
              <div>{t("noProduct")}</div>
              <div className="w-[300px] h-[300px]">
                <Lottie
                  animationData={nodata}
                  loop={true}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default GrandAllProduct;
