import React, { useState, useEffect } from "react";
import "../../mobile/account/UserProfileCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowCircleLeft,
  Phone,
  Apartment,
  Person,
  AddLocation,
  Business,
  Facebook,
  Google,
  Portrait,
} from "@mui/icons-material";
import { MenuItem, Button } from "@mui/material";
import Select from "react-select";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import Loading from "../../components/commons/Loading";

import { updateAuthUser } from "../../reducers/user";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const [userPhoto, setUserPhoto] = useState([]);
  const { authUser, error } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [region, setRegion] = useState("");
  const [showRegion, setShowRegion] = useState("");
  const [regionId, setRegionId] = useState(null);
  const [townshipId, setTownshipId] = useState(null);
  const [townshipRequire, setTownshipRequire] = useState(false);
  const [regionRequire, setRegionRequire] = useState(false);

  const handleChangeRegion = (regionId) => {
    setTownship(null);
    setRegionId(regionId);
    getTownships(regionId);
    setTownshipId(null);
    setRegionRequire(false);
  };
  const getRegion = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.regionsApi,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setRegion(response.data);
    }
  };

  const [township, setTownship] = useState("");
  const [showTownship, setShowTownship] = useState("");
  const handleChangeTownship = (twonshipId) => {
    setTownshipId(twonshipId);
    setTownshipRequire(false);
  };
  const getTownships = async (regId) => {
    let obj = {
      method: "get",
      url: ApiCollections.townshipsApi,
      params: {
        region_id: regId,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setTownship(response.deliFeeRegions);
    }
  };

  useEffect(() => {
    let regionId = authUser
      ? authUser.user
        ? authUser.user.region_id
        : null
      : null;
    let townshipId = authUser
      ? authUser.user
        ? authUser.user.township_id
        : null
      : null;

    getRegion();
    getTownships(regionId);
    setRegionId(regionId);
    setTownshipId(townshipId);
  }, []);

  const getLoginCustomerData = (column) => {
    let colmnValue = "";
    let customer = authUser ? (authUser.user ? authUser.user : null) : null;
    if (customer && column == "phone") {
      if (customer.phone_number) {
        colmnValue = customer.phone_number;
      }
    }
    if (customer && column == "email") {
      if (customer.email) {
        colmnValue = customer.email;
      }
    }
    if (customer && column == "name") {
      if (customer.name) {
        colmnValue = customer.name;
      }
    }
    if (customer && column == "address") {
      if (customer.address) {
        colmnValue = customer.address;
      }
    }
    if (customer && column == "facebook_name") {
      if (customer.facebook_name) {
        colmnValue = customer.facebook_name;
      }
      if (!customer.facebook_name) {
        let customerFacebook = authUser
          ? authUser.facebook_user
            ? authUser.facebook_user
            : null
          : null;
        if (customerFacebook && customerFacebook.name) {
          colmnValue = customerFacebook.name;
        }
      }
    }
    return colmnValue;
  };
  const getUserLoginStatus = () => {
    let userLoginStatus = null;
    let customer = authUser ? authUser.user : null;
    let status = localStorage.getItem("userLoginStatus");
    if (status == "phone" && customer) {
      userLoginStatus = "phone";
    }
    if (status == "facebook" && customer) {
      userLoginStatus = "facebook";
    }
    if (status == "google" && customer) {
      userLoginStatus = "google";
    }
    return userLoginStatus;
  };

  const emailValidate = () => {
    let object = Yup.string();
    let status = getUserLoginStatus();
    if (status && (status == "google" || status == "facebook")) {
      object = Yup.string().required().label("Email");
    }
    return object;
  };
  const facebookNameValidate = () => {
    let object = Yup.string();
    let status = getUserLoginStatus();
    if (status && status == "facebook") {
      object = Yup.string().required().label("Facebook Account Name");
    }
    return object;
  };
  const UserProfileSechema = Yup.object({
    phone: Yup.string().required().label("Phone Number"),
    user_name: Yup.string().required().label("User Name"),
    // email: Yup.string().required().label("Email"),
    email: emailValidate(),
    // facebook_name: Yup.string().required().label("Facebook Account Name"),
    facebook_name: facebookNameValidate(),
    address: Yup.string().required().label("Address"),
    // township: Yup.string().required().label("Township"),
    // region: Yup.string().required().label("Region"),
  });

  const formik = useFormik({
    initialValues: {
      phone: getLoginCustomerData("phone"),
      user_name: getLoginCustomerData("name"),
      email: getLoginCustomerData("email"),
      facebook_name: getLoginCustomerData("facebook_name"),
      address: getLoginCustomerData("address"),
    },
    validationSchema: UserProfileSechema,
    onSubmit: async (values) => {
      saveUserProfileData(values);
    },
  });

  const saveUserProfileData = async (formDatas) => {
    if (!townshipId || !regionId) {
      if (!regionId) {
        setRegionRequire(true);
      }
      if (!townshipId) {
        setTownshipRequire(true);
      }
    } else {
      setTownshipRequire(false);
      setRegionRequire(false);
      let customerId = authUser
        ? authUser.user
          ? authUser.user.id
          : null
        : null;
      const userImg = userPhoto.length ? userPhoto[0] : null;
      let data = {
        ...formDatas,
        region: regionId,
        township: townshipId,
        profile_image: userImg,
        customer_id: customerId,
      };
      const formData = new FormData();

      for (const property in data) {
        formData.append(property, data[property]);
      }
      let requestObj = {
        method: "post",
        url: ApiCollections.updateCustomerProfile,
        params: formData,
      };

      setLoading(true);
      const response = await ApiRequest(requestObj);
      setLoading(false);
      if (response.flag === false) {
        toast.error("Profile Update Fail!");
      } else if (response.data.code === 200) {
        dispatch(updateAuthUser({ user: response.data.data })); //set aut user data to state
        toast.success("Profile Update Success!");
        navigate("/PersonalCenter");
      } else if (response.data.code === 400) {
        toast.error("Profile Not Found!");
      } else {
        toast.error("Somethign Wrong!");
      }
    }
  };

  return (
    <div className="UserProfileDiv">
      {loading && <Loading open={loading} />}
      <div className="header" id="topHeader">
        <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
        <div className="title">{t("UserProfile")}</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="nameBox">
          <div className="name">{t("UserPhoto")}</div>
          <div className="enterBox">
            <Portrait id="photo" />
            <input
              onChange={(e) => {
                setUserPhoto(e.target.files);
              }}
              type="file"
              id="chosePhoto"
              style={{ display: "none" }}
            />
            <div>
              {userPhoto.length > 0 ? (
                <div className="showPhoto">
                  {Array.from(userPhoto).map((item) => {
                    return (
                      <span key={item}>
                        <label for="chosePhoto">
                          <img
                            style={{
                              width: "25vw",
                              height: "25vw",
                              marginLeft: "5vw",
                            }}
                            src={item ? URL.createObjectURL(item) : null}
                          />
                        </label>
                      </span>
                    );
                  })}
                </div>
              ) : (
                <div className="showPhoto">
                  <label for="chosePhoto">
                    <img
                      src={require("../../assets/profile.png")}
                      style={{
                        width: "25vw",
                        height: "25vw",
                        marginLeft: "5vw",
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="nameBox">
          <div className="name">{t("userName")}</div>
          <div className="enterBox">
            <Person id="photo" />
            <input
              type="text"
              placeholder="Enter your name"
              className="inputEnter"
              name="user_name"
              value={formik.values.user_name}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.user_name && Boolean(formik.errors.user_name) && (
            <CutomErrorMessage error={formik.errors.user_name} />
          )}
        </div>

        <div className="nameBox">
          <div className="name">{t("phoneNumber")}</div>
          <div className="enterBox">
            <Phone id="photo" />
            <input
              type="number"
              placeholder="Enter your phone number"
              className="inputEnter"
              name="phone"
              disabled={
                getUserLoginStatus() && getUserLoginStatus() == "phone"
                  ? "disabled"
                  : ""
              }
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.phone && Boolean(formik.errors.phone) && (
            <CutomErrorMessage error={formik.errors.phone} />
          )}
        </div>

        <div className="nameBox">
          <div className="name">{t("EmailAddress")}</div>
          <div className="enterBox">
            <Google id="photo" />
            <input
              type="text"
              placeholder="Enter your email"
              className="inputEnter"
              name="email"
              disabled={
                (getUserLoginStatus() && getUserLoginStatus() == "facebook") ||
                getUserLoginStatus() == "google"
                  ? "disabled"
                  : ""
              }
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.email && Boolean(formik.errors.email) && (
            <CutomErrorMessage error={formik.errors.email} />
          )}
        </div>

        <div className="nameBox">
          <div className="name">{t("FacebookName")}</div>
          <div className="enterBox">
            <Facebook id="photo" />
            <input
              type="text"
              placeholder="Enter your facebook account"
              className="inputEnter"
              name="facebook_name"
              value={formik.values.facebook_name}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.facebook_name &&
            Boolean(formik.errors.facebook_name) && (
              <CutomErrorMessage error={formik.errors.facebook_name} />
            )}
        </div>

        <div className="nameBox">
          <div className="name">{t("address")}</div>
          <div className="enterBox">
            <Apartment id="photo" />
            <input
              type="text"
              placeholder="Enter your Building/ House No/ Floor/ Street"
              className="inputEnter"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.address && Boolean(formik.errors.address) && (
            <CutomErrorMessage error={formik.errors.address} />
          )}
        </div>

        <div className="nameBox">
          <div className="name">{t("region")}</div>
          <div className="enterBox">
            <AddLocation id="selectphoto" />
            <div style={{ width: "90%" }}>
              {region && (
                <Select
                  defaultValue={
                    region[
                      region.findIndex(
                        (x) => x.region_id == authUser.user.region_id
                      )
                    ]
                  }
                  options={region}
                  getOptionLabel={(reg) => reg.region_name_en}
                  getOptionValue={(reg) => reg.region_id}
                  onChange={(value) => {
                    handleChangeRegion(value.region_id);
                  }}
                />
              )}
            </div>
          </div>
          {regionRequire && (
            <CutomErrorMessage error={"Please Select Region"} />
          )}
        </div>

        <div className="nameBox">
          <div className="name">{t("township")}</div>
          <div className="enterBox">
            <Business id="selectphoto" />
            <div style={{ width: "90%" }}>
              {township && (
                <Select
                  // defaultOptions
                  defaultValue={
                    township[
                      township.findIndex(
                        (x) => x.township_id == authUser.user.township_id
                      )
                    ]
                  }
                  options={township}
                  getOptionLabel={(reg) => reg.township_name_en}
                  getOptionValue={(reg) => reg.township_id}
                  onChange={(value) => handleChangeTownship(value.township_id)}
                />
              )}
            </div>
          </div>
          {townshipRequire && (
            <CutomErrorMessage error={"Please Select Township"} />
          )}
        </div>

        <div className="nameBox">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {t("SaveInformation")}
          </Button>
        </div>
      </form>
      <ToastContainer draggable={false} autoClose={3000} />
    </div>
  );
};

export default UserProfile;
