import {
  ArrowCircleLeft,
  ContactSupport,
  QuestionAnswer,
  Send,
} from "@mui/icons-material";
import { Container, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";

import "./ProductQAS.scss";

const ProductQAS = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full h-auto bg-white">
      <div className="w-full h-[100vh] relative !bg-white">
        {/* <div className="w-full h-auto header fixed-top">
        <Container>
          <div className="w-full h-auto relative">
            <div className="w-full text-2xl text-white font-bold text-center py-4">
              Question And Anwer
            </div>
          </div>
        </Container>
      </div> */}
        <Wrapper>
          <div className="w-full h-auto bg-white">
            <Container>
              <div className="w-[60%] h-auto mx-auto pt-[40px] overflow-scroll relative bg-white">
                {showAns.map((all, index) => (
                  <>
                    <Grid container spacing={2} my={1.5}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "10px" }}
                      >
                        <ContactSupport
                          size="small"
                          style={{ color: "#f70d1a" }}
                        />
                        <div style={{ marginLeft: "15px" }}>
                          <div
                            style={{ marginBottom: "5px" }}
                            className="text-[16px]"
                          >
                            {all.customer_name} - {all.customer_id}{" "}
                            {all.question_time} ago
                          </div>
                          <p className="text-[16px]">{all.question}</p>
                        </div>
                      </Grid>
                      <div className="pl-10">
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <QuestionAnswer
                            size="small"
                            style={{ color: "deepskyblue" }}
                          />
                          <div style={{ marginLeft: "15px" }}>
                            <div
                              style={{ marginBottom: "5px" }}
                              className="text-[16px]"
                            >
                              By {all.answer_by} - {all.answer_time} ago
                            </div>
                            <p className="text-[16px]">{all.answer}</p>
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                    <Divider />
                  </>
                ))}
              </div>
            </Container>
          </div>
          <div className=" w-full bg-[rgb(245, 245, 245)]">
            <Container>
              <div className="w-[70%] mx-auto p-3 bg-[rgb(245, 245, 245)]">
                <div className="w-full h-auto flex justify-between">
                  <input
                    type="text"
                    className="w-[90%] py-3 outline-none bg-[rgb(245, 245, 245)] border border-black px-4"
                  />
                  <button className="w-[10%] py-3 flex justify-center items-center bg-secondColor outline-none hover:outline-none focus:outline-none">
                    <Send className="text-white" />
                  </button>
                </div>
              </div>
            </Container>
          </div>
        </Wrapper>
      </div>
      {/* <div className="w-full h-[100vh] mt-[100vh] bg-starColor">something</div> */}
    </div>
  );
};

export default ProductQAS;

const showAns = [
  {
    id: 1,
    customer_name: "Nyan Lin Tun",
    customer_id: 1,
    question_time: 12,
    question: "How can I buy it?",
    answer_by: "Product_owner",
    answer_time: 3,
    answer: "You can purchase it with mobile banking, wavepay or KBZ pay.",
  },
];
