import { Container } from "@mui/material";
import React, { useEffect } from "react";
import OSProductComponent from "../officialStore/OSProductComponent";
import PProductComponent from "./PProductComponent";
import Wrapper from "../../Wrapper";

const PromotionSeeMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        <PProductComponent />
      </Container>
    </Wrapper>
  );
};

export default PromotionSeeMore;
