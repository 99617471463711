import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";

const WishList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getWhishList();
  }, []);

  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [loading, setLoading] = useState(false);

  const [showList, setWhishList] = useState([]);
  const getWhishList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.favouriteList,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setWhishList(response.data);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("MyWishlist")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {showList.length > 0 ? (
                <Box value="1" sx={{ padding: "0 !important" }}>
                  {showList.map((oItem, oIndex) => (
                    <div
                      className="orderProduct"
                      key={oIndex}
                      onClick={() =>
                        gotoproduct(oItem.id, oItem.item_status, oItem.item_id)
                      }
                    >
                      <div className="orderHeader">
                        <div className="number flex justify-between items-center">
                          {oItem.shop_name}
                        </div>
                      </div>
                      <div className="onePcProduct">
                        <div className="bodyProduct">
                          <div className="orderImage">
                            <img src={oItem.product_photo} />
                          </div>
                          <div>
                            <div className="title">{oItem.product_name}</div>
                            <div className="price">{oItem.product_price}Ks</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("noWishlist")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={nodata}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default WishList;
