import React, { useState } from "react";
import { Grid } from "@mui/material";
import { StarOutline, StarRate, StarHalf } from "@mui/icons-material";
import "./popular.scss";
import ProductComponent from "./ProductComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const NewArrival = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = [1, 2, 3, 4, 5];

  const viewMore = () => {
    navigate("/NewArivial");
  };

  return (
    <div className="popular-wrapper">
      <div className="title-div">
        <div className="title">{t("arrivals_title")}</div>
        <div className="view-more" onClick={() => viewMore()}>
          {t("seeMore")}
        </div>
      </div>

      <ProductComponent />
    </div>
  );
};

export default NewArrival;
