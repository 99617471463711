import React, { useState, useEffect } from "react";
import "../../mobile/accountSetting/SignupCss.scss";
import { Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { requestResetPassword } from "../../reducers/userActions";
import { setAuthUser } from "../../reducers/user";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
});
const RequestPasswordReset = () => {
  const { token } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [navigate, token]);

  const handleRequestResetPassword = async ({ phone }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.requestPasswordReset,
      params: {
        phone,
      },
    };

    try {
      setErrors(null);
      const response = await dispatch(
        requestResetPassword(requestObj)
      ).unwrap();
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        toast.success(response.data.message);
        dispatch(setAuthUser(response.data.data));
        navigate("/sms-reset-password"); // step two for login (that must enter sms code)
      } else {
        toast.error("Something Wrong on Request Password Reset!");
      }
    } catch (err) {
      toast.error(`Request failed: ${err.message}`);
    }
  };
  const gotosignin = () => {
    navigate("/login-with-phone");
  };
  return (
    <div className="signup">
      <ToastContainer autoClose={3000} />
      {error && toast.error(error)}
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      <div className="loginTitle">{t("RequestPasswordReset")}</div>
      <Formik
        initialValues={{ phone: "", password: "", confirm_password: "" }}
        validationSchema={userInfoSchema}
        onSubmit={(values) => {
          handleRequestResetPassword(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="userBox">
              <div className="name">{t("phoneNumber")}</div>
              <div className="enterBox">
                <Phone id="photo" />
                <input
                  name="phone"
                  type="text"
                  placeholder={t("pleasePhone")}
                  className="inputEnter"
                  onChange={handleChange("phone")}
                />
              </div>
              {errors.phone && <CutomErrorMessage error={errors.phone} />}
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("RequestPasswordReset")}
            </div>
          </Form>
        )}
      </Formik>
      <div className="phoneDiv" onClick={gotosignin}>
        {t("ALREADY_HAVE_ACCOUNT")}
        <span>&nbsp;{t("LOGIN")}</span>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
