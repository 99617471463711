import React, { useEffect, useState } from "react";
import "../../mobile/account/PersonalCenterCss.scss";
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import lotorder from "../../assets/lotorder.json";
import lotcan from "../../assets/lotcan.json";
import readyship from "../../assets/readyship.json";
import drive from "../../assets/drive.json";
import lotRec from "../../assets/lotRec.json";
import lotreturn from "../../assets/lotreturn.json";
import lotreview from "../../assets/lotreview.json";
import lotwishlist from "../../assets/lotwishlist.json";
import lotfollow from "../../assets/lotfollow.json";
import lotcoupon from "../../assets/coupon.json";
import lotqna from "../../assets/qna.json";
import lotmess from "../../assets/lotmess.json";
import phonecall from "../../assets/phonecall.json";
import { ArrowCircleLeft, Settings } from "@mui/icons-material";
import { Button, Menu, MenuItem, Badge } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/user";
import { persistor } from "../../store";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { addRTS, addS, addCQA } from "../../reducers/countRTSSSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LanguageMenu from "../home/LanguageMenu";

const PersonalCenter = () => {
  const location = useLocation();
  const { authUser, token } = useSelector((state) => state.user);
  const state = useSelector((state) => state.RTSS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getUserInfo();
    getReadyShip();
    getShipping();
    getShowCommentProduct();
    window.scrollTo(0, 0);
  }, []);

  const gotoLogin = () => {
    navigate("/login-with-phone");
  };
  const getUserNameStatus = () => {
    let name = "";
    let customer = authUser ? authUser.user : null;
    let status = localStorage.getItem("userLoginStatus");
    if (status == "phone" && customer) {
      name = authUser.user.phone_number;
    }
    if (status == "google" && customer) {
      name = authUser.user.email;
    }
    if (status == "facebook") {
      let customerFacebook = authUser
        ? authUser.facebook_user
          ? authUser.facebook_user
          : null
        : null;
      if (customerFacebook && customerFacebook.name) {
        name = customerFacebook.name;
      }
    }
    if (customer && authUser.user.region_id && authUser.user.township_id) {
      name = authUser.user.name;
    }
    return name;
  };
  let getCustomerImgUrl = () => {
    let url = null;
    let customer = authUser ? authUser.user : null;
    if (customer && authUser.user) {
      url = authUser.user.image_url;
    }
    return url;
  };
  const goToProfile = () => {
    let customerImg = authUser
      ? authUser.user
        ? authUser.user.image_url
        : null
      : null;
    if (customerImg) {
      navigate("/UserInformation");
    } else if (localStorage.getItem("userTokenID") == null) {
      if (location && location.pathname) {
        sessionStorage.setItem("redirectUrl", location.pathname);
        navigate("/login-with-phone");
      }
    } else {
      navigate("/UserProfile");
    }
  };

  const [countReadyShip, setReadyShipCount] = useState([]);
  const getReadyShip = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.toShipListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setReadyShipCount(response.count);
      dispatch(addRTS(response.count));
    }
  };

  const getShowCommentProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.showCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      dispatch(addCQA(response.data.unseen_question_count));
    }
  };

  const [countShipping, setShipping] = useState([]);
  const getShipping = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.shipedListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setShipping(response.count);
      dispatch(addS(response.count));
    }
  };

  const gotoSignUp = () => {
    navigate("/Signup");
  };
  const goMgdAbout = () => {
    navigate("/MgdAbout");
  };
  const goTermPolicy = () => {
    navigate("/TermPolicy");
  };
  const goSell = () => {
    navigate("/howToSell");
  };
  const goOrderList = () => {
    navigate("/OrderList");
  };
  const goOrdercancle = () => {
    navigate("/OrderCancelList");
  };
  const goreadyship = () => {
    navigate("/ReadyToShip");
  };
  const gtoship = () => {
    navigate("/ToShip");
  };
  const goReceive = () => {
    navigate("/Recieve");
  };
  const goReturn = () => {
    navigate("/Return");
  };
  const goWishlist = () => {
    navigate("/Wishlist");
  };
  const goFllowStore = () => {
    navigate("/FllowStore");
  };
  const goCoupon = () => {
    navigate("/Coupon");
  };
  const goSeeQA = () => {
    navigate("/SeeQA");
  };
  const goReviewPage = () => {
    navigate("/ReviewHistory");
  };
  const goMessage = () => {
    navigate("/Message");
  };
  const handleLogout = (e) => {
    e.preventDefault();
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    dispatch(logout());
    localStorage.clear();
    persistor.persist();
    window.location.href = "/";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loading, setLoading] = useState(false);

  const getUserInfo = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.userInfo,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      localStorage.setItem("userTokenPhone", response.data.phone_number);
      localStorage.setItem("userTokenEmail", response.data.email);
    }
  };
  return (
    <Wrapper>
      {loading && <Loading open={loading} />}
      <div className="account">
        <div className="header" id="topHeader">
          <div className="title">{t("Account")}</div>
          {/* <Settings id="seetingBtn" onClick = {(e) => handleLogout(e)}/> */}
          <div className="seetingBtn">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Settings id="settingIcon" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "5vw !important",
                }}
                onClick={() => goMgdAbout()}
              >
                {t("mallAbout")}
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "5vw !important",
                }}
                onClick={() => goTermPolicy()}
              >
                {t("tap")}
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "5vw !important",
                }}
                onClick={() => goSell()}
              >
                {t("howSell")}
              </MenuItem>
              {token && (
                <MenuItem
                  sx={{
                    fontSize: "3.6vw !important",
                    lineHeight: "5vw !important",
                  }}
                  onClick={() => goToProfile()}
                >
                  {t("accountSettings")}
                </MenuItem>
              )}
              {token ? (
                <MenuItem
                  sx={{
                    fontSize: "3.6vw !important",
                    lineHeight: "5vw !important",
                  }}
                  onClick={(e) => handleLogout(e)}
                >
                  {t("logout")}
                </MenuItem>
              ) : (
                <MenuItem
                  sx={{
                    fontSize: "3.6vw !important",
                    lineHeight: "5vw !important",
                  }}
                  onClick={(e) => gotoLogin(e)}
                >
                  {t("LOGIN")}
                </MenuItem>
              )}
              <MenuItem
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "5vw !important",
                }}
              >
                {t("LanguageMain")}&nbsp;-&nbsp;
                <LanguageMenu />
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="loginHeader">
          <div className="iconWrap">
            {getCustomerImgUrl() ? (
              <img src={getCustomerImgUrl()} onClick={() => goToProfile()} />
            ) : (
              <img
                src={require("../../assets/profile.png")}
                onClick={() => goToProfile()}
              />
            )}
          </div>
          <div className="iconContent">
            <div className="hello">{t("Greeting")}</div>
            {token ? (
              <div className="approvalDiv">
                <span className="usernamestyle">
                  {getUserNameStatus()}
                  {/* {localStorage.getItem("userTokenPhone") ||
                    localStorage.getItem("userTokenEmail")} */}
                </span>
              </div>
            ) : (
              <div className="sing-btn-container">
                <div className="signbut" onClick={() => gotoLogin()}>
                  {t("LOGIN")}
                </div>
                <div className="signbut" onClick={() => gotoSignUp()}>
                  {t("SIGNUP")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="navserver1">
          <div className="orderTitle">{t("MyOrders")}</div>
          <ul className="navLink">
            <li onClick={goOrderList}>
              <div className="iconWrap">
                <Lottie animationData={lotorder} loop={true} id="lot" />
              </div>
              <p>{t("OrderLists")}</p>
            </li>

            <li onClick={goreadyship}>
              <div className="iconWrap">
                {state.countRTS !== 0 && (
                  <Badge
                    badgeContent={state.countRTS}
                    color="error"
                    sx={{ position: "absolute", marginLeft: "6vw" }}
                  ></Badge>
                )}
                <Lottie animationData={readyship} loop={true} id="lott" />
              </div>
              <p>{t("ReadyToShip")}</p>
            </li>

            <li onClick={gtoship}>
              <div className="iconWrap">
                {state.countS !== 0 && (
                  <Badge
                    badgeContent={state.countS}
                    color="error"
                    sx={{ position: "absolute", marginLeft: "6vw" }}
                  ></Badge>
                )}
                <Lottie animationData={drive} loop={true} id="lot" />
              </div>
              <p>{t("Shipping")}</p>
            </li>
            <li onClick={goReceive}>
              <div className="iconWrap">
                <Lottie animationData={lotRec} loop={true} id="lot" />
              </div>
              <p>{t("Receive")}</p>
            </li>
            <li onClick={goReturn}>
              <div className="iconWrap">
                <Lottie animationData={lotreturn} loop={true} id="lot" />
              </div>
              <p>{t("Return")}</p>
            </li>
            <li onClick={goOrdercancle}>
              <div className="iconWrap">
                <Lottie animationData={lotcan} loop={true} id="lot" />
              </div>
              <p>{t("OrderCancel")}</p>
            </li>
            <li onClick={goReviewPage}>
              <div className="iconWrap">
                <Lottie animationData={lotreview} loop={true} id="lot" />
              </div>
              <p>{t("Review")}</p>
            </li>
          </ul>
        </div>
        <div className="navserver2">
          <div className="orderTitle">{t("Favorite")}</div>
          <ul className="navLink">
            <li onClick={goWishlist}>
              <div className="iconWrap">
                <Lottie animationData={lotwishlist} loop={true} id="lot" />
              </div>
              <p>{t("MyWishlist")}</p>
            </li>
            <li onClick={goFllowStore}>
              <div className="iconWrap">
                <Lottie animationData={lotfollow} loop={true} id="lot" />
              </div>
              <p>{t("FollowedStores")}</p>
            </li>
            <li onClick={goSeeQA}>
              <div className="iconWrap">
                {state.countCQA !== 0 && (
                  <Badge
                    badgeContent={state.countCQA}
                    color="error"
                    sx={{ position: "absolute", marginLeft: "6vw" }}
                  ></Badge>
                )}
                <Lottie animationData={lotqna} loop={true} id="lot" />
              </div>
              <p>{t("qna")}</p>
            </li>
            <li onClick={goCoupon}>
              <div className="iconWrap">
                <Lottie animationData={lotcoupon} loop={true} id="lot" />
              </div>
              <p>{t("CouponTitle")}</p>
            </li>
          </ul>
        </div>
        <div className="navserver3">
          <div className="orderTitle">{t("Content")}</div>
          <ul className="navLink">
            <li onClick={goMessage}>
              <div className="iconWrap">
                <Lottie animationData={lotmess} loop={true} id="lot" />
              </div>
              <p>{t("Message")}</p>
            </li>
            <a href="tel:09981600235">
              <li>
                <div className="iconWrap">
                  <Lottie animationData={phonecall} loop={true} id="lot" />
                </div>
                <p>{t("Phone")}</p>
              </li>
            </a>
          </ul>
        </div>
        <div style={{ height: "13vw" }}></div>
      </div>
    </Wrapper>
  );
};

export default PersonalCenter;
