import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useLastLocation } from "react-router-dom-last-location";

const OrderCancleMiddleware = () => {
  const navigate = useNavigate();
  const { lastLocation } = useLastLocation();

  if (
    lastLocation &&
    lastLocation.pathname ==
      `/OrderDetail/${sessionStorage.getItem("lastDetailRoute")}`
  ) {
    return <Outlet />;
  } else {
    navigate("/PersonalCenter");
  }
};

export default OrderCancleMiddleware;
