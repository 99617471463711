import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useLastLocation } from "react-router-dom-last-location";

const PaymentMiddleware = () => {
  const navigate = useNavigate();
  const { lastLocation } = useLastLocation();

  if (lastLocation && lastLocation.pathname == "/CheckOut") {
    return <Outlet />;
  } else if (
    lastLocation &&
    lastLocation.pathname == "/multiple-product-checkout"
  ) {
    return <Outlet />;
  } else {
    navigate("/");
  }

  //   useEffect(() => {
  //     if (lastLocation && lastLocation.pathname == "/CheckOut") {
  //       return <Outlet />;
  //     } else {
  //       navigate("/");
  //     }
  //   }, [lastLocation.pathname == "/CheckOut"]);
};

export default PaymentMiddleware;
