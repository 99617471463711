import React, { useState, useEffect } from "react";
import "../../mobile/category/GrandAllProductCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft, Star } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";

const GrandAllProduct = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData();
  }, []);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  let { grandId } = useParams();
  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const getMore = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.grandProducts,
      params: {
        grand_id: grandId,
        page: page,
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setPageCount(response.data.last_page);
      setProduct([...product, ...response.data.data]);
    }
  };

  const fetchData = () => {
    setPage(page + 1);
    getMore();
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="GrandAllProductDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("categoryAll")}</div>
        </div>
        {product.length > 0 ? (
          <div>
            <ul className="morefloor">
              <li className="moreFloorItem">
                <div className="goodsBox">
                  {product.length > 0 &&
                    product.map((proitem, proindex) => (
                      <div
                        className="floorGoods"
                        onClick={() =>
                          gotoproduct(
                            proitem.id,
                            proitem.item_status,
                            proitem.item_id
                          )
                        }
                        key={proindex}
                      >
                        <img src={proitem.thumbnail_photo} />
                        {proitem.product_promotion_percentage !== null && (
                          <div className="promotionDiscountt">
                            <div className="percent">
                              -
                              {Number.parseFloat(
                                proitem.product_promotion_percentage
                              ).toFixed(0)}
                              %{/* {proitem.product_promotion_percentage}% */}
                            </div>
                          </div>
                        )}
                        <p className="title">{proitem.product_name}</p>
                        {proitem.product_promotion_price !== 0 ? (
                          <p className="price">
                            {proitem.product_promotion_price}
                            ks
                            <span className="disPrice">
                              {proitem.simple_price}ks
                            </span>
                          </p>
                        ) : (
                          <p className="price">{proitem.simple_price}ks</p>
                        )}

                        {proitem.star_count != null && (
                          <div className="starsdiv">
                            {[...Array(proitem.star_count)].map((i) => (
                              <span key={i}>
                                <Star fontSize="small" id="onestar" />
                              </span>
                            ))}
                            {[...Array(5 - proitem.star_count)].map((i) => (
                              <span key={i}>
                                <Star fontSize="small" id="notstar" />
                              </span>
                            ))}
                            ({proitem.person_count})
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </li>
            </ul>
            {product.length > 0 && (
              <InfiniteScroll
                dataLength={product.length}
                next={fetchData}
                hasMore={true}
                loader={
                  pageCount >= page ? (
                    <div className="loading">{t("loading")}</div>
                  ) : (
                    <div className="loading">{t("end")}</div>
                  )
                }
              ></InfiniteScroll>
            )}
          </div>
        ) : (
          <div className="noDataform">
            <div className="text">{t("noProduct")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GrandAllProduct;
