import React, { useState, useEffect } from "react";
import "../../mobile/home/SearchProduct.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, Star } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";

const SearchProduct = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getSearchQueryProduct();
  }, []);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    localStorage.removeItem("search-query");
    navigate(-1);
  };
  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };
  let searchQuery = localStorage.getItem("search-query")
    ? localStorage.getItem("search-query")
    : null;
  const [searchProducts, setSearchProducts] = useState([]);
  const getSearchQueryProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.searchProducts,
      params: {
        search: `${searchQuery}`,
      },
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      toast.error(`Product not found for ${searchQuery}`);
    } else if (response.data.code === 200) {
      setSearchProducts(response.data.data);
    } else if (response.data.code === 404) {
      toast.error(response.data.data.message);
    } else {
      toast.error("Something Wrong when try to search products!");
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="searchDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">
            {t("productFor")}
            {searchQuery}
          </div>
        </div>
        {searchProducts.length > 0 ? (
          <ul className="morefloor">
            <li className="moreFloorItem">
              <div className="goodsBox">
                {searchProducts.length > 0 &&
                  searchProducts.map((proitem, proindex) => (
                    <div
                      className="floorGoods"
                      onClick={() =>
                        gotoproduct(
                          proitem.id,
                          proitem.item_status,
                          proitem.item_id
                        )
                      }
                      key={proindex}
                    >
                      <img src={proitem.photo} />
                      {proitem.product_promotion_percentage !== null && (
                        <div className="promotionDiscountt">
                          <div className="percent">
                            -
                            {Number.parseFloat(
                              proitem.product_promotion_percentage
                            ).toFixed(0)}
                            %{/* {proitem.product_promotion_percentage}% */}
                          </div>
                        </div>
                      )}
                      <p className="title">{proitem.product_name}</p>
                      {proitem.product_promotion_price !== 0 ? (
                        <p className="price">
                          {proitem.product_promotion_price}
                          ks
                          <span className="disPrice">
                            {proitem.simple_price}ks
                          </span>
                        </p>
                      ) : (
                        <p className="price">{proitem.simple_price}ks</p>
                      )}

                      {proitem.star_count != null && (
                        <div className="starsdiv">
                          {[...Array(proitem.star_count)].map((i) => (
                            <span key={i}>
                              <Star fontSize="small" id="onestar" />
                            </span>
                          ))}
                          {[...Array(5 - proitem.star_count)].map((i) => (
                            <span key={i}>
                              <Star fontSize="small" id="notstar" />
                            </span>
                          ))}
                          ({proitem.person_count})
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </li>
          </ul>
        ) : (
          <div className="noDataform">
            <div className="text">{t("noSearch")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchProduct;
