import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product_id: null,
  item_id: null,
  qty: null,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    buy: (state, action) => {
      state.product_id = action.payload.product_id;
      state.item_id = action.payload.item_id;
      state.qty = action.payload.qty;
    },
  },
});
export const { buy } = checkoutSlice.actions;

export default checkoutSlice.reducer;
