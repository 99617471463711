import React, { useEffect, useState } from "react";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { Box, Container, Stack, Typography } from "@mui/material";
import Wrapper from "../Wrapper";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const HowToSell = () => {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.howSellApi,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    if (response.status === 200) {
      setImages(response.data);
    }
  };
  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Stack>
        <Box sx={{ width: "100%", height: "500px" }}>
          <img src={images.photoOne} className="w-full h-full" />
        </Box>
        <Box sx={{ backgroundColor: "white", paddingBottom: "40px" }}>
          <Container>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#120879",
                  fontSize: "23px",
                  paddingBottom: "15px",
                }}
              >
                {t("howSellTitle")}
              </Typography>
              <Typography sx={{ fontSize: "20px", lineHeight: 1.5 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {t("sellOne")}
              </Typography>
            </Stack>
          </Container>
        </Box>
        <Container>
          <Stack
            flexDirection="row"
            gap={4}
            paddingTop="10px"
            paddingBottom="30px"
          >
            <Stack sx={{ width: "50%" }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "338px", height: "250px" }}>
                  <img src={images.photoTwo} className="w-full h-full" />
                </Box>
              </Box>
              <Typography
                sx={{ fontSize: "16.5px", fontWeight: 400, lineHeight: 1.7 }}
              >
                {t("sellTwo")}
              </Typography>
            </Stack>
            <Stack sx={{ width: "50%" }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "210px", height: "210px" }}>
                  <img src={images.photoThree} className="w-full h-full" />
                </Box>
              </Box>
              <Typography
                sx={{ fontSize: "16.5px", fontWeight: 400, lineHeight: 1.7 }}
              >
                {t("sellThree")}
              </Typography>
            </Stack>
          </Stack>
        </Container>
        <Box sx={{ backgroundColor: "white" }}>
          <Container>
            <Stack
              flexDirection="row"
              gap={4}
              alignItems="center"
              paddingY="20px"
            >
              <Box sx={{ width: "280px", height: "200px" }}>
                <img src={images.photoFour} className="w-full h-full" />
              </Box>
              <Typography
                sx={{
                  fontSize: "16.5px",
                  fontWeight: 400,
                  display: "flex",
                  flex: 1,
                  lineHeight: 1.7,
                }}
              >
                {t("sellFour")}
              </Typography>
            </Stack>
            <Box
              sx={{
                padding: "30px",
                borderRadius: 5,
                backgroundColor: "#F7F7F7",
              }}
            >
              <Typography sx={{ lineHeight: 1.7 }}>{t("sellFive")}</Typography>
              <Typography sx={{ lineHeight: 1.7 }}>{t("sellSix")}</Typography>
            </Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography sx={{ lineHeight: 1.7, paddingY: "20px" }}>
                {t("sellSev")}
              </Typography>
            </Box>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "end",
                paddingY: "10px",
              }}
            >
              <Stack alignItems="center" gap={1}>
                <Typography>{t("since")}</Typography>
                <Typography>{t("company")}</Typography>
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              gap={4}
              paddingY="20px"
            >
              <a
                href="https://mail.google.com/mail/u/0/#inbox"
                className="w-[50px] h-[50px]"
              >
                <img src={images.photoFive} className="w-full h-full" />
              </a>
              <a
                href="https://www.facebook.com/mgdmall"
                className="w-[50px] h-[50px]"
              >
                <img src={images.photoSix} className="w-full h-full" />
              </a>
              <a href="https://m.me/mgdmall" className="w-[50px] h-[50px]">
                <img src={images.photoSeven} className="w-full h-full" />
              </a>
              <a
                href="https://www.youtube.com/@mgdmall155"
                className="w-[50px] h-[50px]"
              >
                <img src={images.photoEight} className="w-full h-full" />
              </a>
            </Stack>
          </Container>
        </Box>
      </Stack>
    </Wrapper>
  );
};

export default HowToSell;
