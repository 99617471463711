import React, { useState, useEffect } from "react";
import "../../mobile/storePage/StoreShopCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import { Input, Space } from "antd";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/commons/Loading";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StoreShop = () => {
  const { t } = useTranslation();

  let { sellerId } = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreShop();
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  const [followStore, setfollowStore] = useState("0");
  const gofollow = async (index) => {
    if (localStorage.getItem("userTokenID") != null) {
      setfollowStore(index);
      let requestObj = {
        method: "post",
        url: ApiCollections.followStatus,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          seller_id: sellerId,
          follow_status: index,
        },
      };
      let response = await ApiRequest(requestObj);

      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Something Wrong when try to follow!");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [product, setAllproduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const getAllproduct = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.storeAllProducts,
      params: {
        seller_id: sellerId,
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setAllproduct([...product, ...response.data]);
    }
  };

  const fetchData = () => {
    setPage(page + 1);
    getAllproduct();
  };

  const { Search } = Input;
  const onSearch = (value) => {
    localStorage.setItem("search-query", value);
    navigate("/search-product");
  };
  // const shopnewarrival = [1, 2, 3, 4, 5, 6, 7];
  const [storeAll, setStoreShop] = useState("");
  const [bestProduct, setBestProduct] = useState("");
  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.storeShop,
      params: {
        seller_id: sellerId,
        customer_id: localStorage.getItem("userTokenID"),
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setStoreShop(response.data.data.Header);
      setfollowStore(response.data.data.Header.follow_status);
      setBestProduct(response.data.data.best_seller);
    }
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="storeShopDiv">
        <div
          className="header"
          id="topHeader"
          style={{
            backgroundImage: `url(${storeAll.background_photo})`,
          }}
        >
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="inputDiv">
            <Space direction="vertical">
              <Search
                placeholder={t("searchStore")}
                onSearch={onSearch}
                enterButton
                id="searchDiv"
              />
            </Space>
          </div>
        </div>
        {storeAll && (
          <div className="storeHeader">
            <div className="logo">
              <img src={storeAll.logo} className="image" />
            </div>
            <div className="name">{storeAll.shop_name}</div>
            {followStore == 1 ? (
              <div className="follow" onClick={() => gofollow(0)}>
                {t("followed")}
              </div>
            ) : (
              <div className="follow" onClick={() => gofollow(1)}>
                {t("follow")}
              </div>
            )}
          </div>
        )}

        {/* <div className="shopgoodsNew">
        <div className="title">
          <div className="name">Best Seller</div>
        </div>
        <div className="cards">
          {shopnewarrival.map(() => (
            <div className="card">
              <div className="cardImage">
                <img src={require("../../assets/images/c1.png")} />
              </div>
              <div className="title">
                K-Lite Rechargeable 5W Led Torch Light Kl-7131
              </div>
              <div className="priceBox">
                <div className="price">10000 ks</div>
              </div>
            </div>
          ))}
          <div className="card">
            <div className="seeMore">See More</div>
          </div>
        </div>
      </div> */}
        <div className="storeblank">
          <div className="title">{t("bestSeller")}</div>
        </div>
        <div className="imageDiv">
          {/* {bestProduct[0] && (
            <div
              className="firstLogo"
              onClick={() => gotoproduct(bestProduct[0].id)}
            >
              <img src={bestProduct[0].photo} className="image" />
            </div>
          )} */}

          <div className="secondDiv">
            {bestProduct.length > 0 &&
              bestProduct.slice(0, 4).map((bestItem, bestIndex) => (
                <div
                  className="secondLogo"
                  key={bestIndex}
                  onClick={() =>
                    gotoproduct(
                      bestItem.id,
                      bestItem.item_status,
                      bestItem.item_id
                    )
                  }
                >
                  <img src={bestItem.photo} className="image" />
                </div>
              ))}
          </div>

          {/* <div className="secondDiv">
            {bestProduct[1] && (
              <div
                className="secondLogo"
                onClick={() => gotoproduct(bestProduct[1].id)}
              >
                <img src={bestProduct[1].photo} className="image" />
              </div>
            )}
            {bestProduct[2] && (
              <div
                className="thirdLogo"
                onClick={() => gotoproduct(bestProduct[2].id)}
              >
                <img src={bestProduct[2].photo} className="image" />
              </div>
            )}
          </div> */}
        </div>
        <ul className="storefloor">
          <li className="floorItem">
            <div className="top">
              <div className="title">{t("allProduct")}</div>
            </div>
            <div className="goodsBox">
              {product.length > 0 &&
                product.map((proitem, proindex) => (
                  <div
                    className="floorGoods"
                    onClick={() =>
                      gotoproduct(
                        proitem.id,
                        proitem.item_status,
                        proitem.item_id
                      )
                    }
                    key={proindex}
                  >
                    <img src={proitem.thumbnail_photo} />
                    {proitem.product_promotion_percentage !== null && (
                      <div className="promotionDiscountt">
                        <div className="percent">
                          -
                          {Number.parseFloat(
                            proitem.product_promotion_percentage
                          ).toFixed(0)}
                          %{/* {proitem.product_promotion_percentage}% */}
                        </div>
                      </div>
                    )}
                    <p className="title">{proitem.product_name}</p>
                    {proitem.product_promotion_price !== 0 ? (
                      <p className="price">
                        {proitem.product_promotion_price}
                        ks
                        <span className="disPrice">
                          {proitem.simple_price}ks
                        </span>
                      </p>
                    ) : (
                      <p className="price">{proitem.simple_price}ks</p>
                    )}

                    {proitem.star_count != null && (
                      <div className="starsdiv">
                        {[...Array(proitem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="onestar" />
                          </span>
                        ))}
                        {[...Array(5 - proitem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="notstar" />
                          </span>
                        ))}
                        ({proitem.person_count})
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </li>
        </ul>
        {product.length > 0 && (
          <InfiniteScroll
            dataLength={product.length}
            next={fetchData}
            hasMore={true}
            loader={
              pageCount >= page ? (
                <div className="loading">{t("loading")}</div>
              ) : (
                <div className="loading">{t("end")}</div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default StoreShop;
