import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./categories.scss";
import { motion } from "framer-motion";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useTranslation } from "react-i18next";

const CarouselSlideRect = ({ newData }) => {
  const navigate = useNavigate();
  const goAllProduct = (id) => {
    navigate(`/CategoryAllProduct/${id}`);
  };
  return (
    <div className="categories-div-group">
      {newData.map((category, index) => (
        // <Grid item xs={2}>
        <motion.div
          key={`categories_${index}_${category.id}`}
          className="categories-div"
          onClick={() => goAllProduct(category.id)}
          initial={{ opacity: 0, y: 60 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, margin: "-40px" }}
        >
          <div className="photo">
            <img src={category.image_url} />
          </div>

          <div className="categoryName">{category.category_name}</div>
        </motion.div>
        // </Grid>
      ))}
    </div>
  );
};

const Categories = () => {
  const { t } = useTranslation();
  useEffect(() => {
    getCategoriesData();
  }, []);

  const [category, setCategories] = useState([]);
  const getCategoriesData = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.categories,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setCategories(response.data);
    }
  };

  const classes = useStyles();

  let newArray = [];
  let newData = [...category];
  while (newData.length > 0) newArray.push(newData.splice(0, 8));

  return (
    <div className="categories-wrapper">
      <div className="title-div">
        <div className="title">{t("Category")}</div>
      </div>
      <Carousel
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        indicators={false}
        cycleNavigation={false}
        NextIcon={<ChevronRight />}
        fullHeightHover={false}
        navButtonsWrapperProps={{
          className: classes.navButtonWrapper,
        }}
        NavButton={({ onClick, className, style, next, prev }) => {
          return (
            <Button
              onClick={onClick}
              className={classes.navButton}
              style={style}
            >
              {next && <ChevronRight />}
              {prev && <ChevronLeft />}
            </Button>
          );
        }}
      >
        {newArray.map((each, i) => (
          <CarouselSlideRect newData={each} key={i} />
        ))}
      </Carousel>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  carouselDiv: {
    width: "100%",
  },
  navButtonWrapper: {
    display: "flex",
    alignItems: "center",
  },
  navButton: {
    backgroundColor: "transparent",
    color: "black",
  },
}));

export default Categories;
