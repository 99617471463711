import React, { useState, useEffect } from "react";
import "../../mobile/account/ReviewCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ArrowCircleLeft } from "@mui/icons-material";
import { Rating, Box, TextareaAutosize } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Loading from "../../components/commons/Loading";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";

const labels = {
  1: "Useless",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};
function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const ReviewPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const goback = () => {
    navigate(-1);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getreviewProduct();
  }, []);

  let { reviewOrderId, reviewProductId } = useParams();

  const [reviewProduct, setreviewProduct] = useState([]);
  const getreviewProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.reviewShowProduct,
      params: {
        order_id: reviewOrderId,
        product_id: reviewProductId,
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
    }
  };

  const goHistory = async () => {
    if (comment === null) {
      toast.error("Please fill the comment", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      let data = {
        customer_id: localStorage.getItem("userTokenID"),
        order_id: reviewOrderId,
        product_id: reviewProductId,
        seller_id: reviewProduct.seller_id,
        star_count: value,
        comment: comment,
      };
      const formData = new FormData();

      for (const property in data) {
        formData.append(property, data[property]);
      }
      for (let i = 0; i < image.length; i++) {
        formData.append("photo_array[]", image[i]);
      }

      let requestObj = {
        method: "post",
        url: ApiCollections.giveReview,
        params: formData,
        // params: {
        //   customer_id: localStorage.getItem("userTokenID"),
        //   order_id: reviewOrderId,
        //   product_id: reviewProductId,
        //   seller_id: reviewProduct.seller_id,
        //   star_count: value,
        //   comment: comment,
        //   photo_array: image,
        // },
      };

      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        navigate("/ReviewHistory");
      }
    }
  };

  const [value, setValue] = useState(4);
  const [hover, setHover] = useState(-1);

  const [image, setImage] = useState([]);
  const [comment, setComment] = useState(null);

  const imageSelect = (e) => {
    e.preventDefault();
    if (e.target.files.length > 3) {
      setImage([]);
      toast.error(t("REVIEW_FILE_LIMIT"));
      return false;
    } else {
      let validFile = true;
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size / 1024 / 1024 > 10) {
          // check file size grater than 10MB
          toast.error(t("REVIEW_FILE_SIZE"));
          setImage([]);
          validFile = false;
          break;
        }
      }
      if (validFile) {
        setImage(e.target.files);
      } else {
        setImage([]);
        return false;
      }
    }
  };
  const selectStar = (event, newValue) => {
    event.preventDefault();
    setValue((prevValue) => {
      if (newValue == null && value == prevValue) {
        return value;
      } else {
        return newValue;
      }
    });
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="reviewDiv">
        <ToastContainer draggable={false} autoClose={3000} />
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("Review")}</div>
        </div>
        {reviewProduct && (
          <div className="buyProduct">
            <div className="top">
              <div className="storeName">{reviewProduct.seller_name}</div>
            </div>

            <div className="orderlistDiv">
              <div className="cardImage">
                <img src={reviewProduct.photo} />
              </div>
              <div>
                <div className="title">{reviewProduct.product_name}</div>
                <div className="price">{reviewProduct.simple_price}</div>
                <div className="qty">
                  {t("Quantity")}:
                  <span className="count">{reviewProduct.quantity}</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <Box
          sx={{
            margin: "3vw 12vw",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Rating
            name="hover-feedback"
            value={value}
            precision={1}
            getLabelText={getLabelText}
            onChange={selectStar}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, fontSize: "9vw", marginRight: "2vw" }}
                fontSize="inherit"
              />
            }
          />
          {value !== null && (
            <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
          )}
        </Box>
        <div className="uploadPhoto">
          <label for="chosePhoto" className="textPhoto">
            {t("SelectImages")}
          </label>
          <input
            onChange={imageSelect}
            multiple
            type="file"
            id="chosePhoto"
            style={{ display: "none" }}
          />
          <div className="showPhoto">
            {Array.from(image).map((item) => {
              return (
                <span key={item}>
                  <img
                    style={{ padding: "5vw", width: "30vw", height: "30vw" }}
                    src={item ? URL.createObjectURL(item) : null}
                  />
                </span>
              );
            })}
          </div>
          <TextareaAutosize
            maxRows={4}
            aria-label="maximum height"
            placeholder={t("EnterComment")}
            style={{
              width: "100%",
              height: "50vw",
              fontSize: "3.5vw",
              border: ".5vw solid #f1f1f1",
            }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            id="commentText"
          />
          <button
            // onClick={handleSubmit}
            className="btnSubmit"
            onClick={() => goHistory()}
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ReviewPage;
