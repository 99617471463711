import React, { useEffect, useState } from "react";
import "../../mobile/account/HowToSellCss.scss";
import { ArrowCircleLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";

const HowToSell = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSell();
  }, []);

  const goback = () => {
    navigate(-1);
  };

  const [showSell, setSell] = useState([]);
  const getSell = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.howSellApi,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setSell(response);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="sellDiv">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("howTitle")}</div>
        </div>
        {showSell && (
          <div className="sellMain">
            <div>
              <img className="image" src={showSell.photoOne} />
            </div>
            <div>
              <div className="toptitle">{t("howSellTitle")}</div>
              <p className="pOne">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("sellOne")}
              </p>
            </div>
            <div className="secondDiv">
              <div>
                <img className="image2" src={showSell.photoTwo} />
              </div>
              <p className="ptwo">{t("sellTwo")}</p>
            </div>
            <div className="thDiv">
              <div>
                <img className="image3" src={showSell.photoThree} />
              </div>
              <p className="ptwo">{t("sellThree")}</p>
            </div>
            <div className="foDiv">
              <div>
                <img className="image4" src={showSell.photoFour} />
              </div>
              <p className="ptwo">{t("sellFour")}</p>
            </div>
            <div className="fiv">
              <div className="fivTop">{t("sellFive")}</div>
              <div className="fivbot">{t("sellSix")}</div>
            </div>
            <div className="thz">{t("sellSev")}</div>
            <div className="finish">
              <div className="since">{t("since")}</div>
              <div className="company">{t("company")}</div>
            </div>
            <div className="footsell">
              <a href="https://mail.google.com/mail/u/0/#inbox">
                <img className="footimag" src={showSell.photoFive} />
              </a>
              <a href="https://www.facebook.com/mgdmall">
                <img className="footimag" src={showSell.photoSix} />
              </a>
              <a href="https://m.me/mgdmall">
                <img className="footimag" src={showSell.photoSeven} />
              </a>
              <a href="https://www.youtube.com/@mgdmall155">
                <img className="footimag" src={showSell.photoEight} />
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HowToSell;
