import { Container } from "@mui/material";
import React, { useEffect } from "react";
import OSProductComponent from "./OSProductComponent";
import Wrapper from "../../Wrapper";

const OfficialStoreSeeMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        <OSProductComponent />
      </Container>
    </Wrapper>
  );
};

export default OfficialStoreSeeMore;
