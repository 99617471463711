import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order_number: null,
};

export const orderNumber = createSlice({
  name: "orderNumber",
  initialState,
  reducers: {
    addOrderNumber: (state, action) => {
      state.order_number = action.payload;
    },
  },
});
export const { addOrderNumber } = orderNumber.actions;

export default orderNumber.reducer;
