import React, { useState, useEffect } from "react";
import "../../mobile/seeMore/StoreAllCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const StoreAll = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData();
  }, []);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const gotostore = (id) => {
    navigate(`/StoreShop/${id}`);
  };

  const [storeShop, setStoreShop] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const getMore = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.officialStores,
      params: {
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setStoreShop([...storeShop, ...response.data]);
    }
  };

  const fetchData = () => {
    setPage(page + 1);
    getMore();
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="storeAllDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("shopAll")}</div>
        </div>
        <ul className="storeFloor">
          <li className="floorItem">
            <div className="goodsBox">
              {storeShop.map((storeItem, storeIndex) => (
                <div
                  className="floorGoods"
                  key={storeIndex}
                  onClick={() => gotostore(storeItem.id)}
                >
                  <img src={storeItem.photo} />
                  <p className="title">{storeItem.shop_name}</p>
                </div>
              ))}
            </div>
          </li>
        </ul>
        {storeShop.length > 0 && (
          <InfiniteScroll
            dataLength={storeShop.length}
            next={fetchData}
            hasMore={true}
            loader={
              pageCount >= page ? (
                <div className="loading">{t("loading")}</div>
              ) : (
                <div className="loading">{t("endStore")}</div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default StoreAll;
