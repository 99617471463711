import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Star } from "@mui/icons-material";
import {
  FavoriteBorder,
  Visibility,
  StarOutline,
  StarRate,
  StarHalf,
} from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "../../home/product.scss";
import ApiCollections from "../../../services/ApiCollections";
import { ApiRequest } from "../../../services/common/ApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/commons/Loading";

const OSProductComponent = ({ seeMore }) => {
  const { t } = useTranslation();
  const [officialStore, setOfficialStore] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // getOfficialStore();
    setLoading(true);
    fetchData();
  }, []);

  const getOfficialStore = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.officialStores,
      params: {
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setOfficialStore([...officialStore, ...response.data]);
    }
  };

  const fetchData = () => {
    setPage(page + 1);
    getOfficialStore();
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="w-full h-auto pt-[20px]">
        <div className="w-full h-auto grid grid-cols-7 gap-x-[20px] gap-y-[20px] my-[30px]">
          {officialStore?.map((osItem, osIndex) => (
            <div
              key={osIndex}
              className="w-full h-[150px] relative group osImgG rounded-md overflow-hidden"
              onClick={() => navigate(`/StoreShop/${osItem.id}`)}
            >
              <div className="w-full h-full">
                <img
                  src={osItem.photo}
                  className="w-full h-full object-cover osImg group-hover:scale-125 duration-300"
                />
              </div>
              <div className="officialName text-white block group-hover:translate-y-0 duration-300 absolute w-full h-auto bottom-0 left-0 bg-[rgba(0,0,0,.5)] py-1 text-center translate-y-[40px]">
                {osItem.shop_name}
              </div>
            </div>
          ))}
        </div>
        {officialStore.length > 0 && (
          <InfiniteScroll
            dataLength={officialStore.length}
            next={fetchData}
            hasMore={true}
            loader={
              pageCount >= page ? (
                <div className="text-xl w-full py-4 text-center">
                  {t("loading")}
                </div>
              ) : (
                <div className="text-xl w-full py-4 text-center">
                  {t("endStore")}
                </div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default OSProductComponent;
