import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import comingsoon from "../../assets/comingsoon.json";
import { useTranslation } from "react-i18next";

const Message = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  return (
    <Wrapper>
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("Message")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}

              <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                <div>{t("soon")}</div>
                <div className="w-[300px] h-[300px]">
                  <Lottie
                    animationData={comingsoon}
                    loop={true}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Message;
