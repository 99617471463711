import * as React from "react";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { Box, Container, Tab } from "@mui/material";
import RatingPage from "./RatingPage";
import QuestionPage from "./QuestionPage";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ProductTabs = ({ productId, sellerId }) => {
  const [value, setValue] = React.useState("1");
  const { t, i18n } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(false);
  const [showAns, setAns] = useState([]);

  useEffect(() => {
    getAnswer();
    getreviewProduct();
  }, []);

  const [reviewProduct, setreviewProduct] = useState([]);
  const getreviewProduct = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.productReviewApi,
      params: {
        product_id: productId,
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
    }
  };

  const getAnswer = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.giveAnswer,
      params: {
        product_id: productId,
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setAns(response.data.data);
    }
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }} mt={5}>
      <TabContext value={value}>
        <Container>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={`${t("CustomerReview")} (${reviewProduct.length})`}
                value="1"
              />
              <Tab label={`${t("qna")} (${showAns.length})`} value="2" />
            </TabList>
          </Box>
        </Container>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <RatingPage reviewProduct={reviewProduct} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <QuestionPage
            productId={productId}
            showAns={showAns}
            getAnswer={getAnswer}
            sellerId={sellerId}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ProductTabs;
