import React, { useEffect, useState } from "react";
import { Box, Container, Modal } from "@mui/material";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./header.scss";
import Lottie from "lottie-react";
import phonecall from "../../assets/phonecall.json";
import { logout } from "../../reducers/user";
import { persistor } from "../../store";
import { useTranslation } from "react-i18next";

const TopHeader = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const style = {
    position: "absolute",
    borderRadius: "10px",
    top: "53%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    margin: "auto",
  };

  const { authUser, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // window.onscroll = function () {
  //   scrollFunction();
  // };
  const handleLogout = (e) => {
    e.preventDefault();
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    dispatch(logout());
    localStorage.clear();
    persistor.persist();
    window.location.href = "/";
  };

  // const scrollFunction = () => {
  //   const _top = window.scrollY < 200;
  //   if (_top) {
  //     $("#navbar").css({ top: "0" });
  //   } else {
  //     $("#navbar").css({ top: "-50px" });
  //   }
  // };
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login-with-phone");
  };
  const goToSignup = () => {
    navigate("/Signup");
  };

  const gotoOrderList = () => {
    navigate("/OrderList");
  };

  return (
    <div id="navbar">
      <Container style={{ display: "flex" }}>
        <div className="lang-div"></div>
        <div className="top-menu-bar">
          <div onClick={handleOpen} className="title">
            App Download
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="flxscan">
                <div style={{ marginLeft: "50px" }}>
                  <img
                    src={require("../../assets/images/scanimg.png")}
                    style={{ width: "100%", height: "100%", margin: "auto" }}
                  />
                  <div
                    style={{
                      fontSize: "15px",
                      color: "#1aa8b7",
                      textAlign: "center",
                    }}
                  >
                    Play Store Download
                  </div>
                </div>
                <div>
                  <img
                    src={require("../../assets/images/iosscan.png")}
                    style={{ width: "47%", height: "47%", margin: "auto" }}
                  />
                  <div
                    style={{
                      fontSize: "15px",
                      color: "#1aa8b7",
                      textAlign: "center",
                    }}
                  >
                    IOS Download
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {token ? (
            <>
              <div onClick={(e) => handleLogout(e)} className="title">
                {t("LOGOUT_BUTTON")}
              </div>
              <div onClick={gotoOrderList} className="title">
                {t("SETTING_BUTTON")}
              </div>
            </>
          ) : (
            <>
              <div onClick={goToLogin} className="title">
                {t("LOGIN_BUTTON")}
              </div>
              <div onClick={goToSignup} className="title">
                {t("REGISTER_BUTTON")}
              </div>
            </>
          )}
          <div className="phonetop">
            <Lottie animationData={phonecall} loop={true} id="lot" />
            <span className="text">09981600235</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
