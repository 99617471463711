import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useNavigate } from "react-router-dom";
import { FavoriteBorder, Star } from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import "./product.scss";

const JFYComponent = () => {
  const { t } = useTranslation();
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const navigate = useNavigate();

  const getProduct = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.justForYouApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
        page: page,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setPageCount(response.last_page);
      setProduct([...product, ...response.data]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setPage(page + 1);
    getProduct();
  };

  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  return (
    <>
      <div className="product-wrapper grid grid-cols-5 gap-x-6">
        {product?.map((pItem, pIndex) => (
          <motion.div
            className="single-product"
            key={pIndex}
            onClick={() =>
              linkProductPage(pItem.id, pItem.item_status, pItem.item_id)
            }
            initial={{ opacity: 0, y: 60 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, margin: "-40px" }}
          >
            <div className="single-product-img">
              {/* h-[195px] */}
              <div className="w-full h-[211px]">
                <img
                  src={pItem.thumbnail_photo}
                  className="w-full !h-full !object-fill image"
                />
              </div>

              {/* overlay while hovering image */}
              {/* <div className="overlay"> */}
              {/* </div> */}

              {/* left side */}
              {pItem.product_promotion_percentage !== null && (
                <div className="sg-floating-badges">
                  <span className="onsale percent-count">
                    -
                    {Number.parseFloat(
                      pItem.product_promotion_percentage
                    ).toFixed(0)}
                    %
                  </span>
                </div>
              )}

              {/* right side */}
              {/* <div className="sg-floating-icons">
                <span className="!flex justify-center items-center">
                  <a
                    href=""
                    button-wishlist=""
                    data-product-handle="3-variable-product"
                  >
                    <Tooltip
                      title="Add to Wishlist"
                      arrow
                      // TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      placement="left"
                    >
                      <FavoriteBorder fontSize="small" />
                    </Tooltip>
                  </a>
                </span>
              </div> */}

              <div className="star-icons">
                <span>
                  <Tooltip
                    title="Add to Wishlist"
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    placement="left"
                  >
                    <FavoriteBorder fontSize="small" />
                  </Tooltip>
                </span>
              </div>

              {/* bottom side */}
              {pItem.star_count != null && (
                <div className="sg-floating-stars !flex justify-center items-center">
                  <div className="starsdiv">
                    {[...Array(pItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star
                          fontSize="small"
                          id="onestar"
                          className="text-starColor"
                        />
                      </span>
                    ))}
                    {[...Array(5 - pItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star
                          fontSize="small"
                          id="notstar"
                          className="text-notstarColor"
                        />
                      </span>
                    ))}
                    ({pItem.person_count})
                  </div>
                </div>
              )}
            </div>

            {/* product content */}
            <div className="product-content ">
              <div className="title">
                <h3 className="popup_cart_title">
                  <div>{pItem.product_name}</div>
                </h3>
              </div>

              <div className="price">
                {pItem.product_promotion_price !== 0 ? (
                  <>
                    <span className="current-price">
                      <span className="money">
                        {pItem.product_promotion_price}ks
                      </span>
                    </span>
                    <span className="discounted-price ">
                      <s className="money">{pItem.simple_price}ks</s>
                    </span>
                  </>
                ) : (
                  <span className="current-price">
                    <span className="money">{pItem.simple_price}ks</span>
                  </span>
                )}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      {product.length > 0 && (
        <InfiniteScroll
          dataLength={product.length}
          next={fetchData}
          hasMore={true}
          loader={
            pageCount >= page ? (
              <div className="text-xl w-full py-4 text-center">
                {t("loading")}
              </div>
            ) : (
              <div className="text-xl w-full py-4 text-center">{t("end")}</div>
            )
          }
        ></InfiniteScroll>
      )}
    </>
  );
};

export default JFYComponent;
