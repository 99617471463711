import * as React from "react";
import { ThemeProvider, useTheme, createTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

import Router from "./mobile/router";

import PCRouter from "./pc/router";

function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}
function useIsWidthDown(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}

function MyComponent() {
  const isMdUp = useIsWidthUp("md");
  const isMdDown = useIsWidthDown("md");
  // const isMdUp = window.innerWidth > 900;

  if (isMdUp) {
    return <PCRouter />;
  } else {
    return (
      <Box>
        <Router />
      </Box>
    );
  }
}

const theme = createTheme();

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <MyComponent />{" "}
    </ThemeProvider>
  );
}
