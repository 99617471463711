import React, { useState, useEffect } from "react";
import "./SignUpCss.scss";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import signupCover from "../../assets/signupCover.json";
import { Phone } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { requestResetPassword } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
});

const RequestPasswordReset = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();

  const gotoLogin = () => {
    navigate("/login-with-phone");
  };
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleRequestResetPassword = async ({ phone }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.requestPasswordReset,
      params: {
        phone,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(
        requestResetPassword(requestObj)
      ).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        toast.success(response.data.message);
        dispatch(setAuthUser(response.data.data));
        navigate("/sms-reset-password"); // step two for login (that must enter sms code)
      } else {
        toast.error("Something Wrong on Request Password Reset!");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Request failed: ${err.message}`);
    }
  };

  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <div className="SignUpDiv">
          <ToastContainer autoClose={3000} />
          {error && toast.error(error)}
          {errors &&
            errors.map((error, index) => {
              toast.error(error);
            })}
          <div className="leftBox">
            <div className="loginTitle">{t("FORGET_YOUR_PASSWORD")}</div>

            <Formik
              initialValues={{ phone: "" }}
              validationSchema={userInfoSchema}
              onSubmit={(values) => {
                handleRequestResetPassword(values);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form>
                  <div className="userBox">
                    <div className="name">{t("phoneNumber")}</div>
                    <div className="enterBox">
                      <Phone id="photo" />
                      <input
                        type="text"
                        placeholder={t("pleasePhone")}
                        className="inputEnter"
                        name="phone"
                        onChange={handleChange("phone")}
                      />
                    </div>
                    {errors.phone && <CutomErrorMessage error={errors.phone} />}
                  </div>
                  <div className="btnLog" onClick={handleSubmit}>
                    {t("RequestPasswordReset")}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="rightBox">
            <Lottie animationData={signupCover} loop={true} id="logCov" />
            <div className="phoneDiv" onClick={gotoLogin}>
              {t("ALREADY_HAVE_ACCOUNT")}
              <span>&nbsp;{t("LOGIN")}</span>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default RequestPasswordReset;
