import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { FmdGood, Person, Phone } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const NoCancelDetail = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const { orderID } = useParams();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderDetail();
  }, []);

  const [loading, setLoading] = useState(false);

  const [orderDetail, setOrderDetail] = useState([]);
  const getOrderDetail = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.orderDetailApi,
      params: {
        order_id: orderID,
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setOrderDetail(response.data);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order border-l-4 border-[rgb(245, 245, 245)]">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("OrderDetail")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              <Box value="1" sx={{ padding: "0 !important" }}>
                <div className="orderProduct">
                  <div className="orderHeader">
                    <div className="number flex justify-between items-center">
                      {t("OrderNumber")}&nbsp;:&nbsp;{orderDetail?.order_code}
                    </div>
                    <div className="date !flex !gap-x-2">
                      {t("Date")}: <div>{orderDetail?.date}</div>
                    </div>
                  </div>
                  {orderDetail?.orderLists?.map((oVariant, oIndex) => (
                    <div className="onePcProduct" key={oIndex}>
                      <div className="bodyProduct">
                        <div className="orderImage">
                          <img src={oVariant.photo} />
                        </div>
                        <div className="flex flex-col">
                          <div className="w-full h-[50%] flex justify-between items-start">
                            <div className="title">
                              {oVariant.item_name || oVariant.product_name}
                            </div>
                            <div className="qty !ml-auto">
                              <span className="">{t("Quantity")}: </span>
                              <span className="qtyNumber">
                                {oVariant.quantity}
                              </span>
                            </div>
                          </div>
                          <div className="w-full h-[50%] flex justify-between items-end">
                            <div className="price">
                              {oVariant.promotion_price !== 0 ? (
                                <>
                                  <span className="current-price">
                                    <span className="money">
                                      {oVariant.promotion_price}ks
                                    </span>
                                  </span>
                                  <span className="detailprice ">
                                    <s className="money">{oVariant.price}ks</s>
                                  </span>
                                </>
                              ) : (
                                <span className="current-price">
                                  <span className="money">
                                    {oVariant.price}ks
                                  </span>
                                </span>
                              )}
                            </div>
                            {oVariant.size && oVariant.color && (
                              <div className="flex gap-x-4 ">
                                <div className="flex gap-x-2">
                                  <p>{t("Color")}: </p>
                                  <p className="color">{oVariant.color}</p>
                                </div>
                                <div className="flex gap-x-2">
                                  <p>{t("Size")}: </p>
                                  <p className="size">{oVariant.size}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {oVariant.cancel_status == 1 && (
                        <div className="shopCancel">
                          We're sorry, this product has been cancelled by an
                          unforeseeable circumstances.
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
            <div className="w-full h-auto flex flex-col pt-4 pb-4 border-2 gap-y-6">
              <div className="w-[100%] space-y-6 px-3 order-2 !">
                <p className="text-xl font-bold underline">{t("address")}</p>
                <div className="space-y-3">
                  <div className="flex gap-x-4">
                    <FmdGood className="text-[#0d7199]" />
                    <p className="">
                      {orderDetail?.address}, {orderDetail?.township_name},{" "}
                      {orderDetail?.region_name}
                    </p>
                  </div>
                  <div className="flex gap-x-4">
                    <Person className="text-[#0d7199]" />
                    <p className="">{orderDetail.name}</p>
                  </div>
                  <div className="flex gap-x-4">
                    <Phone className="text-[#0d7199]" />
                    <p className="">{orderDetail.phone_number}</p>
                  </div>
                </div>
              </div>
              <div className="w-[100%] space-y-6 px-3 pb-4 order-1 border-b-[14px] !border-[rgb(245, 245, 245)]">
                <div className="space-y-3 w-full">
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("OrderNumber")}</p>
                    <p className="">{orderDetail?.order_code}</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("Date")}</p>
                    <p className="">{orderDetail.date}</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("Payment")}</p>
                    <p>{orderDetail.payment_method}</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("priceTotal")}</p>
                    <p>{orderDetail.total_product_price}Ks</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("delivery")}</p>
                    <p>{orderDetail.delivery_fee}Ks</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("discount")}</p>
                    <p>{orderDetail.total_discount}Ks</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("CouponTitle")}</p>
                    <p>{orderDetail.coupon_discount}Ks</p>
                  </div>
                  <div className="w-full flex justify-between gap-x-4">
                    <p>{t("total")}</p>
                    <p>{orderDetail.total}Ks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default NoCancelDetail;
