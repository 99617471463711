import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Container } from "@mui/material";
import "../../pc/home/bannerslide.scss";
import Menu from "./Menu";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";

const BannerSlide = (props) => {
  useEffect(() => {
    getBanner();
  }, []);

  const [loading, setLoading] = useState(false);

  const [showBanner, setBanner] = useState([]);
  const getBanner = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.banner,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setBanner(response.data);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="banner-wrapper">
        <Container>
          <Menu />
        </Container>
        <div className="mainBanner">
          <Carousel
            interval={4000}
            animation="slide"
            stopAutoPlayOnHover
            swipe
            next={() => {}}
            prev={() => {}}
          >
            {showBanner.length > 0 &&
              showBanner.map((bannerItem, bannerIndex) => (
                <div
                  className="imgDiv"
                  key={bannerIndex}
                  style={{ backgroundColor: bannerItem.background_color }}
                >
                  <Container>
                    <div className="innerOne">
                      <img src={bannerItem.photo} className="sliderDiv" />
                    </div>
                  </Container>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default BannerSlide;
