import React, { useState, useEffect } from "react";
import "../../mobile/account/UserInformationCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowCircleLeft,
  Phone,
  Person,
  AddLocation,
  Facebook,
  Google,
  Portrait,
} from "@mui/icons-material";
import { MenuItem, Select, Button } from "@mui/material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import Loading from "../../components/commons/Loading";

const UserInformation = () => {
  const { authUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goback = () => {
    navigate(-1);
  };

  useEffect(() => {
    let customerImg = authUser
    ? authUser.user
      ? authUser.user.image_url
      : false
      : false;
    if (!customerImg) {
      navigate('/');
    }
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  const updateProfile = () => {
    navigate('/update-profile');
  }
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="UserInforDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">User Information</div>
        </div>
        <div className="information">
          <div className="detail">
            <Portrait id="img" />
            <div className="des">
              <img
                src={authUser.user.image_url}
                className="image"
              />
            </div>
          </div>
          <div className="detail">
            <Person id="img" />
            <div className="des">{ authUser.user.name }</div>
          </div>
          <div className="detail">
            <Phone id="img" />
            <div className="des">{authUser.user.phone_number}</div>
          </div>
          <div className="detail">
            <Google id="img" />
            <div className="des">{authUser.user.email}</div>
          </div>
          <div className="detail">
            <Facebook id="img" />
            <div className="des">{authUser.user.facebook_name}</div>
          </div>
          <div className="detail">
            <AddLocation id="img" />
            <div className="des">{`${authUser.user.address} , ${authUser.user.township_name_en}, ${authUser.user.region_name_en}`}</div>
          </div>
        </div>
        <div className="editBtn" onClick={updateProfile}>Edit Information</div>
        <ToastContainer draggable={false} autoClose={3000} />
      </div>
    </>
  );
};

export default UserInformation;
