import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid } from "@mui/material";
import { ContactSupport, QuestionAnswer, Send } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { ApiRequest } from "../../services/common/ApiRequest";
import ApiCollections from "../../services/ApiCollections";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import textChat from "../../assets/textChat.json";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const QuestionPage = ({ sellerId, productId, getAnswer, showAns }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [cusQus, setCusQus] = useState("");

  const btnQues = async () => {
    if (localStorage.getItem("userTokenID") != null) {
      let requestObj = {
        method: "post",
        url: ApiCollections.customerQuestion,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          seller_id: sellerId,
          product_id: productId,
          question: cusQus,
        },
      };

      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        getAnswer();
        setCusQus("");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  return (
    <>
      <Container>
        <Box>
          <div className="w-[90%] mx-auto h-[400px] overflow-y-scroll border border-black mt-8 pl-8">
            {showAns.length > 0 ? (
              <div className="w-full h-full">
                {showAns.map((d, i) => (
                  <>
                    <Grid container spacing={2} mt={1.5} mb={3}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <ContactSupport
                          size="small"
                          style={{ color: "#f70d1a" }}
                        />
                        <div style={{ marginLeft: "15px" }}>
                          {d.customer_name != null ? (
                            <div style={{ marginBottom: "5px" }}>
                              {d.customer_name} - {d.question_time}
                            </div>
                          ) : (
                            <div style={{ marginBottom: "5px" }}>
                              User : {d.customer_id} - {d.question_time}
                            </div>
                          )}

                          <p>{d.question}</p>
                        </div>
                      </Grid>
                      {d.answer !== null && (
                        <div className="pl-10">
                          <Grid item xs={12} style={{ display: "flex" }}>
                            <QuestionAnswer
                              size="small"
                              style={{ color: "deepskyblue" }}
                            />
                            <div style={{ marginLeft: "15px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                {d.answer_by} - {d.answer_time}
                              </div>
                              <p>{d.answer}</p>
                            </div>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                    <Divider />
                  </>
                ))}
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center relative">
                <div className="absolute top-[80px]">{t("NoComment")}</div>
                <div className="w-[300px] h-[300px]">
                  <Lottie
                    animationData={textChat}
                    loop={true}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            )}
          </div>
        </Box>
      </Container>
      <div
        className="w-full h-auto border-b-[15px] !border-[rgb(245, 245, 245)]"
        style={{ backgroundColor: "white" }}
      >
        <Container>
          <div
            className="w-[90%] mx-auto pt-3 pb-6  !bg-white]"
            style={{ backgroundColor: "white" }}
          >
            <div className="w-full h-auto flex justify-between">
              <input
                type="text"
                className="w-[90%] py-3 outline-none bg-[rgb(245, 245, 245)] border !border-secondColor px-4"
                placeholder="Please enter the question"
                value={cusQus}
                onChange={(e) => setCusQus(e.target.value)}
              />
              <button
                className="w-[10%] py-3 flex justify-center items-center bg-secondColor outline-none hover:outline-none focus:outline-none"
                onClick={btnQues}
              >
                <Send className="text-white" />
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default QuestionPage;
