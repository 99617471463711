export default {
  banner: "/api/show_banner",
  userInfo: "/api/userInfo",
  categories: "/api/categories",
  categoryProducts: "/api/category_all_products",
  subcategoryProducts: "/api/subcategory_all_products",
  grandProducts: "/api/grand_all_products",
  getAllFlashSales: "/api/flash_sales",
  promotionCampange: "/api/promotion_campange",
  justForYouApi: "/api/just_for_you",
  promotionsApi: "/api/promotion_all_products",
  homeNewArrivalsApi: "/api/home_new_arrivals",
  newArrivalsApi: "/api/new_arrivals",
  mainCategory: "/api/get_category",
  subAndGrandCategory: "/api/get_subAndGrandCategory",
  mobileSubAndGrandCategory: "/api/mobile_all_category",
  productDetailApi: "/api/product_detail",
  sizeApi: "/api/size_api",
  itemDetailApi: "/api/item_detail",
  customerQuestion: "/api/customerQuestion",
  giveAnswer: "/api/toGiveAnswer",
  favStatus: "/api/favourite_status",
  favouriteList: "/api/get_favourite",
  sameStore: "/api/same_store",
  regionsApi: "/api/get_regions",
  citiesApi: "/api/get_cities",
  townshipsApi: "/api/get_townships",
  deliveryFeeApi: "/api/get_delivery_fee",
  addAddressApi: "/api/add_Address",
  editAddressApi: "/api/editAddress",
  addressList: "/api/addressList",
  checkoutApi: "/api/check_out",
  placeOrder: "/api/place_order",
  paymentMethodApi: "/api/paymentMethod",
  confirmPaymenApi: "/api/confirm_payment",
  orderListApi: "/api/orderList",
  orderDetailApi: "/api/orderDetail",
  reasonCancelApi: "/api/reasonCancel",
  orderCancelApi: "/api/orderCancel",
  cancelListApi: "/api/cancelList",
  toShipListApi: "/api/toShipList",
  shipedListApi: "/api/shipedList",
  receiveListApi: "/api/receiveList",
  returnedListApi: "/api/returnedList",
  reviewShowProduct: "/api/review_show_product",
  giveReview: "/api/give_review",
  reviewHistoryApi: "/api/review_history",
  showCommentProductApi: "/api/show_comment_product",
  seenCommentProductApi: "/api/seen_comment_product",
  productReviewApi: "/api/product_review",
  officialStores: "/api/official_stores",
  storeShop: "/api/store_shop",
  followStatus: "/api/follow_status",
  followList: "/api/get_follow",
  storeAllProducts: "/api/store_all_products",
  registerWithPhone: "/api/otp/register-otp",
  loginWithPhone: "/api/otp/login-with-phone",
  loginWithSmsOTP: "/api/otp/login-otp",
  requestSmsCode: "/api/otp/request-otp",
  loginWithFacebook: "api/auth/login/facebook",
  loginWithSocial: "api/auth/login",
  loginCallBackSocial: "api/auth/callback",
  requestPasswordReset: "api/request-password-reset",
  requestPasswordResetSms: "api/request-password-reset-sms",
  resetPassword: "api/reset-password",
  searchProducts: "api/search-products",
  addToCartProductServer: "api/add-customer-cart-data",
  setProductCartQyt: "api/set-product-cart-qty",
  increaseProductCartQyt: "api/increase-product-cart-qty",
  decreaseProductCartQyt: "api/decrease-product-cart-qty",
  removeProductCart: "api/remove-product-cart",
  getCustomerCartData: "api/get-customer-cart",
  updateCustomerProfile: "api/update-profile",
  deleteCartItems: "api/delete-cart-items",
  aboutUsPhotos: "api/about_us_photos",
  howSellApi: "api/how_to_sell",
  couponLists: "api/coupon_lists",
  registerCoupon: "api/register_coupon",
};
