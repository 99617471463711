import React, { useEffect, useState } from "react";
import "./SeeQACss.scss";
import Wrapper from "../Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Divider, Grid } from "@mui/material";
import { ContactSupport, QuestionAnswer } from "@mui/icons-material";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/qna.json";
import { useTranslation } from "react-i18next";

const SeeQA = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSeenCommentProduct();
    getShowCommentProduct();
  }, []);

  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [loading, setLoading] = useState(false);

  const [seenComment, setSeenCommentProduct] = useState([]);
  const getSeenCommentProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "get",
      url: ApiCollections.seenCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setSeenCommentProduct(response.data.data);
    }
  };

  const [showComment, setShowCommentProduct] = useState([]);
  const getShowCommentProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.showCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setShowCommentProduct(response.data.data);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="SeeQADiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("qna")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {showComment.length > 0 ? (
                <Box value="1" sx={{ padding: "0 !important" }}>
                  {showComment.map((oItem, oIndex) => (
                    <div
                      className="orderProduct"
                      key={oIndex}
                      onClick={() =>
                        gotoproduct(oItem.id, oItem.item_status, oItem.item_id)
                      }
                    >
                      <div>
                        <div className="onePcProduct">
                          <div className="bodyProduct">
                            <div className="orderImage">
                              <img src={oItem.photo} />
                            </div>
                            <div className="title !flex flex-col justify-between">
                              {oItem.product_name}
                              <div className="price !ml-0">
                                {oItem.product_promotion_price !== 0 ? (
                                  <>
                                    <span className="current-price">
                                      <span className="money">
                                        {oItem.product_promotion_price}ks
                                      </span>
                                    </span>
                                    <span className="detailprice ">
                                      <s className="money">
                                        {oItem.simple_price}ks
                                      </s>
                                    </span>
                                  </>
                                ) : (
                                  <span className="current-price">
                                    <span className="money">
                                      {oItem.simple_price}ks
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Grid container spacing={2} mb={3}>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", marginBottom: "20px" }}
                        >
                          <ContactSupport
                            size="small"
                            style={{ color: "#f70d1a" }}
                          />
                          <div style={{ marginLeft: "15px" }}>
                            {oItem.customer_name != null ? (
                              <div style={{ marginBottom: "5px" }}>
                                {oItem.customer_name} - {oItem.question_time}
                              </div>
                            ) : (
                              <div style={{ marginBottom: "5px" }}>
                                User : {oItem.customer_id} -{" "}
                                {oItem.question_time}
                              </div>
                            )}

                            <p>{oItem.question}</p>
                          </div>
                        </Grid>
                        {oItem.answer !== null && (
                          <div style={{ paddingLeft: "16px" }}>
                            <Grid item xs={12} style={{ display: "flex" }}>
                              <QuestionAnswer
                                size="small"
                                style={{ color: "deepskyblue" }}
                              />
                              <div style={{ marginLeft: "15px" }}>
                                <div style={{ marginBottom: "5px" }}>
                                  {oItem.answer_by} - {oItem.answer_time}
                                </div>
                                <p>{oItem.answer}</p>
                              </div>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("noqna")}</div>
                  <div className="w-[200px] h-[200px] pt-3">
                    <Lottie
                      animationData={nodata}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default SeeQA;
