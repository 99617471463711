import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useNavigate } from "react-router-dom";
import { FavoriteBorder, Star } from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import "../home/product.scss";

const SSComponent = ({ productId, CateID }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [sameStore, setSameStore] = useState([]);

  useEffect(() => {
    getSameStore(CateID);
  }, [CateID]);

  const getSameStore = async (CateID) => {
    let obj = {
      method: "get",
      url: ApiCollections.sameStore,
      params: {
        id: productId,
        categoryid: CateID,
        page: 1,
        last: 0,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSameStore(response.data);
    }
  };

  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  return (
    <>
      {sameStore.length ? (
        <div className="product-wrapper grid grid-cols-5 gap-x-6">
          {sameStore?.map((pItem, pIndex) => (
            <div
              className="single-product"
              key={pIndex}
              onClick={() =>
                linkProductPage(pItem.id, pItem.item_status, pItem.item_id)
              }
            >
              <div className="single-product-img">
                <div className="w-full h-[211px]">
                  <img
                    src={pItem.photo}
                    className="w-full !h-full !object-fill image"
                  />
                </div>

                {/* overlay while hovering image */}
                {/* <div className="overlay"> */}
                {/* </div> */}

                {/* left side */}
                {pItem.product_promotion_percentage !== null && (
                  <div className="sg-floating-badges">
                    <span className="onsale percent-count">
                      -
                      {Number.parseFloat(
                        pItem.product_promotion_percentage
                      ).toFixed(0)}
                      %
                    </span>
                  </div>
                )}

                {/* right side */}
                <div className="sg-floating-icons">
                  <span className="!flex justify-center items-center">
                    <a
                      href=""
                      button-wishlist=""
                      data-product-handle="3-variable-product"
                    >
                      <Tooltip
                        title="Add to Wishlist"
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="left"
                      >
                        <FavoriteBorder fontSize="small" />
                      </Tooltip>
                    </a>
                  </span>
                </div>

                {/* bottom side */}
                {pItem.star_count != null && (
                  <div className="sg-floating-stars !flex justify-center items-center">
                    <div className="starsdiv">
                      {[...Array(pItem.star_count)].map((i) => (
                        <span key={i}>
                          <Star
                            fontSize="small"
                            id="onestar"
                            className="text-starColor"
                          />
                        </span>
                      ))}
                      {[...Array(5 - pItem.star_count)].map((i) => (
                        <span key={i}>
                          <Star
                            fontSize="small"
                            id="notstar"
                            className="text-notstarColor"
                          />
                        </span>
                      ))}
                      ({pItem.person_count})
                    </div>
                  </div>
                )}
              </div>

              {/* product content */}
              <div className="product-content ">
                <div className="title">
                  <h3 className="popup_cart_title">
                    <div>{pItem.product_name}</div>
                  </h3>
                </div>

                <div className="price">
                  {pItem.product_promotion_price !== 0 ? (
                    <>
                      <span className="current-price">
                        <span className="money">
                          {pItem.product_promotion_price}ks
                        </span>
                      </span>
                      <span className="discounted-price ">
                        <s className="money">{pItem.simple_price}ks</s>
                      </span>
                    </>
                  ) : (
                    <span className="current-price">
                      <span className="money">{pItem.simple_price}ks</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full h-auto text-xl text-center mt-4">{t("end")}</div>
      )}
      {/* {sameStore.length > 0 && (
        <InfiniteScroll
          dataLength={sameStore.length}
          next={fetchData}
          hasMore={true}
          loader={
            pageCount >= page ? (
              <div className="text-xl w-full py-4 text-center">Loading!</div>
            ) : (
              <div className="text-xl w-full py-4 text-center">No Product</div>
            )
          }
        ></InfiniteScroll>
      )} */}
    </>
  );
};

export default SSComponent;
