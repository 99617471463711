import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import JFYComponent from "./JFYComponent";

import "./justforyou.scss";

const JustForYou = () => {
  const { t } = useTranslation();

  return (
    <div className="just-you-wrapper">
      <div className="title-div">
        <div className="title">{t("just_title")}</div>
      </div>

      <JFYComponent />
    </div>
  );
};

export default JustForYou;
