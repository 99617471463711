import React, { useEffect, useState } from "react";
import "../../mobile/account/WishlistCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, Delete, ArrowForwardIos } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getWhishList();
  }, []);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };
  const [showList, setWhishList] = useState([]);
  const getWhishList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.favouriteList,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setWhishList(response.data);
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="while">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("MyWishlist")}</div>
        </div>
        {showList.length > 0 ? (
          <div>
            {showList.map((item, index) => (
              <div
                className="whileAll"
                onClick={() =>
                  gotoproduct(item.id, item.item_status, item.item_id)
                }
                key={index}
              >
                <div className="top">
                  <div className="storeName">{item.shop_name}</div>
                  {/* <ArrowForwardIos id="whilenext" /> */}
                </div>
                <div className="orderlistDiv">
                  <div className="cardImage">
                    <img src={item.product_photo} />
                  </div>
                  <div>
                    <div className="title">{item.product_name}</div>
                    <div className="price">{item.product_price}ks</div>
                    {/* <div className="qty">
                  Color:
                  <span className="count">Black</span>
                  <Delete id="whileDelete" />
                </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noDataform">
            <div className="text">{t("noWishlist")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wishlist;
