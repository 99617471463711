import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiRequest } from '../services/common/ApiRequest';

export const addToCartProductAsync = createAsyncThunk(
	'cart/addToCartProductAsync',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const setQuantity = createAsyncThunk(
	'cart/setQuantity',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const incrementQty = createAsyncThunk(
	'cart/incrementQty',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const decrementQty = createAsyncThunk(
	'cart/decrementQty',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const removeCartItem = createAsyncThunk(
	'cart/removeCartItem',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

