import React from "react";
import Wrapper from "../Wrapper";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TermPolicy = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Box
        sx={{
          width: "100%",
          paddingY: "20px",
        }}
      >
        <Container>
          <Box sx={{ paddingTop: "40px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("termsPolicy")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("policyDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("conditions")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("conditionDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("accountMain")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("accountFir")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("accountSec")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("intellectual")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("intellectualDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("pricing")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("pricingDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("suspend")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("suspendFir")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("suspendSec")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("suspendThd")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("shippingMain")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("shippingDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("refunds")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("refundsDes")}
            </Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              {t("reserve")}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="subtitle1" color="text.primary">
              {t("reserveDes")}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default TermPolicy;
