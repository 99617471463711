import React, { useState, useEffect } from "react";
import "../../mobile/account/ShowCouponCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import Loading from "../../components/commons/Loading";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import nodata from "../../assets/coupon.json";
import { useTranslation } from "react-i18next";

const ShowCoupon = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCouponLists();
  }, []);

  const [loading, setLoading] = useState(false);

  const [CouponLists, setCouponLists] = useState([]);
  const getCouponLists = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.couponLists,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setCouponLists(response.data.Coupon);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="couponDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("CouponTitle")}</div>
        </div>
        {CouponLists.length > 0 ? (
          <div className="couponCard">
            {CouponLists.map((item, index) => (
              <div key={index}>
                <img src={item.coupon_img} className="couponImg" />
                <div className="date">
                  &nbsp;&nbsp;{item.time_left}
                  <div className="name">Day Left</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("CouponNo")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShowCoupon;
