import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product_id: null,
  order_id: null,
};

export const reviewSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    addReview: (state, action) => {
      state.product_id = action.payload.product_id;
      state.order_id = action.payload.order_id;
    },
  },
});
export const { addReview } = reviewSlice.actions;

export default reviewSlice.reducer;
