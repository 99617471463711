// const request =  "http://192.168.1.6:8000/api/"
// const config = {
//     getCategory: request + 'categories',
// }
// export default config

// const domain = 'http://localhost:8000/api/';
const domain = process.env.REACT_APP_API_URL + "/api/";
const headers = new Headers();
headers.set("Accept", "*/*");
headers.set("Content-Type", "application/json");
headers.set("Access-Control-Allow-Origin", "*");

export const getAllData = (link) => {
  return fetch(domain + link, {
    method: "get",
    headers: headers,
  });
};

export const getData = async (link, $page = 1) => {
  return fetch(domain + link, {
    method: "get",
    headers: headers,
  });
};
