import React, { useEffect } from "react";
import { Container } from "@mui/material";

import Wrapper from "../Wrapper";

import "./home.scss";
import BannerSlide from "./BannerSlide";
import Categories from "./Categories";
import FlashSale from "./FlashSale";
import OfficialStores from "./OfficialStores";
import JustForYou from "./JustForYou";
import Popular from "./Popular";
import NewArrival from "./NewArrival";
import Promotion from "./Promotion";
import ReactGA from "react-ga";
ReactGA.initialize("UA-260952847-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = () => {
  useEffect(() => {
    let redirect = sessionStorage.getItem("redirectUrl");
    if (redirect) {
      sessionStorage.removeItem("redirectUrl");
      window.location.href = redirect;
    }
  }, []);

  return (
    <Wrapper>
      <BannerSlide />

      <Container>
        <Categories />

        {/* <FlashSale /> */}

        <Promotion />

        <OfficialStores />

        <NewArrival />

        {/* <Popular /> */}

        <JustForYou />
      </Container>
    </Wrapper>
  );
};

export default Home;
