import React, { useEffect, useState } from "react";
import "./ShowCouponCss.scss";
import Wrapper from "../Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/coupon.json";
import { useTranslation } from "react-i18next";

const ShowCoupon = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getCouponLists();
  }, []);

  const [loading, setLoading] = useState(false);

  const [CouponLists, setCouponLists] = useState([]);
  const getCouponLists = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.couponLists,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setCouponLists(response.data.Coupon);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="cuoponDiv">
          <OrderListNav />
          <div className="cuoponAll">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("CouponTitle")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {CouponLists.length > 0 ? (
                <Box
                  value="1"
                  sx={{ padding: "25px 25px 25px 45px !important" }}
                >
                  {CouponLists.map((item, index) => (
                    <div key={index}>
                      <img src={item.coupon_img} className="couponImg" />
                      <div className="date">
                        &nbsp;&nbsp;{item.time_left}
                        <div className="name">Day Left</div>
                      </div>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("CouponNo")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={nodata}
                      loop={true}
                      className="w-full h-[70%] object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ShowCoupon;
