import { Container } from "@mui/material";
import React from "react";
import { BsFacebook } from "react-icons/bs";
import { BsMessenger } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#094962] w-full h-auto">
        <Container>
          <div className="grid grid-cols-4 gap-x-8 py-[80px]">
            <div className="flex flex-col">
              <h4 className="text-white font-bold">{t("aboutUs")}</h4>
              <p className="text-white text-sm pt-3">{t("eCommerce")}</p>
            </div>
            <div className="flex flex-col">
              <h4 className="text-white font-bold">{t("information")}</h4>
              <div className="flex flex-col space-y-2 pt-3">
                <a
                  href="#"
                  className="text-white text-sm cursor-pointer hover:underline"
                  onClick={() => navigate("/MgdAbout")}
                >
                  {t("aboutUs")}
                </a>
                <a
                  href="#"
                  className="text-white text-sm cursor-pointer hover:underline"
                  onClick={() => navigate("/TermPolicy")}
                >
                  {t("tap")}
                </a>
                <a href="#" className="text-white text-sm">
                  {t("shippingDelivery")}
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <h4 className="text-white font-bold">{t("sell")}</h4>
              <div className="flex flex-col space-y-2 pt-3">
                <a href="#" className="text-white text-sm">
                  {t("memberships")}
                </a>
                <a
                  href="#"
                  className="text-white text-sm cursor-pointer hover:underline"
                  onClick={() => navigate("/howToSell")}
                >
                  {t("tosell")}
                </a>
                <a href="#" className="text-white text-sm">
                  {t("service")}
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <h4 className="text-white font-bold">{t("contact")}</h4>
              <div>
                <p className="text-white py-3 text-sm">
                  {t("zone")}
                  <br />
                  {t("yangon")}
                  <br />
                  09981600235
                </p>
                <div className="flex gap-x-5">
                  <a href="https://www.facebook.com/mgdmall">
                    <BsFacebook className="text-white text-xl" />
                  </a>
                  <a href="https://m.me/mgdmall">
                    <BsMessenger className="text-white text-xl" />
                  </a>
                  <a href="https://www.youtube.com/@mgdmall155">
                    <BsYoutube className="text-white text-xl" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#062542] w-full h-auto">
        <Container>
          <div className="w-full py-4 text-center text-white">
            Copyright 2023 All rights reserved, mgdmall.com.mm
          </div>
        </Container>
      </div>
    </>
  );
}
