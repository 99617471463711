import React, { useEffect, useState } from "react";
import "../../mobile/account/FllowStoreCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, ArrowForwardIos } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const FllowStore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getfollowList();
  }, []);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const goStoreShop = (id) => {
    navigate(`/StoreShop/${id}`);
  };
  const [showList, setFollowList] = useState([]);
  const getfollowList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.followList,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setFollowList(response.data);
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}

      <div className="fllow">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("FollowedStores")}</div>
        </div>
        {showList.length > 0 ? (
          <div>
            {showList.map((item, index) => (
              <div
                className="fllowAll"
                key={index}
                onClick={() => goStoreShop(item.id)}
              >
                <div className="orderlistDiv">
                  <div className="cardImage">
                    <img src={item.photo} />
                  </div>
                  <div>
                    <div className="title">{item.shop_name}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noDataform">
            <div className="text">{t("noFollowed")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FllowStore;
