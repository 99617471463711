import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import profileImg from "../../assets/profile.png";
import { useTranslation } from "react-i18next";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { common } from "@mui/material/colors";
import Loading from "../../components/commons/Loading";

const About = () => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState({});
  const [loading, setLoading] = useState(false);
  const getAboutPhotos = async () => {
    setLoading(true);
    const options = {
      method: "get",
      url: ApiCollections.aboutUsPhotos,
    };
    let res = await ApiRequest(options);
    setLoading(false);
    res = res.data;
    if (res.code === 200) {
      setPhotos(res);
    }
  };

  console.log(photos);

  useEffect(() => {
    getAboutPhotos();
  }, []);
  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#d4e4fe",
          paddingY: "20px",
        }}
      >
        <Container>
          <Stack alignItems="center" sx={{ paddingTop: "40px" }}>
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                fontWeight="bold"
                color="text.primary"
              >
                {t("mallAbout")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                fontWeight="normal"
                color="text.primary"
              >
                {t("Greeting")}
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
      {/* <Container>
      <Stack flexDirection="row" justifyContent="flex-start" marginY="30px">
        <Box sx={{ width: "50%" }}>
          <Stack flexDirection="row" alignItems="center" columnGap={4}>
            <Box
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={profileImg}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Stack>
              <Box>
                <Typography variant="subtitle1">{t("ceo")}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">{t("bossName")}</Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container> */}
      <Container>
        <Stack flexDirection="row" marginY="30px">
          <Box sx={{ width: "50%", display: "flex", flex: 1 }}>
            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
              <Stack
                justifyContent="center"
                rowGap={2}
                sx={{ flex: 1, width: "100%" }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {t("vision")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="text.primary">
                    {t("visiondes")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {t("mission")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="text.primary">
                    {t("missiondes")}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box
              sx={{
                width: "100%",
                height: "400px",
                borderRadius: "30px",
                overflow: "hidden",
              }}
            >
              <img
                src={photos?.photo_one}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                className="!h-[100%]"
              />
            </Box>
          </Box>
        </Stack>
      </Container>
      <Box sx={{ width: "100%", backgroundColor: "#d4e4fe", paddingY: "40px" }}>
        <Container>
          <Stack flexDirection="row" columnGap={6}>
            <Box sx={{ width: "35%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "350px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={photos?.photo_two}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  className="!h-[100%]"
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "65%",
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.primary"
                lineHeight="2.3"
              >
                {t("mgdDes")}
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            lineHeight: "2.1",
            py: "10px",
            marginTop: "30px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ width: "50%", mx: "auto", fontWeight: "bold" }}
          >
            {t("ischose")}
          </Typography>
        </Box>
        <Stack flexDirection="row" columnGap={6} sx={{ paddingTop: "20px" }}>
          <Box sx={{ width: "33.33%" }}>
            <Stack alignItems="center" rowGap={2}>
              <Box
                sx={{
                  width: "90%",
                  height: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={photos?.photo_three}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">{t("firstChose")}</Typography>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ width: "33.33%" }}>
            <Stack alignItems="center" rowGap={2}>
              <Box
                sx={{
                  width: "90%",
                  height: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={photos?.photo_four}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">{t("secChose")}</Typography>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ width: "33.33%" }}>
            <Stack alignItems="center" rowGap={2}>
              <Box
                sx={{
                  width: "90%",
                  height: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={photos?.photo_five}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">{t("thrChose")}</Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <Container sx={{ marginTop: "30px" }}>
        <Grid
          container
          columnGap={2}
          sx={{ width: "100%" }}
          justifyContent="space-between"
        >
          <Grid xs={2.8}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={photos?.photo_six}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid xs={2.8}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={photos?.photo_nine}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid xs={2.8}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={photos?.photo_seven}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid xs={2.8}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <img
                src={photos?.photo_eight}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            backgroundColor: "#d4e4fe",
            borderRadius: "40px",
            overflow: "hidden",
            px: "30px",
            py: "40px",
            marginTop: "60px",
          }}
        >
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            {t("comunicate")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", paddingTop: "30px" }}
          >
            {t("thank")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "60px",
          }}
        >
          <Stack>
            <Typography variant="subtitle1">{t("since")}</Typography>
            <Typography variant="subtitle1">{t("company")}</Typography>
          </Stack>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default About;
