import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiRequest } from '../services/common/ApiRequest';

export const requestLoginWithPhoneAsync = createAsyncThunk(
	'user/requestLoginWithPhoneAsync',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const loginWithSMS = createAsyncThunk(
	'user/loginWithSMS',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const requestOTP = createAsyncThunk(
	'user/requestOTP',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const registerWithPhone = createAsyncThunk(
	'user/registerWithPhone',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const requestResetPassword = createAsyncThunk(
	'user/requestResetPassword',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const requestResetPasswordSms = createAsyncThunk(
	'user/requestResetPasswordSms',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);

export const passwordReset = createAsyncThunk(
	'user/passwordReset',
  async (requestObj,{rejectWithValue}) => {
    try {
      let response = await ApiRequest(requestObj);
      return response;
    } catch (err) {
      if (typeof err === 'string') {
        return rejectWithValue(err) 
      } else {
        if (err.response && err.response.data) {
          return rejectWithValue(err.response.data)
          
        } else if (err.response) {
          return rejectWithValue(err.response)
        } else {
          return rejectWithValue(err)
        }
      }
    }
	}
);
