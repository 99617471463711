import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../mobile/accountSetting/SignupCss.scss";
import {
  ArrowCircleLeft,
  Phone,
  Lock,
  Facebook,
  Google,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { setAuthUser } from "../../reducers/user";
import { requestLoginWithPhoneAsync } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import getCustomerCartItems from "../helper/CustomerCart";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(6).label("Password"),
});
const LoginWithPhone = () => {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (token) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [navigate, token]);

  const goback = () => {
    navigate(-1);
  };

  const phoneLogin = async ({ phone, password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.loginWithPhone,
      params: {
        phone,
        password,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(
        requestLoginWithPhoneAsync(requestObj)
      ).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        dispatch(setAuthUser(response.data.data)); //set aut user data to state
        getCustomerCartItems(response.data.data.user.id);
        toast.success(response.data.message);
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        navigate("/");
      } else {
        toast.error("Something Wrong Login with Phone!");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Fetch failed: ${err.message}`);
    }
  };
  const registerWithPhone = () => {
    navigate("/Signup");
  };

  const handleGoogleLoginApi = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.loginWithSocial + `/google`,
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      setErrors(response.message);
    } else if (response.data.code === 200) {
      window.location.replace(response.data.data.url);
      localStorage.setItem("userTokenID", response.data.data.user.id);
      localStorage.setItem("userLoginStatus", "google");
    } else {
      toast.error("Something Wrong Login with Phone!");
    }
  };
  const handleFacebookLoginApi = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.loginWithSocial + `/facebook`,
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      setErrors(response.message);
    } else if (response.data.code === 200) {
      window.location.replace(response.data.data.url);
      localStorage.setItem("userTokenID", response.data.data.user.id);
      localStorage.setItem("userLoginStatus", "facebook");
    } else {
      toast.error("Something Wrong Login with Facebook!");
    }
  };
  const handleRequestPasswordReset = () => {
    navigate("/request-reset-password");
  };
  return (
    <div className="signup">
      {loading && <Loading open={loading} />}
      <ToastContainer autoClose={3000} />
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      {error && toast.error(error)}
      <div className="loginTitle">{t("LOGIN")}</div>
      <ArrowCircleLeft id="backin" onClick={() => goback()} />
      <Formik
        initialValues={{ phone: "", password: "" }}
        validationSchema={userInfoSchema}
        onSubmit={(values) => {
          phoneLogin(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="userBox">
              <div className="name">{t("phoneNumber")}</div>
              <div className="enterBox">
                <Phone id="photo" />
                <input
                  name="phone"
                  type="text"
                  placeholder={t("pleasePhone")}
                  className="inputEnter"
                  onChange={handleChange("phone")}
                />
              </div>
              {errors.phone && <CutomErrorMessage error={errors.phone} />}
            </div>
            <div className="passwordBox">
              <div className="name">{t("PASSWORD")}</div>
              <div className="enterBox">
                <Lock id="photo" />
                <input
                  placeholder={t("pleasePassword")}
                  className="inputEnter"
                  name="password"
                  type={isRevealPwd ? "text" : "password"}
                  onChange={handleChange("password")}
                />
                {isRevealPwd ? (
                  <Visibility
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                ) : (
                  <VisibilityOff
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                )}
              </div>
              {errors.password && <CutomErrorMessage error={errors.password} />}
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("LOGIN_BUTTON")}
            </div>
            <div className="phoneDiv !pb-[0vw]" onClick={registerWithPhone}>
              {t("DO_NOT_HAVE_ACCOUNT")}
              <span>&nbsp;{t("REGISTER")}</span>
            </div>
          </Form>
        )}
      </Formik>
      <div className="orDiv">
        <span className="orline"></span>
        <span className="ortext">{t("OR")}</span>
        <span className="orliner"></span>
      </div>
      <div className="btnLogFB" onClick={handleFacebookLoginApi}>
        <Facebook id="iconf" />
        {t("LOGIN_FACEBOOK")}
      </div>
      <div className="btnLogGM" onClick={handleGoogleLoginApi}>
        <Google id="iconf" />
        {t("LOGIN_GOOGLE")}
      </div>
      <div className="resetDiv" onClick={handleRequestPasswordReset}>
        {t("FORGET_YOUR_PASSWORD")}
        <span>&nbsp;{t("RESET_PASSWORD")}</span>
      </div>
    </div>
  );
};

export default LoginWithPhone;
