import React, { useEffect, useState } from "react";
import "../../mobile/account/OrderCancelCss.scss";
import { ArrowCircleLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const OrderCancel = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { cancelID } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderDetail();
    getReason();
  }, []);

  const [loading, setLoading] = useState(false);

  const goback = () => {
    navigate(-1);
  };

  const [reason, setReason] = useState("");
  const [showReason, setShowReason] = useState("");
  const handleChangeReason = (event) => {
    setShowReason(event.target.value);
  };
  const getReason = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.reasonCancelApi,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setReason(response.data);
    }
  };

  const confirmReson = async () => {
    if (showReason === "") {
      toast.error("Please Choose Select Reason", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      let requestObj = {
        method: "post",
        url: ApiCollections.orderCancelApi,
        params: {
          order_id: cancelID,
          cancel_status: showReason,
        },
      };

      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        toast.success(response.data.code, {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/OrderCancelList");
      } else {
        toast.error("Order Calcel Fail", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const [orderDetail, setOrderDetail] = useState([]);
  const getOrderDetail = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.orderDetailApi,
      params: {
        order_id: cancelID,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setOrderDetail(response.data);
    }
  };
  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="ordercancel">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("OrderCancel")}</div>
        </div>
        {orderDetail.orderLists &&
          orderDetail.orderLists.map((detailItem, detailIndex) => (
            <div className="orderProduct" key={detailIndex}>
              <img src={detailItem.photo} />
              <div className="detail">
                <div className="title">
                  {detailItem.item_name || detailItem.product_name}
                </div>
                <div className="price">
                  {detailItem.promotion_price !== 0 ? (
                    <span className="ori">
                      {detailItem.promotion_price} ks &nbsp;&nbsp;
                      <s>{detailItem.price}ks</s>
                    </span>
                  ) : (
                    <span className="ori">{detailItem.price} ks</span>
                  )}
                </div>
                {detailItem.color && (
                  <div className="itemBox">
                    <div className="colorBox">
                      {t("Color")}&nbsp;:&nbsp;
                      <span className="coll">{detailItem.color}</span>
                    </div>
                    <div className="sizeBox">
                      {t("Size")}&nbsp;:&nbsp;
                      <span className="siz">{detailItem.size}</span>
                    </div>
                  </div>
                )}
                <div className="qtyBox">
                  {t("Quantity")}&nbsp;:&nbsp;
                  <span className="qty">{detailItem.quantity}</span>
                </div>
              </div>
            </div>
          ))}

        <div className="cancelBox">
          <div className="name">{t("OrderCancelReason")}</div>
          <div className="enterBox">
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={showReason}
              onChange={handleChangeReason}
              displayEmpty
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "3.6vw !important",
                  lineHeight: "7vw !important",
                }}
              >
                {t("SelectTheReason")}
              </MenuItem>
              {reason &&
                reason.map((reasonItem, reasonIndex) => (
                  <MenuItem
                    key={reasonIndex}
                    value={reasonItem.status_id}
                    sx={{
                      fontSize: "3.6vw !important",
                      lineHeight: "7vw !important",
                    }}
                  >
                    {reasonItem.reason}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        <div className="confirmBtn" onClick={confirmReson}>
          {t("Confirm")}
        </div>
        <ToastContainer draggable={false} autoClose={3000} />
      </div>
    </>
  );
};

export default OrderCancel;
