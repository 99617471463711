import { Box, Button, Grid, TextField } from "@mui/material";
import {
  AccountCircle,
  PhonelinkRing,
  Home,
  AddLocation,
  LocationCity,
  Apartment,
  Mail,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import "./address.scss";

import Wrapper from "../Wrapper";

const Address = () => {
  const [data, setData] = useState("");

  const { address } = useSelector((state) => state.user);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    // setData(data);
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  return (
    <Wrapper>
      <Box mb={5} mt={10} px={10} className="pcAddress">
        <div className="deli-info-title">Delivery Information</div>
        <p>{data}</p>
        <Grid container spacing={3} my={2}>
          <Grid item xs={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} my={2}>
                <Grid item xs={6} px={2}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("name", {
                        required: true,
                        minLength: 5,
                        maxLength: 50,
                      })}
                      aria-invalid={errors.firstName ? "true" : "false"}
                      id="input-with-sx"
                      label="Name"
                      placeholder="Matthew"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.name?.type === "required" && (
                    <p role="alert" className="error">
                      Name is required!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} px={2}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <PhonelinkRing
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      // email => {...register({ required: true, pattern: /^\S+@\S+$/i })}
                      {...register("phone", {
                        required: true,
                        minLength: 5,
                        maxLength: 15,
                      })}
                      id="input-with-sx"
                      label="Phone"
                      placeholder="959000000000"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.phone?.type === "required" && (
                    <p role="alert" className="error">
                      Phone number is required and it can be number!!!
                    </p>
                  )}
                  {errors.phone?.type === "maxLength" && (
                    <p role="alert" className="error">
                      Your input exceed maximum length!!!
                    </p>
                  )}
                  {errors.phone?.type === "minLength" && (
                    <p role="alert" className="error">
                      Your input exceed minimum length!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} py={2} mt={4}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Mail
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("mail", {
                        required: true,
                      })}
                      type="mail"
                      id="input-with-sx"
                      label="Mail"
                      placeholder="matthew@gmail.com"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.mail?.type === "required" && (
                    <p role="alert" className="error">
                      Mail account is required and it can be number!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} py={2} mt={4}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Apartment
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("street", { required: true })}
                      id="input-with-sx"
                      label="Building/ House No/ Floor/ Street"
                      placeholder="Building 15, No. 5B, Floor 5, Yadana Street,"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.street?.type === "required" && (
                    <p role="alert" className="error">
                      Your street is required to deliver product!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} py={2} mt={4}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AddLocation
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("region", { required: true })}
                      id="input-with-sx"
                      label="Region"
                      placeholder="Yangon"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.region?.type === "required" && (
                    <p role="alert" className="error">
                      Your region is required to deliver product!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} py={2} mt={4}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <LocationCity
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("city", { required: true })}
                      id="input-with-sx"
                      label="City"
                      placeholder="Yangon"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.city?.type === "required" && (
                    <p role="alert" className="error">
                      Your city is required to deliver product!!!
                    </p>
                  )}
                </Grid>
                <Grid item xs={6} py={2} mt={4}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <LocationCity
                      sx={{
                        fontSize: 30,
                        color: "action.active",
                        mr: 1,
                        my: 0.5,
                      }}
                    />
                    <TextField
                      {...register("township", { required: true })}
                      id="input-with-sx"
                      label="Township"
                      placeholder="Kamayut"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  {errors.township?.type === "required" && (
                    <p role="alert" className="error">
                      Your township is required to deliver product!!!
                    </p>
                  )}
                </Grid>
              </Grid>
              <input type="submit" />
            </form>
          </Grid>
          <Grid item xs={4}>
            <div>Your Order(s)</div>
            <Grid container spacing={2} my={2}>
              <Grid item xs={8}>
                Items Total (1 Items)
              </Grid>
              <Grid item xs={4}>
                Ks 2000
              </Grid>
            </Grid>
            <Grid container spacing={2} my={2}>
              <Grid item xs={8}>
                Delivery Fee
              </Grid>
              <Grid item xs={4}>
                Ks 1000
              </Grid>
            </Grid>
            <Grid container spacing={2} my={2}>
              <Grid item xs={8}>
                Total
              </Grid>
              <Grid item xs={4}>
                Ks 3000
              </Grid>
            </Grid>
            <button
              className="confirm-btn"
              style={{ background: address !== null ? "blue" : "#ccc" }}
            >
              Confirm Order
            </button>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default Address;
