import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Box, Container, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import noorder from "../../assets/noorder.json";
import { useTranslation } from "react-i18next";

const OrderCancleList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCancleList();
  }, []);

  const [loading, setLoading] = useState(false);

  const [showCancleList, setCancelList] = useState([]);
  const getCancleList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.cancelListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setCancelList(response.data);
    }
  };

  const godeatil = (orderID) => {
    navigate(`/cancelDetail/${orderID}`);
  };

  return (
    <Wrapper>
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("OrderCancel")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {showCancleList.length > 0 ? (
                <Box value="1" sx={{ padding: "0 !important" }}>
                  {showCancleList.map((oItem, mIndex) => (
                    <div className="orderProduct" key={mIndex}>
                      <div className="orderHeader">
                        <div className="number flex justify-between items-center">
                          {t("OrderNumber")}&nbsp;:&nbsp;{oItem.order_code}
                        </div>
                        <div className="date !flex !gap-x-2">
                          {t("Date")}: <div>{oItem.date}</div>
                        </div>
                      </div>
                      {oItem.orderLists.map((oVariant, oIndex) => (
                        <div className="onePcProduct" key={oIndex}>
                          <div className="bodyProduct">
                            <div className="orderImage">
                              <img src={oVariant.photo} />
                            </div>
                            <div>
                              <div className="title">
                                {oVariant.product_name || oVariant.item_name}
                              </div>
                              <div className="price">
                                {oVariant.promotion_price !== 0 ? (
                                  <>
                                    <span className="current-price">
                                      <span className="money">
                                        {oVariant.promotion_price}ks
                                      </span>
                                    </span>
                                    <span className="detailprice ">
                                      <s className="money">
                                        {oVariant.price}ks
                                      </s>
                                    </span>
                                  </>
                                ) : (
                                  <span className="current-price">
                                    <span className="money">
                                      {oVariant.price}ks
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="qty !ml-auto">
                              <span className="">{t("Quantity")}: </span>
                              <span className="qtyNumber">
                                {oVariant.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="bottom">
                        <div className="paid">
                          {t("Payment")}&nbsp;:&nbsp;{oItem.payment_method}
                        </div>
                        <div
                          className="detail cursor-pointer"
                          onClick={() => godeatil(oItem.order_id)}
                        >
                          {t("OrderDetail")}
                        </div>
                      </div>
                    </div>
                  ))}
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("NoProduct")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={noorder}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default OrderCancleList;
