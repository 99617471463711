import React, { useState, useEffect } from "react";

import "../../mobile/accountSetting/LoginWithSms.scss";
import { Email } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { requestResetPasswordSms } from "../../reducers/userActions";
import { useTranslation } from "react-i18next";

const codeSchema = Yup.object({
  code: Yup.number().required().label("SMS Verification Code"),
});
const RequestResetPasswordSms = () => {
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, authUser } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [navigate, token]);
  const submitRequestResetPasswordSms = async ({ code }) => {
    let userId = authUser ? (authUser.user ? authUser.user.id : null) : null;
    let requestObj = {
      method: "post",
      url: ApiCollections.requestPasswordResetSms,
      params: {
        user_id: userId,
        otp: code,
      },
    };

    try {
      setErrors(null);
      const response = await dispatch(
        requestResetPasswordSms(requestObj)
      ).unwrap();
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        toast.success(response.data.message);
        navigate("/reset-password"); //redirect home
      } else {
        toast.error("Something Wrong Reset!");
      }
    } catch (err) {
      toast.error(`Reset failed: ${err.message}`);
    }
  };
  return (
    <div className="signup">
      <ToastContainer autoClose={3000} />
      {error && toast.error(error)}
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      <div className="loginTitle">{t("RequestPasswordResetWithSms")}</div>
      <Formik
        initialValues={{ code: "" }}
        validationSchema={codeSchema}
        onSubmit={(values) => {
          submitRequestResetPasswordSms(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="verCode">
              <div className="name">{t("VerificationCode")}</div>
              <div className="enterBox">
                <Email id="photo" />
                <input
                  type="number"
                  placeholder={t("pleaseVerificationCode")}
                  className="inputEnter"
                  name="code"
                  onChange={handleChange("code")}
                />
                {errors.code && <CutomErrorMessage error={errors.code} />}
              </div>
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("Reset")}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestResetPasswordSms;
