import { Outlet } from "react-router";
import { LastLocationProvider } from "react-router-dom-last-location";

export default function RouteLayout() {

  return (
    <>
      <LastLocationProvider>
        <Outlet />
      </LastLocationProvider>
    </>
  )
}