import React, { useState } from "react";
import { Grid } from "@mui/material";
import { StarOutline, StarRate, StarHalf } from "@mui/icons-material";

import "../home/popular.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import RPComponent from "./RPComponent";

const RelatedProduct = ({ relativeProducts }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="popular-wrapper !px-[24px]">
      <div className="title-div">
        <div className="title">{t("RelatedProducts")}</div>
      </div>
      <RPComponent relatedProducts={relativeProducts} />
    </div>
  );
};

export default RelatedProduct;
