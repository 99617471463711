import { ChevronRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./menu.scss";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  useEffect(() => {
    getSub();
  }, []);

  const navigate = useNavigate();

  const subAllProduct = (id) => {
    navigate(`/SubAllProduct/${id}`);
  };

  const grandAllProduct = (id) => {
    navigate(`/GrandAllProduct/${id}`);
  };

  const [showSub, setSub] = useState([]);
  const getSub = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.subAndGrandCategory,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSub(response.data);
    }
  };

  const [id, setId] = useState({
    category: null,
    sub_category: null,
    grand_category: null,
  });

  const [isActive, setIsActive] = useState({
    cat: false,
    sub: false,
  });

  const [data, setData] = useState({
    sub_category: null,
    grand: null,
  });

  const displaySubCategories = (item) => {
    setId({
      category: item.id,
    });
    if (item.sub_category !== undefined && item.sub_category !== null) {
      setIsActive({ cat: true });
      item.sub_category !== undefined &&
        setData({ sub_category: item.sub_category });
    } else {
      setIsActive({ ...isActive, cat: false });
    }
  };

  const displayGrandCategories = (item) => {
    setId({ ...id, sub_category: item.id });
    if (item.grand !== undefined && item.grand !== null) {
      setIsActive({ ...isActive, sub: true });
      setData({ ...data, grand: item.grand });
    } else {
      setIsActive({ ...isActive, sub: false });
    }
  };

  const hideClass = () => {
    setId({ category: null, sub_category: null });
    setIsActive({ cat: false });
  };

  return (
    <div
      id="category"
      className="menu-wrapper"
      onMouseLeave={() => hideClass()}
    >
      <div className="menu-div">
        {showSub.length > 0 &&
          showSub.slice(0, 9).map((item, index) => (
            <div
              className={`${item.id === id.category ? "active" : ""} menu`}
              key={index}
            >
              <div onMouseOver={() => displaySubCategories(item)}>
                {item.category_name}
              </div>
              {isActive.cat && item.id === id.category && <ChevronRight />}
            </div>
          ))}
      </div>

      {/* Sub Categories */}
      <div className="menu-div">
        {isActive.cat &&
          data.sub_category !== null &&
          data.sub_category.map(
            (item, index) =>
              id.category === item.category_id && (
                <div
                  className={`${
                    item.id === id.sub_category ? "active" : ""
                  } menu`}
                  key={index}
                  onClick={() => subAllProduct(item.id)}
                >
                  <div onMouseOver={() => displayGrandCategories(item)}>
                    {item.subcategory_name}
                  </div>
                  {isActive.sub && item.id === id.sub_category && (
                    <ChevronRight />
                  )}
                </div>
              )
          )}
      </div>

      {/* Grand Categories */}
      <div className="menu-div">
        {isActive.sub &&
          data.grand !== null &&
          data.grand.map(
            (item, index) =>
              id.sub_category === item.subcategory_id && (
                <div
                  className={`${
                    item.id === id.grand_category ? "active" : ""
                  } menu`}
                  key={index}
                  onClick={() => grandAllProduct(item.id)}
                >
                  <div
                    onMouseOver={() =>
                      setId({ ...id, grand_category: item.id })
                    }
                  >
                    {item.grandsubcategory_name}
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default Menu;
