import { createSlice } from "@reduxjs/toolkit";

import {
  addToCartProductAsync,
  setQuantity,
  incrementQty,
  decrementQty,
  removeCartItem,
} from "./cartActions";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    checkoutItems: [],
    totalCount: 0,
    totalAmount: 0,
    discountTotalAmount: 0,
    totalCheckoutAmount: 0,
    totalCheckoutDiscountAmount: 0,
    totalCheckoutAmountTemp: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      state.cart = action.payload;
      // const itemInCart = state.cart.find((item) => item.id === action.payload.id);
      // if (itemInCart) {
      //   itemInCart.quantity++;
      // } else {
      //   if (!action.payload.quantity) {
      //     state.cart.push({ ...action.payload, quantity: 1 });
      //   } else {
      //     state.cart.push({ ...action.payload });
      //   }
      // }
    },
    updateCart: (state, action) => {
      state.cart = action.payload;
    },
    setQuantityOfItem: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload.id);
      item.quantity = action.payload.quantity;
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      item.quantity++;
    },

    decrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.id !== action.payload
      );
      state.cart = removeItem;
    },
    getCartCount: (state, action) => {
      let cartCount = state.cart.reduce((total, item) => {
        return parseInt(item.quantity) + total;
      }, 0);
      state.totalCount = cartCount;
    },
    getTotalAmount: (state, action) => {
      state.totalAmount = state.cart.reduce((acc, item) => {
        if (item.promotion_price !== 0) {
          return acc + item.promotion_price * item.quantity;
        } else {
          return acc + item.price * item.quantity;
        }
      }, 0);
      state.discountTotalAmount = state.cart.reduce((acc, item) => {
        return acc + (item.price - item.promotion_price) * item.quantity;
      }, 0);
    },
    setCheckoutItems: (state, action) => {
      state.totalCheckoutAmount = action.payload.reduce(
        (totalCheckoutAmt, item) => {
          return totalCheckoutAmt + item.price * item.quantity;
        },
        0
      );
      state.totalCheckoutDiscountAmount = action.payload.reduce(
        (totalCheckoutDiscountAmount, item) => {
          if (item.promotion_price !== 0) {
            return (
              totalCheckoutDiscountAmount +
              (item.price - item.promotion_price) * item.quantity
            );
          } else {
            return totalCheckoutDiscountAmount + 0;
          }
        },
        0
      );
      state.checkoutItems = action.payload;
    },
    updateTotalCheckoutAmountTemp: (state, action) => {
      if (action.payload.type == "ADD") {
        state.totalCheckoutAmountTemp = state.checkoutItems.reduce(
          (amount, item) => {
            if (item.promotion_price !== 0) {
              return amount + item.promotion_price * item.quantity;
            } else {
              return amount + item.price * item.quantity;
            }
          },
          0
        );
      } else {
        state.totalCheckoutAmountTemp = 0;
      }
    },

    removeChecked: (state) => {
      state.checkoutItems = [];
    },
  },
  extraReducers: {
    [addToCartProductAsync.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [addToCartProductAsync.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    [setQuantity.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [setQuantity.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    [incrementQty.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [incrementQty.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    [decrementQty.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [decrementQty.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    [removeCartItem.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [removeCartItem.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

export const {
  addToCart,
  updateCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  getTotalAmount,
  getCartCount,
  setQuantityOfItem,
  setCheckoutItems,
  updateTotalCheckoutAmountTemp,
  removeChecked,
} = cartSlice.actions;
export default cartSlice.reducer;
