import React, { useState, useEffect } from "react";
import "../../mobile/account/MessageCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import Lottie from "lottie-react";
import comingsoon from "../../assets/comingsoon.json";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const Message = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="messageDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("Message")}</div>
        </div>

        <div className="noOrder">
          <div className="text">{t("soon")}</div>
          <div className="iconWrap">
            <Lottie animationData={comingsoon} loop={true} id="lot" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
