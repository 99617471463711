import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";
import { useLastLocation } from "react-router-dom-last-location";

const primary = purple[500]; // #f44336

export default function AuthProtectedRoute() {
  const { token } = useSelector((state) => state.user);
  const { lastLocation } = useLastLocation();
  if (lastLocation && lastLocation.pathname) {
    if (!token) {
      sessionStorage.setItem("redirectUrl", lastLocation.pathname);
    }

  }

  // show unauthorized screen if no user is found in redux store
  if (!token) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#5582b3",
        }}
      >
        {/* <Typography variant="h1" style={{ color: 'white' }}>
              404
            </Typography> */}
        <img
          src={require("../../assets/images/Logopng.png")}
          style={{ width: "25vw", height: "25vw" }}
        />
        <Typography
          variant="h6"
          style={{ color: "white", padding: "5vw 0", fontSize: "4vw" }}
        >
          Please, Login First !
        </Typography>
        <NavLink to="/login-with-phone">
          <Button
            variant="contained"
            style={{ color: "white", fontSize: "4vw" }}
          >
            Go To Login
          </Button>
        </NavLink>
      </Box>
    );
  }
  // returns child route elements
  return <Outlet />;
}
