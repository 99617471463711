import React, { useEffect, useState } from "react";
import "../../mobile/account/MgdAboutCss.scss";
import { ArrowCircleLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";

const MgdAbout = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAbout();
  }, []);

  const goback = () => {
    navigate(-1);
  };

  const [showAbout, setAbout] = useState([]);
  const getAbout = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.aboutUsPhotos,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAbout(response);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="mgdAbout">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("mallAbout")}</div>
        </div>
        {/* <div className="ceoDiv">
          <div className="left">
            <img className="image" src={require("../../assets/profile.png")} />
          </div>
          <div className="right">
            <div className="text">{t("ceo")}</div>
            <div className="name">{t("bossName")}</div>
          </div>
        </div> */}
        <div className="mgdBuilding">
          <img className="image" src={showAbout.photo_one} />
        </div>
        <div className="visionDiv">
          <div className="title">{t("vision")}</div>
          <div className="des">{t("visiondes")}</div>
        </div>
        <div className="visionDiv">
          <div className="title">{t("mission")}</div>
          <div className="des">{t("missiondes")}</div>
        </div>
        <div className="mgdDetail">
          <div className="top">
            <img className="image" src={showAbout.photo_two} />
          </div>
          <div className="bottom">
            <div className="text">{t("mgdDes")}</div>
          </div>
        </div>
        <div className="chose">
          <div className="text">{t("ischose")}</div>
        </div>
        <div className="firstChose">
          <div className="left">
            <img className="image" src={showAbout.photo_three} />
          </div>
          <div className="right">
            <div className="text">{t("firstChose")}</div>
          </div>
        </div>
        <div className="secChose">
          <div className="left">
            <div className="text">{t("secChose")}</div>
          </div>
          <div className="right">
            <img className="image" src={showAbout.photo_four} />
          </div>
        </div>
        <div className="firstChose">
          <div className="left">
            <img className="image" src={showAbout.photo_five} />
          </div>
          <div className="right">
            <div className="text">{t("thrChose")}</div>
          </div>
        </div>
        <div className="communicate">
          <div className="top">
            <div className="left">
              <img className="image" src={showAbout.photo_six} />
            </div>
            <div className="right">
              <img className="image" src={showAbout.photo_seven} />
            </div>
          </div>
          <div className="middle">{t("comunicate")}</div>
          <div className="top">
            <div className="left">
              <img className="image" src={showAbout.photo_eight} />
            </div>
            <div className="right">
              <img className="image" src={showAbout.photo_nine} />
            </div>
          </div>
        </div>
        <div className="thz">{t("thank")}</div>
        <div className="finish">
          <div className="since">{t("since")}</div>
          <div className="company">{t("company")}</div>
        </div>
      </div>
    </>
  );
};

export default MgdAbout;
