import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import { Star } from "@mui/icons-material";
import { FavoriteBorder } from "@mui/icons-material";
import { Fade, Tooltip } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../home/product.scss";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useTranslation } from "react-i18next";
import Loading from "../../components/commons/Loading";

const SearchProduct = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getSearchQueryProduct();
  }, [searchParams]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const linkProductPage = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  let searchQuery = searchParams.get("search")
    ? searchParams.get("search")
    : null;
  const [searchProducts, setSearchProducts] = useState([]);
  const getSearchQueryProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.searchProducts,
      params: {
        search: `${searchQuery}`,
      },
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      toast.error(`Product not found for ${searchQuery}`);
    } else if (response.data.code === 200) {
      setSearchProducts(response.data.data);
    } else if (response.data.code === 404) {
      toast.error(response.data.data.message);
    } else {
      toast.error("Something Wrong when try to search products!");
    }
  };

  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        {loading && <Loading open={loading} />}
        <ToastContainer autoClose={3000} />
        <div className="w-full h-auto py-[20px]">
          <div className="product-wrapper grid grid-cols-5 gap-x-6">
            {searchProducts?.map((naItem, naIndex) => (
              <motion.div
                className="single-product"
                key={naIndex}
                onClick={() =>
                  linkProductPage(naItem.id, naItem.item_status, naItem.item_id)
                }
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="single-product-img">
                  <div className="w-full h-[211px]">
                    <img
                      src={naItem.photo}
                      className="w-full !h-full !object-fill image"
                    />
                  </div>

                  {naItem.product_promotion_percentage !== null && (
                    <div className="sg-floating-badges">
                      <span className="onsale percent-count">
                        -
                        {Number.parseFloat(
                          naItem.product_promotion_percentage
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  )}

                  {/* right side */}
                  <div className="sg-floating-icons">
                    <span className="!flex justify-center items-center">
                      <a
                        href=""
                        button-wishlist=""
                        data-product-handle="3-variable-product"
                      >
                        <Tooltip
                          title="Add to Wishlist"
                          arrow
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          placement="left"
                        >
                          <FavoriteBorder fontSize="small" />
                        </Tooltip>
                      </a>
                    </span>
                  </div>

                  {/* bottom side */}
                  {naItem.star_count != null && (
                    <div className="sg-floating-stars !flex justify-center items-center">
                      <div className="starsdiv">
                        {[...Array(naItem.star_count)].map((i) => (
                          <span key={i}>
                            <Star
                              fontSize="small"
                              id="onestar"
                              className="text-starColor"
                            />
                          </span>
                        ))}
                        {[...Array(5 - naItem.star_count)].map((i) => (
                          <span key={i}>
                            <Star
                              fontSize="small"
                              id="notstar"
                              className="text-notstarColor"
                            />
                          </span>
                        ))}
                        ({naItem.person_count})
                      </div>
                    </div>
                  )}
                </div>

                {/* product content */}
                <div className="product-content ">
                  <div className="title">
                    <h3 className="popup_cart_title">
                      <div>{naItem.product_name}</div>
                    </h3>
                  </div>

                  <div className="price">
                    <span className="current-price">
                      <span className="money">{naItem.simple_price}ks</span>
                    </span>
                    {naItem.product_promotion_price !== 0 && (
                      <span className="discounted-price ">
                        <s className="money">
                          {naItem.product_promotion_price}ks
                        </s>
                      </span>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default SearchProduct;
