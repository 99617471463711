import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isActive: "/",
}

export const bottomNavSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    checkedNavActive: (state, action) => {
      state.isActive = action.payload.isActive;
    },
  },
})

// Action creators are generated for each case reducer function
export const { checkedNavActive } = bottomNavSlice.actions

export default bottomNavSlice.reducer

