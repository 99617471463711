import React, { useState, useEffect } from "react";
import "./SignUpCss.scss";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import signupCover from "../../assets/signupCover.json";
import { Email } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Container, Button } from "@mui/material";
import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { loginWithSMS, requestOTP } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import getCustomerCartItems from "../../mobile/helper/CustomerCart";
import { useTranslation } from "react-i18next";

const codeSchema = Yup.object({
  code: Yup.number().required().label("SMS Verification Code"),
});

const LoginWithSms = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const { authUser, error, token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);
  const submitSmsLogin = async ({ code }) => {
    let userId = authUser ? (authUser.user ? authUser.user.id : null) : null;
    let requestObj = {
      method: "post",
      url: ApiCollections.loginWithSmsOTP,
      params: {
        user_id: userId,
        otp: code,
      },
    };

    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(loginWithSMS(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
        localStorage.setItem("userTokenID", response.data.data.user.id);
        dispatch(setAuthUser(response.data.data));
        getCustomerCartItems(response.data.data.user.id);
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        navigate("/"); //redirect home
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === false
      ) {
        toast.error(response.data.message);
      } else {
        toast.error("Something Wrong Login !");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Login failed: ${err.message}`);
    }
  };
  const requestSmsCode = async () => {
    let userId = authUser ? (authUser.user ? authUser.user.id : null) : null;
    let requestObj = {
      method: "get",
      url: ApiCollections.requestSmsCode,
      params: {
        user_id: userId,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(requestOTP(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === false
      ) {
        toast.error(response.data.message);
      } else {
        toast.error("Something Wrong Request OTP !");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Request OTP failed: ${err.message}`);
    }
  };
  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <div className="SignUpDiv">
          <ToastContainer autoClose={3000} />
          {error && toast.error(error)}
          {errors &&
            errors.map((error, index) => {
              toast.error(error);
            })}
          <div className="leftBox">
            <div className="loginTitle">{t("LoginWithSms")}</div>

            <Formik
              initialValues={{ code: "" }}
              validationSchema={codeSchema}
              onSubmit={(values) => {
                submitSmsLogin(values);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form>
                  <div className="passwordBox">
                    <div className="name">{t("VerificationCode")}</div>
                    <div className="enterBox">
                      <Email id="mphoto" />
                      <input
                        type="number"
                        placeholder={t("pleaseVerificationCode")}
                        className="inputEnter"
                        name="code"
                        onChange={handleChange("code")}
                      />
                      {/* <div className="sms" onClick={requestSmsCode}>
                      Get Code Via SMS
                    </div> */}
                    </div>
                    <div className="request-sms-btn-container">
                      <p className="text-[13px]">{t("Don'tReceiveSms")}</p>
                      <Button variant="contained" onClick={requestSmsCode}>
                        {t("GetCodeViaSms")}
                      </Button>
                    </div>
                    {errors.vercode && (
                      <CutomErrorMessage error={errors.vercode} />
                    )}
                  </div>
                  <div className="btnLog" onClick={(e) => handleSubmit(e)}>
                    {t("LOGIN_BUTTON")}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="rightBox">
            <Lottie animationData={signupCover} loop={true} id="logCov" />
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default LoginWithSms;
