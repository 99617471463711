import React, { useState, useEffect } from "react";
import "../../mobile/accountSetting/SignupCss.scss";
import {
  ArrowCircleLeft,
  Phone,
  Lock,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { registerWithPhone } from "../../reducers/userActions";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(6).label("Password"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
const SignupWithPhone = () => {
  const { token } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  useEffect(() => {
    if (token) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [navigate, token]);

  const goback = () => {
    navigate(-1);
  };

  const submitSignUp = async ({ phone, password, confirm_password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.registerWithPhone,
      params: {
        phone,
        password,
        confirm_password,
      },
    };

    try {
      setErrors(null);
      const response = await dispatch(registerWithPhone(requestObj)).unwrap();
      if (response.flag === false) {
        setErrors([response.message[0].data.message]);
      } else if (
        response.data.code === 201 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
        //set aut user data to state
        dispatch(setAuthUser(response.data.data));
        navigate("/login-sms"); // step two for login (that must enter sms code)
      } else {
        toast.error("Something Wrong on Register!");
      }
    } catch (err) {
      toast.error(`Request failed: ${err.message}`);
    }
  };
  const gotosignin = () => {
    navigate("/login-with-phone");
  };
  return (
    <div className="signup">
      <ToastContainer autoClose={3000} />
      {error && toast.error(error)}
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      <div className="loginTitle">{t("SIGNUP")}</div>
      <ArrowCircleLeft id="backin" onClick={() => goback()} />
      <Formik
        initialValues={{ phone: "", password: "", confirm_password: "" }}
        validationSchema={userInfoSchema}
        onSubmit={(values) => {
          submitSignUp(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="userBox">
              <div className="name">{t("phoneNumber")}</div>
              <div className="enterBox">
                <Phone id="photo" />
                <input
                  name="phone"
                  type="text"
                  placeholder={t("pleasePhone")}
                  className="inputEnter"
                  onChange={handleChange("phone")}
                />
              </div>
              {errors.phone && <CutomErrorMessage error={errors.phone} />}
            </div>
            <div className="passwordBox">
              <div className="name">{t("PASSWORD")}</div>
              <div className="enterBox">
                <Lock id="photo" />
                <input
                  placeholder={t("pleasePassword")}
                  className="inputEnter"
                  name="password"
                  type={isRevealPwd ? "text" : "password"}
                  onChange={handleChange("password")}
                />
                {isRevealPwd ? (
                  <Visibility
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                ) : (
                  <VisibilityOff
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                )}
              </div>
              {errors.password && <CutomErrorMessage error={errors.password} />}
            </div>
            <div className="passwordBox">
              <div className="name">{t("ConfirmPassword")}</div>
              <div className="enterBox">
                <Lock id="photo" />
                <input
                  placeholder={t("pleasePassword")}
                  className="inputEnter"
                  name="confirm_password"
                  type={isRevealPwd ? "text" : "password"}
                  onChange={handleChange("confirm_password")}
                />
                {isRevealPwd ? (
                  <Visibility
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                ) : (
                  <VisibilityOff
                    id="eyepho"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                )}
              </div>
              {errors.confirm_password && (
                <CutomErrorMessage error={errors.confirm_password} />
              )}
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("SIGNUP_BUTTON")}
            </div>
          </Form>
        )}
      </Formik>
      <div className="phoneDiv" onClick={gotosignin}>
        {t("phoneNumber")}
        <span>&nbsp;{t("LOGIN")}</span>
      </div>
    </div>
  );
};

export default SignupWithPhone;
