import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Star } from "@mui/icons-material";

import "./popular.scss";
import { Navigate, useNavigate } from "react-router";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";

const Promotion = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getPromotion();
  }, []);

  const viewMore = () => {
    navigate("PromotionMore");
  };

  const [promotion, setPromotion] = useState("");
  const getPromotion = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.promotionCampange,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setPromotion(response.data);
    }
  };

  return (
    <>
      {promotion && (
        <div className="popular-wrapper">
          <div className="title-div">
            <div className="title">Promotion</div>
            <div className="view-more" onClick={() => viewMore()}>
              See All
            </div>
          </div>
          {promotion?.campange && (
            <div className="w-full h-[300px] py-[20px]">
              <img
                src={promotion?.campange?.campange_photo}
                className="w-full h-[320px]"
              />
            </div>
          )}

          {promotion?.products?.length > 0 && (
            <div className="w-full h-auto grid grid-cols-3 gap-x-4">
              {promotion?.products?.slice(0, 3).map((pItem, pIndex) => (
                <div
                  key={pIndex}
                  className="pHC relative flex w-full h-auto justify-between py-4 px-4 border-b border-gray-400 cursor-pointer z-10"
                  onClick={() => {
                    if (pItem.item_status != 0) {
                      navigate(
                        `/products/product-detail/${pItem.product_id}/${pItem.item_id}`
                      );
                    } else {
                      navigate(`/products/product-detail/${pItem.product_id}`);
                    }
                  }}
                >
                  {pItem.promotion_percentage !== null && (
                    <div className="absolute top-0 -left-5 w-[40px] h-[40px] rounded-full flex justify-center items-center text-[12px] text-white bg-[#408d7f]">
                      -
                      {Number.parseFloat(pItem.promotion_percentage).toFixed(0)}
                      %
                    </div>
                  )}
                  <div className="w-[40%] h-[130.13px] -z-10 overflow-hidden">
                    <img className="w-full h-full" src={pItem.photo} />
                  </div>
                  <div className="w-[50%] flex flex-col justify-center gap-y-2">
                    <span className="promotionName">{pItem.product_name}</span>
                    <div className="price">
                      {pItem.discount_price !== 0 ? (
                        <>
                          <span className="current-price">
                            <span className="money">
                              {pItem.discount_price}ks
                            </span>
                          </span>
                          <span className="discounted-price ">
                            <s className="money">{pItem.price}ks</s>
                          </span>
                        </>
                      ) : (
                        <span className="current-price">
                          <span className="money">{pItem.price}ks</span>
                        </span>
                      )}
                    </div>
                    <div>
                      {pItem.star_count > 0 && (
                        <div className="flex">
                          {[...Array(pItem.star_count)].map((i) => (
                            <span key={i}>
                              <Star
                                fontSize="small"
                                id="onestar"
                                className="text-starColor"
                              />
                            </span>
                          ))}
                          {[...Array(5 - pItem.star_count)].map((i) => (
                            <span key={i}>
                              <Star
                                fontSize="small"
                                id="notstar"
                                className="text-notstarColor"
                              />
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* <div className="w-full h-auto grid grid-cols-3 gap-x-4">
        {test.map((pItem, pIndex) => (
          <div className="flex w-full h-auto justify-between py-4 border-b border-gray-400">
            <div className="w-[40%] h-[96px]">
              <img className="w-full h-auto object-cover" src="" />
            </div>
            <div className="w-[50%] flex flex-col justify-center gap-y-2">
              <div>jjjj</div>
              <div className="popular-price">222</div>
              <div>
                {[...Array(5)].map((e, i) => (
                  <span>
                    <StarOutline fontSize="small" />
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div> */}
        </div>
      )}
    </>
  );
};

export default Promotion;
