import React, { useState } from "react";
import { Grid } from "@mui/material";
import { StarOutline, StarRate, StarHalf } from "@mui/icons-material";

import "../home/popular.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import SSComponent from "./SSComponent";

const SameStore = ({ productId, cateId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = [1, 2, 3, 4, 5];

  const viewMore = ({ productId }) => {
    navigate("/NewArivial");
  };

  return (
    <div className="popular-wrapper !px-[24px]">
      <div className="title-div">
        <div className="title">{t("SameStoreProducts")}</div>
      </div>
      <SSComponent productId={productId} CateID={cateId} />
    </div>
  );
};

export default SameStore;
