import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  couponID: null,
  couponPrice: null,
  couponStatus: 0,
};

export const couponSlice = createSlice({
  name: "couponSlice",
  initialState,
  reducers: {
    addID: (state, action) => {
      state.couponID = action.payload;
    },
    addPrice: (state, action) => {
      state.couponPrice = action.payload;
    },
    addStatus: (state, action) => {
      state.couponStatus = action.payload;
    },
  },
});
export const { addID, addPrice, addStatus } = couponSlice.actions;

export default couponSlice.reducer;
