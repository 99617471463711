import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import "../../mobile/category/CategoryCss.scss";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Categories = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getCategory();
    getSub();
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChangePC = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [mainCate, setCate] = useState([]);
  const getCategory = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.mainCategory,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setCate(response.data);
    }
  };

  const goAllProduct = (id) => {
    navigate(`/GrandAllProduct/${id}`);
  };

  const [showSub, setSub] = useState([]);
  const getSub = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.mobileSubAndGrandCategory,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSub(response.data);
    }
  };

  return (
    <Wrapper>
      {loading && <Loading open={loading} />}
      <div className="Categories h-[100vh] overflow-hidden">
        <div className="cateheader" id="topHeader">
          <img
            src={require("../../assets/images/MGDLogo.png")}
            className="headerLogo"
          />
        </div>
        <Box
          sx={{
            width: "100%",
            height: "88%",
            bgcolor: "#fff",
            display: "flex",
            fontSize: "3.5vw",
            width: "100%",
          }}
        >
          <Box
            className="grandAndSub"
            sx={{
              width: "30%",
              height: "100%",
              backgroundColor: "#fff",
              overflow: "scroll",
              overflowY: "auto",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {mainCate.map((first, findex) => (
                <Tab
                  label={first.category_name}
                  {...a11yProps(findex)}
                  id="tabfont"
                  icon={<Avatar src={first.image_url} id="firsticon" />}
                />
              ))}
            </Tabs>
          </Box>
          {showSub.map((join, jindex) => (
            <TabPanel
              value={value}
              index={jindex}
              key={jindex}
              style={{ width: "70%" }}
            >
              {join.sub_category &&
                join.sub_category.map((item, index) => (
                  <Accordion
                    id="accolt"
                    key={index}
                    expanded={expanded === index}
                    onChange={handleChangePC(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore id="arrowicon" />}
                      aria-controls={item.id}
                      id={item.id}
                    >
                      <Typography className="title">
                        {item.subcategory_name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px !important" }}>
                      <ul className="con">
                        {item.grand &&
                          item.grand.map((con, index1) => (
                            <li
                              className="list"
                              key={index1}
                              onClick={() => goAllProduct(con.id)}
                            >
                              <img src={con.image_url} />
                              <Typography className="text1Hidden">
                                {con.grandsubcategory_name}
                              </Typography>
                            </li>
                          ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </TabPanel>
          ))}

          {/* <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel> */}
        </Box>
      </div>
    </Wrapper>
  );
};

export default Categories;
