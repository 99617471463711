import React, { useState, useEffect } from "react";
import "./CheckOutCss.scss";
import Wrapper from "../Wrapper";
import { Grid, Box, MenuItem, Select, Modal } from "@mui/material";
import {
  FmdGood,
  Phone,
  Apartment,
  Person,
  AddLocation,
  Business,
  KeyboardArrowRight,
  HighlightOff,
} from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import selectaddress from "../../assets/selectaddress.json";
import Loading from "../../components/commons/Loading";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../../mobile/httpConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { addID, addPrice, addStatus } from "../../reducers/couponSlice";
import nodata from "../../assets/coupon.json";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

const couponstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 2,
  overflowY: "scroll",
};

const CheckOut = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { authUser } = useSelector((state) => state.user);

  const state = useSelector((state) => state.checkout);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAddressList();
    getCheckOut();
    getRegion();
    setPhone(authUser.user.phone_number);
    getCouponLists();
    dispatch(addID(null));
    dispatch(addPrice(null));
    dispatch(addStatus(0));
  }, []);

  const [loading, setLoading] = useState(false);

  const userLoginStatus = () => {
    let loginStatus = "";
    const customer = authUser ? (authUser.user ? authUser.user : null) : null;
    const status = localStorage.getItem("userLoginStatus");
    if (customer && status == "google") {
      loginStatus = "google";
    }
    if (customer && status == "facebook") {
      loginStatus = "facebook";
    }
    if (customer && status == "phone") {
      loginStatus = "phone";
    }
    return loginStatus;
  };

  const [addressList, setAddressList] = useState([]);
  const getAddressList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      getDeliveryFee(response.data.township_id, response.data.region_id);
      setName(response.data.name);
      setPhone(response.data.phone_number);
      setAddress(response.data.address);
      setShowRegion(response.data.region_id);
      setShowTownship(response.data.township_id);
    } else {
      setAddressList(response.data);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState([]);
  const [Cod, setCod] = useState([]);
  const [largeItem, setLargeItem] = useState([]);
  const [deliveryExtra, setDeliveryExtra] = useState([]);
  const getDeliveryFee = async (townID, regionID) => {
    let productIdsArr = [];
    if (state.product_id != null) {
      productIdsArr.push({
        id: state.product_id,
        quantity: state.qty,
        type: "product",
      });
    } else {
      productIdsArr.push({
        id: state.item_id,
        quantity: state.qty,
        type: "item",
      });
    }

    let requestObj = {
      method: "post",
      url: ApiCollections.deliveryFeeApi,
      params: {
        township_id: townID,
        region_id: regionID,
        productIds_arr: productIdsArr,
      },
    };

    let response = await ApiRequest(requestObj);

    if (response.data.code === 200) {
      setDeliveryFee(response.data.data.delivery_fee);
      setCod(response.data.data.cod_status);
      setLargeItem(response.data.data.large_item_status);
      const { finalTotal } = response.data.data.extra_charge.reduce(
        (total, item) => {
          const { shipping_type_price } = item;
          const data = shipping_type_price;
          total.finalTotal += data;
          return total;
        },
        { finalTotal: 0 }
      );
      setDeliveryExtra(finalTotal);
    }
  };

  const gotoOrderList = async () => {
    if (addressList == null) {
      toast.error("Please Address", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (largeItem == 1 && Cod == 0) {
      toast.error(
        "Sorry, this item can't be shipped to your selected address",
        {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      navigate("/Payment");
    }
  };

  const [checkShow, setCheckOut] = useState([]);
  const [totalCal, setTotalCal] = useState([]);
  const [promotionCal, setPromotionCal] = useState([]);
  const getCheckOut = async () => {
    const response = await (
      await getAllData(
        `check_out?product_id=${state.product_id}&item_id=${state.item_id}&qty=${state.qty}`
      )
    ).json();
    if (response.code === 200) {
      setCheckOut(response.data);
      if (response.data.product_promotion_price !== 0) {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(
            (response.data.price - response.data.product_promotion_price) *
              response.quantity
          );
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(
            (response.data.product_price -
              response.data.product_promotion_price) *
              response.quantity
          );
        }
      } else {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(0);
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(0);
        }
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [editopen, editsetOpen] = React.useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [region, setRegion] = useState("");
  const [showNoTownship, setShowNoTownship] = useState(false);
  const [showRegion, setShowRegion] = useState("");
  const handleChangeRegion = (event) => {
    setShowNoTownship(true);
    setShowRegion(event.target.value);
    getTownships(event.target.value);
    setShowTownship("");
  };

  const getRegion = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.regionsApi,
    };
    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setRegion(response.data);
    }
  };

  const [township, setTownship] = useState("");
  const [showTownship, setShowTownship] = useState("");
  const handleChangeTownship = (event) => {
    setShowNoTownship(false);
    setShowTownship(event.target.value);
  };
  const getTownships = async (regId) => {
    let obj = {
      method: "get",
      url: ApiCollections.townshipsApi,
      params: {
        region_id: regId,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setTownship(response.deliFeeRegions);
    }
  };

  const btnSave = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.addAddressApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
        name: name,
        ph_no: phone,
        address: address,
        region_id: showRegion,
        township_id: showTownship,
      },
    };
    if (name && phone && address && showRegion && showTownship) {
      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        navigate("/CheckOut");
        setOpen(false);
        getAddressList();
      }
    } else {
      toast.error("Please Fill All Informations", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const btnEdit = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.editAddressApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
        name: name,
        ph_no: phone,
        address: address,
        region_id: showRegion,
        township_id: showTownship,
      },
    };
    if (name && phone && address && showRegion && showTownship) {
      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        navigate("/CheckOut");
        editsetOpen(false);
        getAddressList();
        // window.location.reload(true);
      }
    } else {
      toast.error("Please Fill All Informations", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const edithandleOpen = () => {
    editsetOpen(true);
    getTownships(showRegion);
  };
  const edithandleClose = () => editsetOpen(false);

  const [CouponLists, setCouponLists] = useState([]);
  const getCouponLists = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.couponLists,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setCouponLists(response.data.Coupon);
    }
  };

  const [couponCal, setCouponCal] = useState("0");
  const couponget = (couponPrice, levelAmount, id) => {
    if (levelAmount == "Level 1") {
      if (totalCal >= 10000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setStateCoupon(false);
      } else {
        toast.error("အနည်းဆုံး တစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 2") {
      if (totalCal >= 20000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setStateCoupon(false);
      } else {
        toast.error("အနည်းဆုံး နှစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 3") {
      if (totalCal >= 40000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setStateCoupon(false);
      } else {
        toast.error("အနည်းဆုံး လေးသောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const [stateCoupon, setStateCoupon] = React.useState(false);
  const handleOpenCoupon = () => setStateCoupon(true);
  const handleCloseCoupon = () => setStateCoupon(false);

  return (
    <Wrapper>
      <Container>
        {loading && <Loading open={loading} />}
        <ToastContainer draggable={false} autoClose={3000} />
        <div className="checkoutAll">
          {addressList != null ? (
            <div>
              <div className="address">
                <div className="title">
                  <span className="name">{t("address")}</span>
                  <span
                    className="edit cursor-pointer"
                    onClick={edithandleOpen}
                  >
                    {t("edit")}
                  </span>
                </div>
                <div className="detail">
                  <FmdGood id="img" />
                  <div className="des">
                    {addressList.address},{addressList.township_name},
                    {addressList.region_name}
                  </div>
                </div>
                <div className="detail">
                  <Person id="img" />
                  <div className="des">{addressList.name}</div>
                </div>
                <div className="detail">
                  <Phone id="img" />
                  <div className="des">{addressList.phone_number}</div>
                </div>
              </div>
              <Modal
                open={editopen}
                onClose={edithandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <form className="AEflex">
                    <div className="AEBox">
                      <div className="name">{t("userName")}</div>
                      <div className="enterBox">
                        <Person id="photo" />
                        <input
                          type="text"
                          placeholder={t("pleaseName")}
                          className="inputEnter"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("phoneNumber")}</div>
                      <div className="enterBox">
                        <Phone id="photo" />
                        <input
                          type="number"
                          placeholder={t("pleasePhone")}
                          className="inputEnter"
                          value={phone}
                          disabled={
                            userLoginStatus() && userLoginStatus() == "phone"
                              ? "disabled"
                              : ""
                          }
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("region")}</div>
                      <div className="enterBox">
                        <AddLocation id="selectphoto" />
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={showRegion}
                          // label="Choose Region"
                          onChange={handleChangeRegion}
                          displayEmpty
                        >
                          {/* <MenuItem
                            value=""
                            sx={{
                              fontSize: "16px !important",
                            }}
                          >
                            {addressList.region_name}
                          </MenuItem> */}
                          {region.length > 0 &&
                            region.map((reginItem, regionIndex) => (
                              <MenuItem
                                key={regionIndex}
                                value={reginItem.region_id}
                                sx={{
                                  fontSize: "16px!important",
                                }}
                              >
                                {reginItem.region_name_en}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("township")}</div>
                      <div className="enterBox">
                        <Business id="selectphoto" />
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={showTownship}
                          // label={addressList.township_name}
                          onChange={handleChangeTownship}
                          displayEmpty
                        >
                          {showNoTownship && (
                            <MenuItem
                              value=""
                              sx={{
                                fontSize: "16px !important",
                              }}
                            >
                              {t("selectTownship")}
                            </MenuItem>
                          )}
                          {township.length > 0 &&
                            township.map((townshipItem, townshipIndex) => (
                              <MenuItem
                                key={townshipIndex}
                                value={townshipItem.township_id}
                                sx={{
                                  fontSize: "16px !important",
                                }}
                              >
                                {townshipItem.township_name_en}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("address")}</div>
                      <div className="enterBox">
                        <Apartment id="photo" />
                        <input
                          type="text"
                          placeholder={t("pleaseadd")}
                          className="inputEnter"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <button className="saveBtn" onClick={btnEdit} type="button">
                      {t("save")}
                    </button>
                  </form>
                </Box>
              </Modal>
            </div>
          ) : (
            <div>
              <div className="NoAddress" onClick={handleOpen}>
                <div className="iconlot">
                  <Lottie
                    animationData={selectaddress}
                    loop={true}
                    id="lotsec"
                  />
                </div>
                <div className="text cursor-pointer">{t("addAddress")}</div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <form className="AEflex">
                    <div className="AEBox">
                      <div className="name">{t("userName")}</div>
                      <div className="enterBox">
                        <Person id="photo" />
                        <input
                          type="text"
                          placeholder={t("pleaseName")}
                          className="inputEnter"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("phoneNumber")}</div>
                      <div className="enterBox">
                        <Phone id="photo" />
                        <input
                          type="number"
                          placeholder={t("pleasePhone")}
                          className="inputEnter"
                          value={phone}
                          disabled={
                            userLoginStatus() && userLoginStatus() == "phone"
                              ? "disabled"
                              : ""
                          }
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("region")}</div>
                      <div className="enterBox">
                        <AddLocation id="selectphoto" />
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={showRegion}
                          onChange={handleChangeRegion}
                          required
                          displayEmpty
                        >
                          <MenuItem
                            value=""
                            sx={{
                              fontSize: "16px !important",
                            }}
                          >
                            {t("selectRegion")}
                          </MenuItem>
                          {region.length > 0 &&
                            region.map((reginItem, regionIndex) => (
                              <MenuItem
                                key={regionIndex}
                                value={reginItem.region_id}
                                sx={{
                                  fontSize: "16px !important",
                                }}
                              >
                                {reginItem.region_name_en}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("township")}</div>
                      <div className="enterBox">
                        <Business id="selectphoto" />
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={showTownship}
                          onChange={handleChangeTownship}
                          displayEmpty
                          required
                        >
                          <MenuItem
                            value=""
                            sx={{
                              fontSize: "16px !important",
                            }}
                          >
                            {t("selectTownship")}
                          </MenuItem>
                          {township.length > 0 &&
                            township.map((townshipItem, townshipIndex) => (
                              <MenuItem
                                key={townshipIndex}
                                value={townshipItem.township_id}
                                sx={{
                                  fontSize: "16px !important",
                                }}
                              >
                                {townshipItem.township_name_en}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="AEBox">
                      <div className="name">{t("address")}</div>
                      <div className="enterBox">
                        <Apartment id="photo" />
                        <input
                          type="text"
                          placeholder={t("pleaseadd")}
                          className="inputEnter"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <button className="saveBtn" onClick={btnSave} type="button">
                      {t("save")}
                    </button>
                  </form>
                </Box>
              </Modal>
            </div>
          )}
          <div className="checkOutDiv">
            <div className="leftDiv">
              <div className="procheck">
                <div className="cardImage">
                  <img src={checkShow.product_photo} />
                </div>
                <div className="productRight">
                  <div className="title">{checkShow.product_name}</div>

                  <div className="price">
                    {checkShow.product_promotion_price !== 0 ? (
                      <>
                        <span className="">
                          {checkShow.product_promotion_price}ks
                        </span>
                        <span className="oriPrice">
                          {checkShow.price || checkShow.product_price}ks
                        </span>
                      </>
                    ) : (
                      <span className="money">
                        {checkShow.price || checkShow.product_price}ks
                      </span>
                    )}
                  </div>
                  <div className="qty">
                    {t("Quantity")}&nbsp;:&nbsp;{state.qty}
                  </div>
                </div>
              </div>
              <div className="couponAll">
                <React.Fragment key={"bottom"}>
                  <div className="couponChose" onClick={handleOpenCoupon}>
                    {t("CouponUse")}
                    <KeyboardArrowRight id="btnRight" />
                  </div>

                  <Modal
                    open={stateCoupon}
                    onClose={handleCloseCoupon}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={couponstyle}>
                      <div className="couponCartPc">
                        <div className="crossDiv">
                          <HighlightOff
                            id="couponcross"
                            onClick={handleCloseCoupon}
                          />
                        </div>
                        {CouponLists.length > 0 ? (
                          <div>
                            {CouponLists.map((item, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  couponget(
                                    item.discount_price,
                                    item.coupon_level,
                                    item.id
                                  )
                                }
                                className=" mt-[15px]"
                              >
                                <img
                                  src={item.coupon_img}
                                  className="couponImg"
                                />
                                <div className="date">
                                  &nbsp;&nbsp;{item.time_left}
                                  <div className="name">Day Left</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="w-full h-auto flex flex-col items-center mt-[20px]">
                            <div>{t("CouponNo")}</div>
                            <div className="w-[300px] h-[300px]">
                              <Lottie
                                animationData={nodata}
                                loop={true}
                                className="w-full h-[70%] object-cover"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Box>
                  </Modal>
                </React.Fragment>
              </div>
            </div>
            <div className="rightDiv">
              <div>{t("orderSummary")}</div>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("priceTotal")}
                </Grid>
                {totalCal ? (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    {Number.parseFloat(totalCal).toFixed(2)}Ks
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    0Ks
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("delivery")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  {deliveryFee != null ? deliveryFee + deliveryExtra : 0}Ks
                </Grid>
              </Grid>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("cashOnDelivery")}
                </Grid>
                {Cod == 1 ? (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      color: "green",
                    }}
                  >
                    {t("available")}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    {t("unAvailable")}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("discount")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  -{Number.parseFloat(promotionCal).toFixed(2)}ks
                </Grid>
              </Grid>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("CouponTitle")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  -{Number.parseFloat(couponCal).toFixed(2)}ks
                </Grid>
              </Grid>
              <Grid container spacing={2} my={2}>
                <Grid item xs={8}>
                  {t("total")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  {Number.parseFloat(
                    totalCal +
                      deliveryFee +
                      deliveryExtra -
                      promotionCal -
                      couponCal
                  ).toFixed(2)}
                  ks
                </Grid>
              </Grid>
              <button className="confirmBtn" onClick={() => gotoOrderList()}>
                {t("placeOrder")}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default CheckOut;
