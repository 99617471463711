import { store } from "../../store";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import {
  addToCart,
  getTotalAmount,
  getCartCount,
} from "../../reducers/cartSlice";

const getCustomerCartItems = async (customerId) => {
  let obj = {
    method: "get",
    url: ApiCollections.getCustomerCartData,
    params: {
      customer_id: customerId,
    },
  };
  const response = await ApiRequest(obj);
  if (response.flag === false) {
  } else if (response.data.code === 200) {
    // store.dispatch(someAction)
    store.dispatch(addToCart(response.data.data));
    store.dispatch(getTotalAmount());
    store.dispatch(getCartCount());
  } else {
    console.log("Something Wrong Login with Phone!");
  }
};
export default getCustomerCartItems;
