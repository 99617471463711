import { createSlice } from "@reduxjs/toolkit";

import {
  requestLoginWithPhoneAsync,
  loginWithSMS,
  requestOTP,
  registerWithPhone,
  requestResetPassword,
  requestResetPasswordSms,
  passwordReset,
} from "./userActions";



const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;
const initialState = {
  user: null,
  token: userToken,
  address: null,
  entities: [],
  loading: false,
  error: null,
  authUser: null,
  language: "en",
  languageNative: "English",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.showData;
    },
    logout: (state) => {
      // state.user = null;
      localStorage.removeItem("userToken");
      localStorage.removeItem("userTokenID");
      localStorage.removeItem("userTokenPhone");
      localStorage.removeItem("userTokenEmail");
      // persistor.purge();
      // return {
      //   ...state,
      //   user: null,
      //   token: null,
      //   address: null,
      //   authUser: null,
      // };

    },
    setAddress: (state, action) => {
      state.address = action.payload.address;
    },
    setAuthUser: (state, action) => {
      localStorage.setItem("userToken", action.payload.auth_token);
      return {
        ...state,
        authUser: action.payload,
        token: action.payload.auth_token,
      };
    },
    setLanguge: (state, action) => {
      localStorage.setItem("Accept-Language", action.payload);
      return { ...state, language: action.payload };
    },
    setLangugeName: (state, action) => {
      return { ...state, languageNative: action.payload };
    },
    updateAuthUser: (state, action) => {
      return {
        ...state,
        authUser: {...state.authUser,...action.payload},
      };
    },
  },
  extraReducers: {
    [requestLoginWithPhoneAsync.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [requestLoginWithPhoneAsync.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    //requestOTP
    [loginWithSMS.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [loginWithSMS.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    //requestOTP
    [requestOTP.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [requestOTP.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    //registerWithPhone
    [registerWithPhone.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [registerWithPhone.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
    //requestResetPassword
    [requestResetPassword.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [requestResetPassword.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },

    //requestResetPasswordSms
    [requestResetPasswordSms.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [requestResetPasswordSms.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },

    //passwordReset
    [passwordReset.fulfilled]: (state, action) => {
      state.entities = [action.payload];
    },
    [passwordReset.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  setUser,
  setAddress,
  setAuthUser,
  setLanguge,
  setLangugeName,
  updateAuthUser,
} = userSlice.actions;

export default userSlice.reducer;
