import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductComponent from "../../home/ProductComponent";
import SMProductComponent from "./SMProductComponent";
import Wrapper from "../../Wrapper";

const NewArrivalSeeMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        <SMProductComponent />
      </Container>
    </Wrapper>
  );
};

export default NewArrivalSeeMore;
