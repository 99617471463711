import { Outlet } from "react-router";
import { useLastLocation } from "react-router-dom-last-location";

export default function LastLocaltionRoute() {
  // const { lastLocation } = useLastLocation();

  // if(lastLocation && lastLocation.pathname) sessionStorage.setItem("redirectUrl", lastLocation.pathname);

  return (
    <>
      <Outlet />
    </>
  );
}
