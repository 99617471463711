import React, { useState, useEffect } from "react";
import "../../mobile/product/CheckOutCss.scss";
import {
  ArrowCircleLeft,
  FmdGood,
  Person,
  Phone,
  KeyboardArrowRight,
  HighlightOff,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import selectaddress from "../../assets/selectaddress.json";
import { getAllData } from "../httpConfig";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";
import { Drawer, Box } from "@mui/material";
import { addID, addPrice, addStatus } from "../../reducers/couponSlice";
import { useDispatch } from "react-redux";
import nodata from "../../assets/coupon.json";

const CheckOut = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const proid = sessionStorage.getItem("productid");
  const itid = sessionStorage.getItem("itemid");
  const qty = sessionStorage.getItem("simpleQTY");

  useEffect(() => {
    window.scrollTo(0, 0);
    getCheckOut();
    getAddressList();
    getCouponLists();
    dispatch(addID(null));
    dispatch(addPrice(null));
    dispatch(addStatus(0));
  }, []);

  const [loading, setLoading] = useState(false);

  const gotoOrderList = async () => {
    if (addressList == null) {
      toast.error("Please Address", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (largeItem == 1 && Cod == 0) {
      toast.error(
        "Sorry, this item can't be shipped to your selected address",
        {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      navigate("/Payment");
    }
  };

  const [checkShow, setCheckOut] = useState([]);
  const [totalCal, setTotalCal] = useState([]);
  const [promotionCal, setPromotionCal] = useState([]);
  const getCheckOut = async () => {
    const response = await (
      await getAllData(
        `check_out?product_id=${proid}&item_id=${itid}&qty=${qty}`
      )
    ).json();
    if (response.code === 200) {
      setCheckOut(response.data);
      if (response.data.product_promotion_price !== 0) {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(
            (response.data.price - response.data.product_promotion_price) *
              response.quantity
          );
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(
            (response.data.product_price -
              response.data.product_promotion_price) *
              response.quantity
          );
        }
      } else {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(0);
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(0);
        }
      }
    }
    // let obj = {
    //     method: 'get',
    //     url: ApiCollections.checkoutApi,
    //     params: {
    //         product_id: proid,
    //         item_id: itid || null,
    //         qty: qty,
    //     }
    // };

    // let response = await ApiRequest(obj);
    // response =response.data;
    // if (response.code === 200) {
    //     setCheckOut(response.data)
    // }
  };

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  const [CouponLists, setCouponLists] = useState([]);
  const getCouponLists = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.couponLists,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setCouponLists(response.data.Coupon);
    }
  };

  const [addressList, setAddressList] = useState([]);
  const getAddressList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      getDeliveryFee(response.data.township_id, response.data.region_id);
    } else {
      setAddressList(response.data);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState("0");
  const [Cod, setCod] = useState([]);
  const [largeItem, setLargeItem] = useState([]);
  const [deliveryExtra, setDeliveryExtra] = useState([]);
  const getDeliveryFee = async (townID, regionID) => {
    let productIdsArr = [];
    if (proid != null) {
      productIdsArr.push({
        id: proid,
        quantity: qty,
        type: "product",
      });
    } else {
      productIdsArr.push({
        id: itid,
        quantity: qty,
        type: "item",
      });
    }

    let requestObj = {
      method: "post",
      url: ApiCollections.deliveryFeeApi,
      params: {
        township_id: townID,
        region_id: regionID,
        productIds_arr: productIdsArr,
      },
    };

    let response = await ApiRequest(requestObj);

    if (response.data.code === 200) {
      setDeliveryFee(response.data.data.delivery_fee);
      setCod(response.data.data.cod_status);
      setLargeItem(response.data.data.large_item_status);
      const { finalTotal } = response.data.data.extra_charge.reduce(
        (total, item) => {
          const { shipping_type_price } = item;
          const data = shipping_type_price;
          total.finalTotal += data;
          return total;
        },
        { finalTotal: 0 }
      );
      setDeliveryExtra(finalTotal);
    }
  };

  const gotoAddress = () => {
    navigate("/AddAddress");
  };

  const goEditAddress = () => {
    navigate("/EditAddress");
  };

  const [couponCal, setCouponCal] = useState("0");
  const couponget = (couponPrice, levelAmount, id) => {
    if (levelAmount == "Level 1") {
      if (totalCal >= 10000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး တစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 2") {
      if (totalCal >= 20000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး နှစ်သောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (levelAmount == "Level 3") {
      if (totalCal >= 40000) {
        dispatch(addID(id));
        dispatch(addPrice(couponPrice));
        dispatch(addStatus(1));
        setCouponCal(couponPrice);
        setState(false);
      } else {
        toast.error("အနည်းဆုံး လေးသောင်းကျပ်ဖိုး ဝယ်ယူသူများ အတွက်သာ", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, bottom: open });
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <ToastContainer draggable={false} autoClose={3000} />

      <div className="seeOrder">
        <div className="header">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title"> {t("checkOut")}</div>
        </div>
        {addressList !== null ? (
          <div className="address">
            <div className="title">
              <span className="name">{t("address")}</span>
              <span className="edit" onClick={() => goEditAddress()}>
                {t("edit")}
              </span>
            </div>
            <div className="detail">
              <FmdGood id="img" />
              <div className="des">
                {addressList.address},{addressList.township_name},
                {addressList.region_name}
              </div>
            </div>
            <div className="detail">
              <Person id="img" />
              <div className="des">{addressList.name}</div>
            </div>
            <div className="detail">
              <Phone id="img" />
              <div className="des">{addressList.phone_number}</div>
            </div>
          </div>
        ) : (
          <div className="NoAddress" onClick={() => gotoAddress()}>
            <div className="iconlot">
              <Lottie animationData={selectaddress} loop={true} id="lotsec" />
            </div>
            <div className="text">{t("addAddress")}</div>
          </div>
        )}

        {checkShow && (
          <div className="orderProduct">
            <img src={checkShow.product_photo} />
            <div className="detail">
              <div className="title">{checkShow.product_name}</div>
              {checkShow.price ? (
                <div className="priceBox">
                  {checkShow.product_promotion_price !== 0 ? (
                    <div>
                      <span className="price">
                        {checkShow.product_promotion_price} ks
                      </span>
                      <span className="disPrice">{checkShow.price}ks</span>
                    </div>
                  ) : (
                    <div>
                      <span className="price">{checkShow.price} ks</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="priceBox">
                  {checkShow.product_promotion_price !== 0 ? (
                    <div>
                      <span className="price">
                        {checkShow.product_promotion_price} ks
                      </span>
                      <span className="disPrice">
                        {checkShow.product_price}ks
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="price">
                        {checkShow.product_price} ks
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="qtyBox">
                {t("Quantity")}&nbsp;:&nbsp;
                <span className="qty">{qty}</span>
              </div>
            </div>
          </div>
        )}

        <div className="tax">
          <div className="total">
            <span className="name">{t("priceTotal")}</span>
            {totalCal ? (
              <span className="number">
                {Number.parseFloat(totalCal).toFixed(2)}ks
              </span>
            ) : (
              <span className="number">0ks</span>
            )}
          </div>

          <div className="total">
            <span className="name">{t("delivery")}</span>
            <span className="number">{deliveryFee + deliveryExtra}ks</span>
          </div>

          <div className="total">
            <span className="name">{t("cashOnDelivery")}</span>
            {Cod == 1 ? (
              <span className="number !text-[green]">{t("available")}</span>
            ) : (
              <span className="number !text-[red]">{t("unAvailable")}</span>
            )}
          </div>

          <div className="total">
            <span className="name">{t("discount")}</span>
            <span className="number">
              -{Number.parseFloat(promotionCal).toFixed(2)}ks
            </span>
          </div>
          <div className="total">
            <span className="name">{t("CouponTitle")}</span>
            <span className="number">
              -{Number.parseFloat(couponCal).toFixed(2)}ks
            </span>
          </div>
        </div>

        <div className="couponAll">
          <React.Fragment key={"bottom"}>
            <div className="couponChose" onClick={toggleDrawer(true)}>
              {t("CouponUse")}
              <KeyboardArrowRight id="btnRight" />
            </div>

            <Drawer
              anchor={"bottom"}
              open={state["bottom"]}
              onClose={toggleDrawer(false)}
            >
              <Box sx={{ width: "auto" }} role="presentation">
                <div className="couponCart">
                  <div className="crossDiv">
                    <HighlightOff
                      id="couponcross"
                      onClick={toggleDrawer(false)}
                    />
                  </div>

                  {CouponLists.length > 0 ? (
                    <div>
                      {CouponLists.map((item, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            couponget(
                              item.discount_price,
                              item.coupon_level,
                              item.id
                            )
                          }
                        >
                          <img src={item.coupon_img} className="couponImg" />
                          <div className="date">
                            &nbsp;&nbsp;{item.time_left}
                            <div className="name">Day Left</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="noCou">
                      <div className="text">{t("CouponNo")}</div>
                      <div className="iconWrap">
                        <Lottie animationData={nodata} loop={true} id="lot" />
                      </div>
                    </div>
                  )}
                </div>
              </Box>
            </Drawer>
          </React.Fragment>
        </div>
        <div className="orderFooter">
          <div className="buyNow">
            {t("total")} :
            <span>
              {Number.parseFloat(
                totalCal +
                  deliveryFee +
                  deliveryExtra -
                  promotionCal -
                  couponCal
              ).toFixed(2)}
              ks
            </span>
          </div>
          <div className="addCart" onClick={gotoOrderList}>
            {t("placeOrder")}
          </div>
        </div>
        <ToastContainer draggable={false} autoClose={3000} />
      </div>
    </>
  );
};

export default CheckOut;
