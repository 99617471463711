import React, { useEffect } from "react";
import Footer from "./footer/Footer";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Box,
  Container,
  CssBaseline,
  Fab,
  Fade,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const ScrollTop = (props) => {
  // use style

  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#topHeader"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: "25vw", right: "3vw" }}
      >
        {children}
      </Box>
    </Fade>
  );
};
const Wrapper = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {props.children}
      <Footer />
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <ArrowCircleUpIcon sx={{ color: "#014E40", fontSize: "9vw" }} />
        </Fab>
      </ScrollTop>
    </div>
  );
};

export default Wrapper;
