import React, { useState, useEffect } from "react";
import "../../mobile/account/SeeQACss.scss";
import { useNavigate } from "react-router-dom";
import {
  ArrowCircleLeft,
  ContactSupport,
  QuestionAnswer,
} from "@mui/icons-material";
import Loading from "../../components/commons/Loading";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import nodata from "../../assets/qna.json";
import { useTranslation } from "react-i18next";

const SeeQA = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getShowCommentProduct();
    getSeenCommentProduct();
  }, []);

  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };

  const [loading, setLoading] = useState(false);

  const [seenComment, setSeenCommentProduct] = useState([]);
  const getSeenCommentProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "get",
      url: ApiCollections.seenCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setSeenCommentProduct(response.data.data);
    }
  };

  const [showComment, setShowCommentProduct] = useState([]);
  const getShowCommentProduct = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.showCommentProductApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setShowCommentProduct(response.data.data);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="seeqaDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("qna")}</div>
        </div>
        {showComment.length > 0 ? (
          <div className="onenew">
            {showComment.map((item, index) => (
              <div
                className="buyProduct"
                key={index}
                onClick={() =>
                  gotoproduct(item.id, item.item_status, item.item_id)
                }
              >
                <div className="orderlistDiv">
                  <div className="cardImage">
                    <img src={item.photo} />
                  </div>
                  <div>
                    <div className="title">{item.product_name}</div>
                    <div className="price">
                      {item.product_promotion_price !== 0 ? (
                        <span className="ori">
                          {item.product_promotion_price} ks &nbsp;&nbsp;
                          <s>{item.simple_price}ks</s>
                        </span>
                      ) : (
                        <span className="ori">{item.simple_price} ks</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="allQa">
                  <div className="writeQ">
                    <ContactSupport id="qicon" />
                    <div className="ques">
                      <div className="showDetail">
                        {item.customer_name != null ? (
                          <span className="name">{item.customer_name}</span>
                        ) : (
                          <span className="name">
                            User : {item.customer_id}
                          </span>
                        )}
                        <span className="date">{item.question_time}</span>
                      </div>
                      <div className="wq">{item.question}</div>
                    </div>
                  </div>
                  {item.answer != null && (
                    <div className="writeA">
                      <QuestionAnswer id="qicon" />
                      <div className="ques">
                        <div className="showDetail">
                          <span className="name">{item.answer_by}</span>
                          <span className="date">{item.answer_time}</span>
                        </div>
                        <div className="wq">{item.answer}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("noqna")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SeeQA;
